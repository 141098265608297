import React from "react";
import Nav1 from "../../Components/Quicklink";
import Navbar from "../../Components/navigation";
import Footer3 from "../../Components/footer3";
import BlogSection1 from "../../SubPages/Blog/Blogsection1";

const ZephTech = () => {
  return (
    <>
      <div className="max-w-[1460px] mx-auto  content-center">
        <Nav1 />
        <Navbar />
        <BlogSection1 />
        <div>
          <div className="grid md:grid-cols-2 md:mx-auto  p-2">
            <div
              className="text-justify p-4 bg-gray-100 col-span-1 "
              style={{ fontFamily: "calibri" }}
            >
              <h3 className="font-bold text-xl">
                Transforming Mercy Girls High School's Online Presence: A
                Testimony of ZephTech's Excellence
              </h3>{" "}
              <p>
                As a proud member of the Mercy Girls High School community in
                Okigwe, I am thrilled to share my heartfelt testimony about the
                incredible work of ZephTech, a web design firm that has
                revolutionized our school's online presence.
              </p>{" "}
              <p>
                {" "}
                Before ZephTech's intervention, our school's website was
                outdated, clunky, and failed to capture the essence of our
                institution's values and achievements. It was clear that we
                needed a modern, user-friendly platform that could effectively
                communicate with our stakeholders and showcase the excellence
                that defines Mercy Girls High School
                <img
                  className=" p-4"
                  src="Images/zephtectflyer.jpg"
                  alt="Prawa at mercy girls high school okigwe"
                />
              </p>
              <p>
                {" "}
                From the moment we engaged with ZephTech, it was evident that we
                were in capable hands. Their team took the time to understand
                our school's unique identity, values, and goals, ensuring that
                every aspect of the website reflected our ethos. Through
                collaborative discussions and meticulous planning, they crafted
                a vision that exceeded our expectations.
              </p>
              <p>
                The transformation of our website by ZephTech has been nothing
                short of remarkable. The new design is sleek, intuitive, and
                visually stunning, providing visitors with a seamless browsing
                experience. From vibrant imagery to engaging multimedia content,
                every element has been carefully curated to captivate and inform
                our audience
              </p>{" "}
              <p>
                One of the most impressive aspects of ZephTech's work is their
                attention to detail. They have seamlessly integrated features
                such as event calendars, news updates, and photo galleries,
                allowing us to keep our community informed and engaged.
                Additionally, the website is fully responsive, ensuring optimal
                performance across all devices and screen sizes.
              </p>{" "}
              <p>
                Not only has ZephTech delivered a cutting-edge website, but they
                have also provided comprehensive training and ongoing support to
                ensure that we can manage and update the platform with ease.
                Their dedication to customer satisfaction and commitment to
                excellence have
              </p>{" "}
              <p>
                Thanks to ZephTech's expertise and professionalism, Mercy Girls
                High School now has a digital platform that reflects the
                excellence of our institution. Our website has become a dynamic
                hub for students, parents, alumni, and the wider community,
                fostering connections and showcasing the incredible achievements
                of our students and staff.
              </p>{" "}
              <p>
                I cannot recommend ZephTech highly enough to any organization
                seeking to enhance their online presence. Their passion,
                creativity, and technical expertise have transformed our
                school's digital footprint, and we are immensely grateful for
                their partnership. Thank you, ZephTech, for helping us shine
                brightly
              </p>{" "}
            </div>
            <div
              style={{ fontFamily: "calibri" }}
              className=" p-4 bg-gray-100 "
            >
              <h2 className="font-bold text-xl text-zinc-400 mt-4">
                Recent Blogs
              </h2>
              <div className="p-2">
                <div className="grid md:grid-cols-2  p-4 m-4">
                  <div className="mx-auto mx-w-sm">
                    <h3 className="text-md font-bold px-2 ">
                      Prawa NGO Champions Girl Child Education with Support from
                      Mercy Girls High School Okigwe Alumni
                    </h3>
                    <section
                      style={{ fontFamily: "sans-serif" }}
                      className="px-6 text-sm mt-2 text-justify text-zinc-500"
                    >
                      In a world where education remains a powerful tool for
                      empowerment and social change, Prawa NGO stands at the
                      forefront of...
                    </section>
                  </div>
                  <div className="w-full">
                    <img
                      className=""
                      src="Images/prawaflyer.jpg"
                      alt="prawa at mercy girls high school okigwe"
                    />
                    <a
                      href="prawa"
                      className="bg-zinc-200 relative mx-auto hover:text-mghso-10 p-4 text-center "
                    >
                      Continue Reading
                    </a>
                  </div>
                </div>{" "}
                {/* second Blog headline */}
                <div className="grid md:grid-cols-2 p-4 m-4 ">
                  <div className="mx-auto mx-w-sm">
                    <h3 className="text-md font-bold px-2">
                      Celebrating Excellence: Mercy Girls High School Shines in
                      WAEC 2022/2023 Examinations
                    </h3>
                    <section
                      style={{ fontFamily: "sans-serif" }}
                      className="px-6 text-sm mt-2 text-justify text-zinc-500"
                    >
                      Mercy Girls High School, a beacon of academic excellence,
                      has once again demonstrated its commitment to nurturing
                      bright minds and fostering academic brilliance.
                    </section>
                  </div>
                  <div className="w-full">
                    <img
                      className=""
                      src="Images/waeclogo.jpeg"
                      alt="nnpc at mercy girls high school okigwe"
                    />
                    <a
                      href="result"
                      className="bg-zinc-200 relative mx-auto hover:text-mghso-10 p-4 text-center "
                    >
                      Continue Reading
                    </a>
                  </div>
                </div>
                {/* second Blog headline */}
                <div className="grid md:grid-cols-2 p-4  m-4">
                  <div className="mx-auto mx-w-sm">
                    <h3 className="text-md font-bold px-2 ">
                      Lumix Graphics Transforms Mercy Girls High School Okigwe's
                      Website and Facebook Page with
                    </h3>
                    <section
                      style={{ fontFamily: "sans-serif" }}
                      className="px-6 text-sm mt-2 text-justify text-zinc-500"
                    >
                      In a digital age where online presence is paramount, Mercy
                      Girls High School Okigwe has received a remarkable
                      makeover thanks to Lumix Graphics and its
                    </section>
                  </div>
                  <div className="w-full">
                    <img
                      className=""
                      src="Images/graphics_logo.jpg"
                      alt="prawa at mercy girls high school okigwe"
                    />
                    <a
                      href="Lumix"
                      className="bg-zinc-200 relative mx-auto hover:text-mghso-10 p-4 text-center "
                    >
                      Continue Reading
                    </a>
                  </div>
                </div>
                {/* 3rd Blog headline */}
                <div className="grid md:grid-cols-2 p-4 m-4">
                  <div className="mx-auto mx-w-sm">
                    <h3 className="text-md font-bold px-2 ">
                      NCC's Rural Broadband Initiative Empowers Mercy Girls High
                      School Okigwe with Cutting-Edge ICT Complex.
                    </h3>
                    <section
                      style={{ fontFamily: "sans-serif" }}
                      className="px-6 text-sm mt-2 text-justify text-zinc-500"
                    >
                      In a concerted effort to bridge the digital divide and
                      empower underserved communities,
                    </section>
                  </div>
                  <div className="w-full">
                    <img
                      className=""
                      src="Images/indesign.jpg"
                      alt="nnc at mercy girls high school okigwe"
                    />
                    <a
                      href="ncc"
                      className="bg-zinc-200 relative mx-auto hover:text-mghso-10 p-4 text-center "
                    >
                      Continue Reading
                    </a>
                  </div>
                </div>
                {/*4th Blog Headline*/}
                <div className="grid md:grid-cols-2 p-4 m-4 ">
                  <div className="mx-auto mx-w-sm">
                    <h3 className="text-md font-bold px-2">
                      Living by Design Nation (LBD) Launches "Say No to
                      Examination Malpractice" Campaign at Mercy Girls High
                      School okigwe
                    </h3>
                    <section
                      style={{ fontFamily: "sans-serif" }}
                      className="px-6 text-sm mt-2 text-justify text-zinc-500"
                    >
                      In a world where academic integrity is paramount, Living
                      by Design Nation (LBD) has taken...
                    </section>
                  </div>
                  <div className="w-full">
                    <img
                      className=""
                      src="Images/living_logo.jpg"
                      alt="nnpc at mercy girls high school okigwe"
                    />
                    <a
                      href="lbd"
                      className="bg-zinc-200 relative mx-auto hover:text-mghso-10 p-4 text-center "
                    >
                      Continue Reading
                    </a>
                  </div>
                </div>
                {/*   section5 */}
                <div className="grid md:grid-cols-2 p-4  m-4">
                  <div className="mx-auto mx-w-sm">
                    <h3 className="text-md font-bold px-2 ">
                      NNPC LTD Enhances STEM Education at Mercy Girls High
                      School Okigwe with Generous Donation
                    </h3>
                    <section
                      style={{ fontFamily: "sans-serif" }}
                      className="px-6 text-sm mt-2 text-justify text-zinc-500"
                    >
                      In a commendable gesture towards promoting education and
                      empowering young minds in the field of STEM (Science,
                      Technology...
                    </section>
                  </div>
                  <div className="w-full">
                    <img
                      className=""
                      src="Images/nnpc_board.jpg"
                      alt="nnpc at mercy girls high school okigwe"
                    />
                    <a
                      href="Blog"
                      className="bg-zinc-200 relative mx-auto hover:text-mghso-10 p-4 text-center "
                    >
                      Continue Reading
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer3 />
      </div>
    </>
  );
};
export default ZephTech;
