import React from "react";
//import { FaCalendar } from "react-icons/fa";
const Acsecction2 = () => {
  return (
    <>
      <div className="grid md-block lg:grid-cols-2 gap-4 bg-mghso-10 p-8">
        <div className="p-8 text-gray-200">
          <h2 className="text-center w-[100%]  font-bold text-xl p-2">
            Admmision Policy
          </h2>

          <p className="text-justify">
            Mercy Girls High School Okigwe is a selective school, in terms of
            seeking students with sound accademic ability as well as strengths
            in the areas of sports religion and the arts.
          </p>
          <p className="text-justify">
            MGHSO offers a broad, rigorous progranm and is looking for evidence
            of a student's preparation to succees at MGHSO, paying particular
            attention to:
          </p>
          <ul className="list-disc p-4">
            <li>&nbsp;Ethusiasm, curiosity and a passion for learning.</li>
            <li>
              &nbsp;Intellect, evidenced by a history of accademic success
            </li>
            <li>&nbsp; Ability to fit into a boarding environment</li>
            <li>&nbsp; Well-disciplined, social and respectful</li>
            <li>&nbsp; Ability in sports or the art</li>
            <li>&nbsp; Ability to respect the religous life of others</li>
          </ul>
          <p className="">
            For more Information about Mercy Girls' High School Okigwe Admission
            please contact &nbsp;&nbsp;
            <strong>+2348068491189, &nbsp;info@mghso.com.ng</strong>
          </p>
        </div>
        <div className=" bg-white table-auto  p-8">
          <h3 className="text-center w-[100%]  font-bold text-xl p-2">
            School Calender 2024/2025
          </h3>
          {/* <FaCalendar /> */}
          <table className="table-auto w-[100%] space-x-2 p-4 shadow-md">
            <thead className="bg-gray-300">
              <tr className="border-[1px] border-gray-500">
                <th>TERM</th>
                <th>DATE</th>
                <th>EVENT</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-[1px] p-2 border-gray-500">
                <td className="p-4">1st Term</td>
                <td>
                  <p className="bg-yellow-200 text-gray-900">09/09/2024</p>
                  <hr></hr>
                  <p className="bg-green-200 text-gray-900">16/12/2024</p> <hr></hr>
                  <p>28/10/2024</p>
                  <hr></hr>
                  <p>01/11/2024</p>
                </td>
                <td>
                  <p className="bg-yellow-200 text-gray-900">School Resumes</p>
                  <hr></hr>
                  <p className="bg-green-200 text-gray-900">School Closses</p> <hr></hr>
                  <p></p>
                  <p>Mid-Term Break</p>
                </td>
              </tr>{" "}
              <tr className="border-[1px] border-gray-500">
                <td className="p-4">2nd Term</td>
                <td>
                  <p className="bg-yellow-200 text-gray-900">06/01/2025</p>
                  <hr></hr>
                  <p className="bg-green-200 text-gray-900">07/04/2025</p>
                  <hr></hr>
                  <p> 17/02/2025</p>
                  <hr></hr>
                  <p>21/02/2025</p>
                </td>
                <td>
                  <p className="bg-yellow-200 text-gray-900">School Resumes</p>
                  <hr></hr>
                  <p className="bg-green-200 text-gray-900">School Closses</p> <hr></hr>
                  <p></p>
                  <p>Mid-Term Break</p>
                </td>
              </tr>{" "}
              <tr className="border-[1px] border-gray-500">
                <td className="p-4">3rd Term</td>
                <td>
                  <p className="bg-yellow-200 text-gray-900">28/04/2025</p>
                  <hr></hr>
                  <p className="bg-green-200 text-gray-900">28/07/2025</p> <hr></hr>
                  <p>26/05/2025</p>
                  <hr></hr>
                  <p>30/05/2025 </p>
                </td>
                <td>
                  <p className="bg-yellow-200 text-gray-900">School Resumes</p>
                  <hr></hr>
                  <p className="bg-green-200 text-gray-900">School Closses</p> <hr></hr>
                  <p></p>
                  <p>Mid-Term Break</p>
                </td>
              </tr>
              <tr className="px-4">
                <td className="px-4 py-4">2024/2025 Academic Session</td>
                <td>08/09/2025</td>
                <td>School Resumes</td>
              </tr>
            </tbody>
          </table>

<div className="py-4">
            <p className="text-sm mt-4 font-bold"> Impotant Dates To Remember</p>
            <p  className="text-sm px-2"> -Tuesday October 1, 2024 (Independent Day)</p>
            <p className="text-sm px-2">-Friday November 1, 2024 (All Saints Day)</p>
            <p className="text-sm px-2">-Wednesday February 26, 2025 (Ash Wednesday)</p>
            <p className="text-sm px-2">-Thursday May 1, 2025 (Workers Day)</p>
            <p className="text-sm px-2">-Thursday May 22, 2025 (Ascension Thursday)</p>
            <p className="text-sm px-2">-Tuesday May 27, 2025 (Children's Day)</p>
<p className="text-sm px-2">-Thursday May 29, 2025 (Democarcy Day)</p>          </div>
        </div>
      </div>
    </>
  );
};
export default Acsecction2;
