import React, { useState, useEffect } from "react";
const EduSection1 = () => {
  const slides = [
    "School_Road2.jpg",
    "School_Compound3.jpg",
    "School_gate_Open.jpg",
  ];
  const [currentSlide, setCurrentSlide] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 7000);
    return () => clearInterval(interval);
  }, []);
  return (
    <div className="relative mt-8 md:-mt-24 -z-10 overflow-hidden">
      {/* Slides */}
      {slides.map((slide, index) => (
        <div
          key={index}
          className={`absolute inset-0 w-full h-full transition-opacity ${
            index === currentSlide ? "opacity-100" : "opacity-0"
          }`}
        >
          <img
            className="w-full h-full object-cover object-center"
            src={`Images/${slide}`}
            alt={`Slide ${index + 1}`}
          />
          <div className="absolute inset-0 bg-mghso-10 opacity-50"></div>
        </div>
      ))}
      {/* Content */}
      <div className="relative py-40 z-10 text-white md:text-center">
        <h1 className="text-4xl font-extrabold pl-4 leading-tight sm:text-5xl md:text-6xl lg:text-7xl">
          {" "}
          Education
        </h1>
      </div>
    </div>
  );
};
export default EduSection1;
