import React from "react";
import Nav1 from "../Components/Quicklink";
import Navbar from "../Components/navigation";
import BlogSection1 from "../SubPages/Blog/Blogsection1";
import ContactSection3 from "../SubPages/Contact/contactsection3";
import BlogSection2 from "../SubPages/Blog/Blogsection2";
import Footer3 from "../Components/footer3";

const Blog = () => {
  return (
    <>
      <div className="max-w-[1460px] mx-auto content-center">
        <Nav1 />
        <Navbar />
        <BlogSection1 />
        <BlogSection2 />

        <ContactSection3 />

        <Footer3 />
      </div>
    </>
  );
};
export default Blog;
