import React from "react";
import Nav1 from "../../../../Components/Quicklink";
import Navbar from "../../../../Components/navigation";
import AlumniSection1 from "../../AlumniSection1";
import Footer3 from "../../../../Components/footer3";

const Ogechisection = () => {
  return (
    <>
      <div>
        <Nav1 />
        <Navbar />
        <AlumniSection1 />

        <div className="grid md:grid-cols-3 p-6 md:p-20 text-justify">
          <div className="max-w-sm mx-auto">
            <img
              className="  p-6 md:p-8 pb-2 w-[100%] mx-auto object-center"
              src="Images/ogechi1.jpg"
              alt="Ohiasection"
            />
            <h4 className="text-md font-bold px-4 mx-auto md:px-8">
              {" "}
              Ogechi Chinyere Ogu Esq
            </h4>
          </div>
          <div className="col-span-2 md:py-20">
            <p className="text-sm px-4">
              Ogechi Chinyere Ogu Esq. is the Deputy Director , Prisoners’
              Rehabilitation and Welfare Action (PRAWA) and the National
              Coordinator Olive Justice Development Initiative.
            </p>
            <p className="text-sm px-4">
              {" "}
              She is a member of Association of International Corrections &
              Prisons Association (ICPA) , Staff Training and Development
              Community Corrections’ Sub Committee of ICPA, International
              Corrections and Prisons Association-Africa Chapter (ICPA-Africa),
              Association of Professional Negotiators and Mediators of Nigeria
              and is currently representing PRAWA in the National Committee
              Against Torture and is a member of the Federal Capital Territory
              Parole Board. She was the Team Lead , Security and Justice Unit of
              PRAWA and managed several Prison Reform Projects of PRAWA
              including, Prison Reform Project, the Decriminalization of Petty
              Offences in Nigeria, Project and Effective Implementation of
              Non-custodial Measures in Nigeria Project. She spent early years
              of her career as a teacher and thought English language/ Literatur
            </p>
          </div>
        </div>
        <Footer3 />
      </div>
    </>
  );
};
export default Ogechisection;
