import React from "react";
const AboutHome = () => {
  return (
    <div className="relative" style={{ fontFamily: "calibri" }}>
      <div>
        <img
          src="Images/assembly.jpg"
          alt="mercy girls high school"
          className="absolute w-full h-full object-cover object-center"
        />
        <div
          className="absolute inset-0 opacity-80"
          style={{
            backgroundImage: "linear-gradient(to top, #9e142a, black",
            fontFamily: "calibri",
          }}
        ></div>
      </div>

      <div className="  relative p-4 md:p-4 z-10 text-white ">
        <div className="block grid md:grid-cols-6 md:mx-8 mx-2 justify-between ">
          <div className="md:p-4 inline-block">
            <h2>Mercy Girls' High School</h2>
            <img
              src="Images/biglogo.png"
              alt="mgsho_icon"
              className=" h-28 w-30  p-2 mx-auto  "
            />
            <div
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-delay="300"
              data-aos-offset="0"
              className="w-full mx-auto px-1 md:px-3"
            >
              <h4 className="text-sm md:text-sm  text-center text-amber-300">
                About Our School
              </h4>
            </div>
          </div>
          <div
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1500"
            className="py-2  text-zinc-200 col-span-5 space-y-2 text-sans md:p-4  text-sm md:text-base justify-start w-full "
          >
            <p>
              Mercy was established by Most Rev. Dr. Anthony Nwedo CSSP to train
              and prepare young people(girls) for challenging feature educative
              lives. In his effort to protect and educate the to be well trained
              and educated to meet their goals. In the heart of the founder, An
              avanue for self-development was given birth to, It was named
              'Mercy Girls High School.
            </p>

            <p>
              Mercy the oldest queen in town has passed through many transitory
              stages, she went through governments after the civil war in 1967
              to jenauary 1970 and came back to the mission after 40 years of
              waiting, with her glory intact, and become a panacea to women.
            </p>
            <p>
              The pride of the girl child is her name. In their quest to give
              hope to the 'girl child' These great men and woman, gave their
              very best to this institution and have made her become a paradigm
              of the excellence of girl education.
              <a href="About" className="text-amber-300">
                More..
              </a>
            </p>
          </div>
        </div>
        <div className="block grid md:grid-cols-6 md:mx-8 mx-2 justify-between mt-2 md:mt-4 ">
          <div
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            className="col-span-6 md:col-span-1 item-center  md:p-4 w-full inline-block"
          >
            <img
              src="Images/bishop.jpg"
              alt="mgsho_icon"
              className="  h-[15rem] w-[14rem]   mx-auto md:mx-2 md:h-40 md:w-40 p-2 m-2 border-2 border-gray-200"
            />
            <h3 className="text-[11px] text-center">
              Most Rev. Dr. Anthony Gogo Nwedo CSSP
            </h3>
            <p className="text-[11px]  text-center">Founder</p>
          </div>
          <div
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
            className=" col-span-5 md:py-10 p-4  text-sm md:text-base justify-start w-full md:w-full"
          >
            A young, energetic, hansome and vibrant principal of Madonna High
            School Ihitte; an Engligh Language and Literature double major was
            consecrated first Bishop of Umuahia Diocese in 1959. The erudite
            scholar, a man with vision and mission, who later in life came to be
            known and call "The education Bishop", was the Most Rev. Dr. Anthony
            Gogo Nwedo CSSP, of the blessed memory
          </div>
        </div>
      </div>
    </div>
  );
};
export default AboutHome;
