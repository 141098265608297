import React from "react";
import Nav1 from "../../Components/Quicklink";
import Navbar from "../../Components/navigation";
import Footer3 from "../../Components/footer3";
import BlogSection1 from "../../SubPages/Blog/Blogsection1";

const Ncc = () => {
  return (
    <>
      <div className="max-w-[1460px] mx-auto  content-center">
        <Nav1 />
        <Navbar />
        <BlogSection1 />
        <div>
          <div className="grid md:grid-cols-2 md:mx-auto  p-2">
            <div
              className="text-justify p-4 bg-gray-100 col-span-1 "
              style={{ fontFamily: "calibri" }}
            >
              <h3 className="font-bold text-xl">
                Bridging the Digital Divide: NCC's Rural Broadband Initiative
                Empowers Mercy Girls High School Okigwe with Cutting-Edge ICT
                Complex.
              </h3>{" "}
              <img
                className=" p-4"
                src="Images/indesign.jpg"
                alt="Ncc at mercy girls high school okigwe"
              />
              <p>
                In a concerted effort to bridge the digital divide and empower
                underserved communities, the Nigeria Communications Commission
                (NCC) has launched a groundbreaking rural broadband initiative.
                Supported by the Universal Service Provision Fund (USPF), this
                initiative aims to provide internet connectivity to remote
                areas, catalyzing socio-economic development and fostering
                inclusive growth. As part of this initiative, Mercy Girls High
                School Okigwe has been selected to receive an innovative ICT
                complex, spearheaded by iNterra Networks LTD Abuja, marking a
                significant milestone in the quest for universal access to
                digital resources and education.
              </p>
              <h4 className="font-semibold text-lg">
                {" "}
                Empowering Education with Connectivity:{" "}
              </h4>
              <p>
                The provision of an ICT complex to Mercy Girls High School
                Okigwe represents a transformative leap towards modernizing
                education in rural communities. Equipped with high-speed
                internet connectivity and state-of-the-art technology
                infrastructure, this complex opens up a world of possibilities
                for students, enabling them to access online educational
                resources, collaborate with peers globally, and engage in
                immersive learning experiences.
              </p>
              <img
                className=" "
                src="Images/ncclogo.jpeg"
                alt="Ncc at mercy girls high school okigwe"
              />
              <p>
                By breaking down barriers to information and knowledge, NCC's
                rural broadband initiative empowers students to unlock their
                full potential and become active participants in the digital
                economy.
              </p>
              <h4 className="font-semibold text-lg">
                Catalyzing Innovation and Entrepreneurship:
              </h4>{" "}
              <p>
                {" "}
                Beyond the classroom, the ICT complex serves as a catalyst for
                innovation and entrepreneurship, nurturing a culture of
                creativity and problem-solving among students. Through
                specialized training programs, mentorship initiatives, and
                entrepreneurship workshops facilitated by iNterra Networks LTD
                Abuja, students are equipped with the skills and knowledge
                needed to leverage technology for social impact and economic
                empowerment. By fostering a spirit of innovation and
                entrepreneurship, NCC's initiative not only prepares students
                for future careers but also cultivates a generation of
                change-makers who are poised to drive sustainable development in
                their communities.
              </p>
              <h4 className="font-semibold text-lg">
                Community Engagement and Collaboration:
              </h4>
              <p>
                NCC's rural broadband initiative is more than just a
                technological intervention—it is a catalyst for community
                engagement and collaboration. By partnering with local
                stakeholders, including Mercy Girls High School Okigwe and
                iNterra Networks LTD Abuja, NCC fosters a sense of ownership and
                sustainability, ensuring that the benefits of connectivity
                extend beyond the school gates and permeate every aspect of
                community life. Through collaborative efforts to leverage
                technology for social good, NCC and its partners are laying the
                groundwork for inclusive development and empowering communities
                to chart their own path towards prosperity.
              </p>
              <p>
                The partnership between NCC, USPF, Mercy Girls High School
                Okigwe, and iNterra Networks LTD Abuja exemplifies the
                transformative potential of technology in driving socio-economic
                development and empowering marginalized communities. Through the
                provision of an ICT complex and broadband connectivity, NCC's
                rural broadband initiative is not only expanding access to
                education but also catalyzing innovation, entrepreneurship, and
                community development. As we celebrate this milestone, let us
                redouble our efforts to ensure that every community in Nigeria
                has access to the digital tools and resources needed to thrive
                in the 21st century
              </p>
            </div>
            <div
              style={{ fontFamily: "calibri" }}
              className=" p-4 bg-gray-100 "
            >
              <h2 className="font-bold text-xl text-zinc-400 mt-4">
                Recent Blogs
              </h2>
              <div className="p-2">
                <div className="grid md:grid-cols-2 p-4 m-4 ">
                  <div className="mx-auto mx-w-sm">
                    <h3 className="text-md font-bold px-2">
                      Living by Design Nation (LBD) Launches "Say No to
                      Examination Malpractice" Campaign at Mercy Girls High
                      School okigwe
                    </h3>
                    <section
                      style={{ fontFamily: "sans-serif" }}
                      className="px-6 text-sm mt-2 text-justify text-zinc-500"
                    >
                      In a world where academic integrity is paramount, Living
                      by Design Nation (LBD) has taken...
                    </section>
                  </div>
                  <div className="w-full">
                    <img
                      className=""
                      src="Images/living_logo.jpg"
                      alt="nnpc at mercy girls high school okigwe"
                    />
                    <a
                      href="lbd"
                      className="bg-zinc-200 relative mx-auto hover:text-mghso-10 p-4 text-center "
                    >
                      Continue Reading
                    </a>
                  </div>
                </div>

                {/* second Blog headline */}
                <div className="grid md:grid-cols-2 p-4  m-4">
                  <div className="mx-auto mx-w-sm">
                    <h3 className="text-md font-bold px-2 ">
                      Lumix Graphics Transforms Mercy Girls High School Okigwe's
                      Website and Facebook Page with
                    </h3>
                    <section
                      style={{ fontFamily: "sans-serif" }}
                      className="px-6 text-sm mt-2 text-justify text-zinc-500"
                    >
                      In a digital age where online presence is paramount, Mercy
                      Girls High School Okigwe has received a remarkable
                      makeover thanks to Lumix Graphics and its
                    </section>
                  </div>
                  <div className="w-full">
                    <img
                      className=""
                      src="Images/graphics_logo.jpg"
                      alt="prawa at mercy girls high school okigwe"
                    />
                    <a
                      href="Lumix"
                      className="bg-zinc-200 relative mx-auto hover:text-mghso-10 p-4 text-center "
                    >
                      Continue Reading
                    </a>
                  </div>
                </div>
                {/* 3rd Blog headline */}
                <div className="grid md:grid-cols-2  p-4 m-4">
                  <div className="mx-auto mx-w-sm">
                    <h3 className="text-md font-bold px-2 ">
                      Prawa NGO Champions Girl Child Education with Support from
                      Mercy Girls High School Okigwe Alumni
                    </h3>
                    <section
                      style={{ fontFamily: "sans-serif" }}
                      className="px-6 text-sm mt-2 text-justify text-zinc-500"
                    >
                      In a world where education remains a powerful tool for
                      empowerment and social change, Prawa NGO stands at the
                      forefront of...
                    </section>
                  </div>
                  <div className="w-full">
                    <img
                      className=""
                      src="Images/prawaflyer.jpg"
                      alt="prawa at mercy girls high school okigwe"
                    />
                    <a
                      href="prawa"
                      className="bg-zinc-200 relative mx-auto hover:text-mghso-10 p-4 text-center "
                    >
                      Continue Reading
                    </a>
                  </div>
                </div>
                {/*4th section */}
                <div className="grid md:grid-cols-2 p-4 m-4 ">
                  <div className="mx-auto mx-w-sm">
                    <h3 className="text-md font-bold px-2">
                      Celebrating Excellence: Mercy Girls High School Shines in
                      WAEC 2022/2023 Examinations
                    </h3>
                    <section
                      style={{ fontFamily: "sans-serif" }}
                      className="px-6 text-sm mt-2 text-justify text-zinc-500"
                    >
                      Mercy Girls High School, a beacon of academic excellence,
                      has once again demonstrated its commitment to nurturing
                      bright minds and fostering academic brilliance.
                    </section>
                  </div>
                  <div className="w-full">
                    <img
                      className=""
                      src="Images/waeclogo.jpeg"
                      alt="nnpc at mercy girls high school okigwe"
                    />
                    <a
                      href="result"
                      className="bg-zinc-200 relative mx-auto hover:text-mghso-10 p-4 text-center "
                    >
                      Continue Reading
                    </a>
                  </div>
                </div>
                <div className="grid md:grid-cols-2 p-4 m-4 ">
                  <div className="mx-auto mx-w-sm">
                    <h3 className="text-md font-bold px-2">
                      Celebrating 60 Years of Excellence: Mercy Girls High
                      School's Inter House Sports Competition
                    </h3>
                    <section
                      style={{ fontFamily: "sans-serif" }}
                      className="px-6 text-sm mt-2 text-justify text-zinc-500"
                    >
                      Mercy Girls High School, a cornerstone of education and
                      excellence, recently celebrated its 60th anniversary with
                      great pomp and grandeur.
                    </section>
                  </div>
                  <div className="w-full">
                    <img
                      className=""
                      src="Images/houses.jpg"
                      alt="nnpc at mercy girls high school okigwe"
                    />
                    <a
                      href="result"
                      className="bg-zinc-200 relative mx-auto hover:text-mghso-10 p-4 text-center "
                    >
                      Continue Reading
                    </a>
                  </div>
                </div>
                {/*4th Blog Headline*/}
                <div className="grid md:grid-cols-2 p-4  m-4">
                  <div className="mx-auto mx-w-sm">
                    <h3 className="text-md font-bold px-2 ">
                      NNPC LTD Enhances STEM Education at Mercy Girls High
                      School Okigwe with Generous Donation
                    </h3>
                    <section
                      style={{ fontFamily: "sans-serif" }}
                      className="px-6 text-sm mt-2 text-justify text-zinc-500"
                    >
                      In a commendable gesture towards promoting education and
                      empowering young minds in the field of STEM (Science,
                      Technology...
                    </section>
                  </div>
                  <div className="w-full">
                    <img
                      className=""
                      src="Images/nnpc_board.jpg"
                      alt="nnpc at mercy girls high school okigwe"
                    />
                    <a
                      href="Blog"
                      className="bg-zinc-200 relative mx-auto hover:text-mghso-10 p-4 text-center "
                    >
                      Continue Reading
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer3 />
      </div>
    </>
  );
};
export default Ncc;
