import React from "react";
import Nav1 from "../../Components/Quicklink";
import Navbar from "../../Components/navigation";
import Footer3 from "../../Components/footer3";
import BlogSection1 from "../../SubPages/Blog/Blogsection1";

const Rsults = () => {
  return (
    <>
      <div className="max-w-[1460px] mx-auto content-center">
        <Nav1 />
        <Navbar />
        <BlogSection1 />
        <div>
          <div className="grid md:grid-cols-2 md:mx-auto  p-2">
            <div
              className="text-justify p-4 bg-gray-100 col-span-1 "
              style={{ fontFamily: "calibri" }}
            >
              <h3 className="font-bold text-xl">
                Celebrating Excellence: Mercy Girls High School Shines in WAEC
                2022/2023 Examinations
              </h3>{" "}
              <p>
                Mercy Girls High School, a beacon of academic excellence, has
                once again demonstrated its commitment to nurturing bright minds
                and fostering academic brilliance. In the recent West African
                Senior Secondary School Certificate Examination (WASSCE),
                several students from Mercy Girls High School showcased
                outstanding performance, bringing pride and honor to their alma
                mater.{" "}
                <img
                  className=" p-4"
                  src="Images/waecresult_Winers.jpg"
                  alt="Prawa at mercy girls high school okigwe"
                />
              </p>
              <p>
                {" "}
                Among these remarkable students are Oti Miracle, Theodore
                Divine, Udeh Chidimma Juliet, Okoh Bethel Chioma, Onyedikachi
                Cecilia, and many others whose dedication, hard work, and
                resilience have paid off in flying colors.{" "}
              </p>
              <p>
                Oti Miracle, a name that truly reflects her achievement, has not
                only excelled academically but has also inspired her peers with
                her diligence and perseverance. Her stellar performance in the
                WASSCE examinations serves as a testament to her unwavering
                commitment to academic excellence.
              </p>{" "}
              <p>
                Theodore Divine, another shining star from Mercy Girls High
                School, has demonstrated exceptional intelligence and a thirst
                for knowledge. Her remarkable scores in the WASSCE examinations
                underscore her determination to succeed and her ability to
                overcome challenges.
              </p>{" "}
              <div className="grid grid-cols-3">
                <img
                  className=" h-40 w-45 md:h-45 md:w-40 p-4"
                  src="Images/divine.jpg"
                  alt="Prawa at mercy girls high school okigwe"
                />
                <img
                  className=" h-40 w-45 md:h-45 md:w-40 p-4"
                  src="Images/divine.jpg"
                  alt="Prawa at mercy girls high school okigwe"
                />
                <img
                  className=" h-40 w-45 md:h-45 md:w-40 p-4"
                  src="Images/ikejibeResult.jpg"
                  alt="Prawa at mercy girls high school okigwe"
                />
              </div>
              <p>
                Udeh Chidimma Juliet, with her brilliant mind and relentless
                pursuit of excellence, has left an indelible mark on Mercy Girls
                High School. Her outstanding performance in the WASSCE
                examinations reflects her dedication to achieving her goals and
                aspirations.
              </p>{" "}
              <p>
                Okoh Bethel Chioma, a name synonymous with excellence, has made
                Mercy Girls High School proud with her remarkable achievements
                in the WASSCE examinations. Her academic prowess and unwavering
                determination serve as an inspiration to her peers and juniors
                alike.{" "}
              </p>{" "}
              <p>
                Onyedikachi Cecilia, a shining example of perseverance and
                dedication, has emerged victorious in the WASSCE examinations,
                showcasing her exceptional intellect and unwavering commitment
                to success.
              </p>{" "}
              <p>
                These exemplary students, along with their peers, have not only
                made their families proud but have also brought glory to Mercy
                Girls High School. Their remarkable achievements serve as a
                testament to the school's commitment to academic excellence and
                the nurturing of well-rounded individuals.
              </p>{" "}
              <p>
                The success of these students would not have been possible
                without the dedicated efforts of the teachers and staff at Mercy
                Girls High School, who have worked tirelessly to impart
                knowledge, instill values, and inspire greatness in their
                students.
              </p>{" "}
              <p>
                {" "}
                As we celebrate the outstanding performance of these students,
                let us also recognize the importance of hard work,
                determination, and perseverance in achieving success. May their
                achievements serve as a source of inspiration to current and
                future students, motivating them to strive for excellence in all
                their endeavors.
              </p>{" "}
              <p>
                Congratulations to Oti Miracle, Theodore Divine, Udeh Chidimma
                Juliet, Okoh Bethel Chioma, Onyedikachi Cecilia, and all the
                students of Mercy Girls High School who have excelled in the
                WASSCE examinations. Your achievements are a testament to your
                talent, dedication, and resilience. Keep shining bright and
                reaching for the
              </p>
            </div>
            <div
              style={{ fontFamily: "calibri" }}
              className=" p-4 bg-gray-100 "
            >
              <h2 className="font-bold text-xl text-zinc-400 mt-4">
                Recent Blogs
              </h2>
              <div className="p-2">
                <div className="grid md:grid-cols-2  p-4 m-4">
                  <div className="mx-auto mx-w-sm">
                    <h3 className="text-md font-bold px-2 ">
                      Prawa NGO Champions Girl Child Education with Support from
                      Mercy Girls High School Okigwe Alumni
                    </h3>
                    <section
                      style={{ fontFamily: "sans-serif" }}
                      className="px-6 text-sm mt-2 text-justify text-zinc-500"
                    >
                      In a world where education remains a powerful tool for
                      empowerment and social change, Prawa NGO stands at the
                      forefront of...
                    </section>
                  </div>
                  <div className="w-full">
                    <img
                      className=""
                      src="Images/prawaflyer.jpg"
                      alt="prawa at mercy girls high school okigwe"
                    />
                    <a
                      href="prawa"
                      className="bg-zinc-200 relative mx-auto hover:text-mghso-10 p-4 text-center "
                    >
                      Continue Reading
                    </a>
                  </div>
                </div>

                {/* second Blog headline */}
                <div className="grid md:grid-cols-2 p-4  m-4">
                  <div className="mx-auto mx-w-sm">
                    <h3 className="text-md font-bold px-2 ">
                      Lumix Graphics Transforms Mercy Girls High School Okigwe's
                      Website and Facebook Page with
                    </h3>
                    <section
                      style={{ fontFamily: "sans-serif" }}
                      className="px-6 text-sm mt-2 text-justify text-zinc-500"
                    >
                      In a digital age where online presence is paramount, Mercy
                      Girls High School Okigwe has received a remarkable
                      makeover thanks to Lumix Graphics and its
                    </section>
                  </div>
                  <div className="w-full">
                    <img
                      className=""
                      src="Images/graphics_logo.jpg"
                      alt="prawa at mercy girls high school okigwe"
                    />
                    <a
                      href="Lumix"
                      className="bg-zinc-200 relative mx-auto hover:text-mghso-10 p-4 text-center "
                    >
                      Continue Reading
                    </a>
                  </div>
                </div>
                {/* 3rd Blog headline */}
                <div className="grid md:grid-cols-2 p-4 m-4">
                  <div className="mx-auto mx-w-sm">
                    <h3 className="text-md font-bold px-2 ">
                      NCC's Rural Broadband Initiative Empowers Mercy Girls High
                      School Okigwe with Cutting-Edge ICT Complex.
                    </h3>
                    <section
                      style={{ fontFamily: "sans-serif" }}
                      className="px-6 text-sm mt-2 text-justify text-zinc-500"
                    >
                      In a concerted effort to bridge the digital divide and
                      empower underserved communities,
                    </section>
                  </div>
                  <div className="w-full">
                    <img
                      className=""
                      src="Images/indesign.jpg"
                      alt="nnc at mercy girls high school okigwe"
                    />
                    <a
                      href="ncc"
                      className="bg-zinc-200 relative mx-auto hover:text-mghso-10 p-4 text-center "
                    >
                      Continue Reading
                    </a>
                  </div>
                </div>
                {/*4th Blog Headline*/}
                <div className="grid md:grid-cols-2 p-4 m-4 ">
                  <div className="mx-auto mx-w-sm">
                    <h3 className="text-md font-bold px-2">
                      Living by Design Nation (LBD) Launches "Say No to
                      Examination Malpractice" Campaign at Mercy Girls High
                      School okigwe
                    </h3>
                    <section
                      style={{ fontFamily: "sans-serif" }}
                      className="px-6 text-sm mt-2 text-justify text-zinc-500"
                    >
                      In a world where academic integrity is paramount, Living
                      by Design Nation (LBD) has taken...
                    </section>
                  </div>
                  <div className="w-full">
                    <img
                      className=""
                      src="Images/living_logo.jpg"
                      alt="nnpc at mercy girls high school okigwe"
                    />
                    <a
                      href="lbd"
                      className="bg-zinc-200 relative mx-auto hover:text-mghso-10 p-4 text-center "
                    >
                      Continue Reading
                    </a>
                  </div>
                </div>
                <div className="grid md:grid-cols-2 p-4 m-4 ">
                  <div className="mx-auto mx-w-sm">
                    <h3 className="text-md font-bold px-2">
                      Celebrating 60 Years of Excellence: Mercy Girls High
                      School's Inter House Sports Competition
                    </h3>
                    <section
                      style={{ fontFamily: "sans-serif" }}
                      className="px-6 text-sm mt-2 text-justify text-zinc-500"
                    >
                      Mercy Girls High School, a cornerstone of education and
                      excellence, recently celebrated its 60th anniversary with
                      great pomp and grandeur.
                    </section>
                  </div>
                  <div className="w-full">
                    <img
                      className=""
                      src="Images/houses.jpg"
                      alt="nnpc at mercy girls high school okigwe"
                    />
                    <a
                      href="result"
                      className="bg-zinc-200 relative mx-auto hover:text-mghso-10 p-4 text-center "
                    >
                      Continue Reading
                    </a>
                  </div>
                </div>
                {/*   section5 */}
                <div className="grid md:grid-cols-2 p-4  m-4">
                  <div className="mx-auto mx-w-sm">
                    <h3 className="text-md font-bold px-2 ">
                      NNPC LTD Enhances STEM Education at Mercy Girls High
                      School Okigwe with Generous Donation
                    </h3>
                    <section
                      style={{ fontFamily: "sans-serif" }}
                      className="px-6 text-sm mt-2 text-justify text-zinc-500"
                    >
                      In a commendable gesture towards promoting education and
                      empowering young minds in the field of STEM (Science,
                      Technology...
                    </section>
                  </div>
                  <div className="w-full">
                    <img
                      className=""
                      src="Images/nnpc_board.jpg"
                      alt="nnpc at mercy girls high school okigwe"
                    />
                    <a
                      href="Blog"
                      className="bg-zinc-200 relative mx-auto hover:text-mghso-10 p-4 text-center "
                    >
                      Continue Reading
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer3 />
      </div>
    </>
  );
};
export default Rsults;
