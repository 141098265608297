import React from "react";
import { FaLink } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";
const Section5 = () => {
  return (
    <div
      style={{ fontFamily: "calibri" }}
      className="grid md:grid-cols-2 lg:grid-cols-3 sm:grid-cols-2 gap-4 relative block m-8 md:m-20 justify-between"
    >
      <div className="relative z-10 mx-auto ">
        <div className=" absolute inset-0 w-full h-full transition-opacity">
          {" "}
          <img
            className="w-full h-full object-cover object-center"
            src="Images/lib.jpeg"
            alt="Slide"
          />
          <div className="absolute inset-0 bg-mghso-10 opacity-70"></div>
        </div>
        <div className="relative py-12 px-20 mx-auto ">
          <div
            style={{ fontFamily: "schmalfette" }}
            className="flex text-xl pt-4 md:pt-8 text-white"
          >
            <div className="  text-amber-300 px-2">
              <FaLink />
            </div>
            <h3>Quick Links</h3>
          </div>
          <div className="py-2 border-b-[1px] border-gray-300 w-full">
            <a
              href="Alumni"
              className=" text-amber-300 text-sm hover:text-white text-yellow "
            >
              Alumni And Donors
            </a>
          </div>
          <div className="py-2 border-b-[1px] border-gray-300 w-full">
            <a
              href="Academics"
              className=" text-amber-300 text-sm hover:text-white  text-yellow "
            >
              Academic Programs
            </a>
          </div>
          <div className="py-2 border-b-[1px] border-gray-300 w-full">
            <a
              href="sports"
              className=" text-amber-300 text-sm  hover:text-white text-yellow "
            >
              Sports{" "}
            </a>
          </div>
          <div className="py-2 border-b-[1px] border-gray-300 w-full">
            <a
              href="Academics"
              className=" text-amber-300 text-sm hover:text-white  text-yellow "
            >
              School Policy{" "}
            </a>
          </div>
          <div className="py-2 border-b-[1px] border-gray-300 w-full">
            <a
              href="ErrorPage"
              className=" text-amber-300 hover:text-white text-sm text-yellow "
            >
              Mercy @60{" "}
            </a>
          </div>
          <div className="py-2 border-b-[1px] border-gray-300 w-full">
            <a
              href="ErrorPage"
              className=" text-amber-300 text-sm hover:text-white text-yellow "
            >
              FAQ{" "}
            </a>
          </div>
        </div>
      </div>
      <div className=" px-4 mx-auto pt-4 md:pt-[1px]">
        <h3 className="font-bold text-black text-lg pb-2">
          Join The Mercy Girls
        </h3>
        Students who inspire to be part of this citadel of leaning must meet the
        following Requriements:{" "}
        <ul className="list-disc p-2 space-y-2">
          <li>
            {" "}
            Age: Student must meet the minimum age requirement for admisssion,
            which is usually 10-11 years of age for junior secondary (JSS1) and
            13-14 years old for Senior secondary school (SSS1).
          </li>
          <li>
            Academic performance: Students must have good academic performance,
            usually demonstrated through the results of the common entrance
            examination.
          </li>
          <li>
            Financial Capability: Parents must ensure that their financial
            capacity should be able to carry their Girl through .....
          </li>
        </ul>
        <div className="mt-6 pl-4 sm:mt-[1px]">
          <a
            href="Academics"
            className="inline-block border-2 hover:border-gray-200 no-underline px-4 py-2 md:px-6 md:py-3 text-lg font-semibold bg-mghso-10 hover:bg-black  text-white "
          >
            Get Started
          </a>
        </div>
      </div>
      <div className="relative md:col-span-2 lg:col-span-1 z-10 overflow-hidden  mt-4 md:mt-[1px] ">
        <div className=" absolute inset-0 w-full h-full transition-opacity">
          {" "}
          <img
            className="w-full h-full object-cover object-center"
            src="Images/schoolsignboard.jpg"
            alt="Slide"
          />
          <div className="absolute inset-0 bg-mghso-10 opacity-80"></div>
        </div>
        <div className="relative p-8 mt-8">
          <FaEnvelope className=" w-full  text-[3rem] text-center text-amber-300" />

          <h3 className="text-center pt-8 px-auto text-base font-semifold text-white">
            Subscribe To
          </h3>
          <h3 className="text-center  text-base font-semifold text-white">
            Newsletter
          </h3>
          <h4 className="px-auto text-center  p-4 font-mono mx-auto text-gray-400 text-sm md:text-base">
            Get Update to news and events
          </h4>
          <div className="mt-2 pl-4 sm:mt-10">
            <input
              type="text"
              placeholder="Your Emall Address"
              className="w-full inline-block px-4 py-2 md:px-6 md:py-3  "
            />
          </div>
          <div className="mt-2 pl-4 sm:mt-10">
            <a
              href="#your-link"
              className="inline-block border-[2px] hover:border-gray-200 no-underline px-4 py-2 md:px-6 md:py-3 text-lg font-semibold bg-amber-300 hover:bg-black w-full text-center text-white "
            >
              Subscribe
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section5;
