import React from "react";
import { FaMusic } from "react-icons/fa";

const AboutSection4 = () => {
  return (
    <>
      <div className="grid  lg:grid-cols-3">
        <div className="p-8 mt-8">
          <FaMusic size={30} className="text-amber-300" />
          <h2 className="py-2 text-xl font-bold"> Our Song</h2>
          <div className="text-justiy text-[15px]">
            <p>
              <i>Mercy Girls High School, Okigwe where contemporary </i>
            </p>
            <p>
              <i>learning and academic excellence is assured through a</i>
            </p>
            <p>
              <i>holistic and qualitative methods of Catholic Education</i>
            </p>
            <p>
              {" "}
              <i>
                We impact knowledge and skills not compromising our core values
              </i>
            </p>
            <p>
              {" "}
              <i>
                decipline, integrity, puntuality, responsibility, deligence,
                team work and creativity
              </i>
            </p>
            <p>
              {" "}
              <i>With God who is our strength </i>
            </p>
            <p>
              <i>Mercy Girls we are truly the best and always number one!!!</i>
            </p>
            <img
              src="Images/band2.jpg"
              alt="student in the field "
              className="sticky"
            />
          </div>
        </div>
        <div className="col-span-2">
          <img
            src="Images/student1.jpg"
            alt="student in the field "
            className="sticky"
          />
        </div>
      </div>
    </>
  );
};
export default AboutSection4;
