import React from "react";
import Nav1 from "../Components/Quicklink";
import Navbar from "../Components/navigation";
import HeroSection from "../SubPages/Home/HeroSection";
import Quicklink1 from "../SubPages/Home/Quicklink";
import WelcomeSection from "../SubPages/Home/welcomSection";
import AboutHome from "../SubPages/Home/AboutHome";
import MoreRection from "../SubPages/Home/MoreSection";
import Section1 from "../SubPages/Home/section3";
import Section4 from "../SubPages/Home/section4";
import Section5 from "../SubPages/Home/section5";
import Partners from "../SubPages/Home/partnerssection";
import Footer3 from "../Components/footer3";
import CommentAndApply from "../SubPages/Home/comment&Apply";

const HomePage = () => {
  return (
    <>
      <div className="max-w-[1440px] mx-auto  content-center">
        <Nav1 />
        <Navbar />
        <HeroSection />
        <Quicklink1 />
        <WelcomeSection />
        <AboutHome />
        <MoreRection />

        <Section1 />
        <CommentAndApply />
        <Section4 />
        <Section5 />
        <Partners />
        <Footer3 />
      </div>
    </>
  );
};
export default HomePage;
