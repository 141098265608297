import React from "react";
import Nav1 from "../../../../Components/Quicklink";
import Navbar from "../../../../Components/navigation";
import SchSection1 from "./schsection1";
import SchSection2 from "./schsection2";
import Footer3 from "../../../../Components/footer3";
const FaqSection = () => {
  return (
    <div>
      <Nav1 />
      <Navbar />
      <SchSection1 />
      <SchSection2 />
      <Footer3 />
    </div>
  );
};
export default FaqSection;
