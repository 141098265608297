import React from "react";

const AlumniSection3 = () => {
  const data = [
    {
      src: "ogechi1.jpg",
      Header: "Ogechi Chinyere Ogu Esq",
      Paragraph1:
        " Ogechi Chinyere Ogu Esq. is the Deputy Director , Prisoners’ Rehabilitation and Welfare Action (PRAWA) and ....",
      Link: "Ogechi",
    },
    {
      src: "uju.jpg",
      Header: " Dr. Uju Agomoh Bsc, M.sc, PGD, M.Phil, LLB, BL, PhD",
      Paragraph1:
        "Dr. Uju Agomoh is the Executive Director Prisoners’ Rehabilitation And Welfare Action (PRAWA) – with a mission of promoting institutional reforms in formal and informal sector...",
      Link: "ujusection",
    },

    {
      src: "euch.jpg",
      Header: " Eucharia Okpugo-Enofe - M",
      Paragraph1:
        "Eucharia graduated from 'Mercy High School, 'Okigwe' She moved  on to the completion of her...... ",
      Link: "eucheria",
    },

    {
      src: "ohia.jpg",
      Header: "Ohia Josephine",
      Paragraph1:
        "Eucharia graduated from 'Mercy High School, 'Okigwe' She moved  on to the completion of her...... ",
      Link: "Ohia",
    },
  ];
  return (
    <div className="  justify-between bg-mghso-10">
      <h2 className="text-center w-[100%] pt-12 text-xl font-bold text-gray-100">
        Noteable Alumni
      </h2>
      <div className="w-[20%] h-1 mx-auto bg-white m-2"></div>
      <div className="grid md:grid-cols-4 px-8 md:px-28 ">
        {data.map((item, index) => (
          <div key={index} className=" text-white p-4 md:p-4 font-sans  w-full">
            <div className="bg-gray-100 shadow-md basis-1/4">
              <div className="p-4">
                <img
                  className=" object-cover pb-2 object-center"
                  src={`Images/${item.src}`}
                  alt="Ugochi"
                />
                <div className="text-lg text-black font-semibold pb-2">
                  {item.Header}
                </div>
                <div className="text-sm text-gray-600">{item.Paragraph1}</div>
                <div className="text-sm">{item.Paragraph2}</div>
                <div className="text-sm">{item.Paragraph3}</div>
                <button className="bg-mghso-10 w-[100%] p-2 mt-4">
                  <a href={`${item.Link}`}> Read More </a>
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default AlumniSection3;
