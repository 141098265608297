//import logo from "./logo.svg";
import "./App.css";

//import Container from "react-bootstrap/esm/Container";
import News from "./NewPages/News/News";
import Sport from "./NewPages/Sports";
import Contact from "./NewPages/Contact";
import Staff from "./Pages/Staff";
import Alumni from "./NewPages/Community/Alumni";
import Academic from "./NewPages/Academics";
import About from "./NewPages/About";
import Toursection from "./NewPages/Features/Taketour";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Container from "react-bootstrap/esm/Container";
import Ujusection from "./SubPages/Alumni/NotableAlumni/Uju/Ujusection";
import Ohiasection from "./SubPages/Alumni/NotableAlumni/Ohia/Ohia";
import Euchsection from "./SubPages/Alumni/NotableAlumni/Euch/eucheria";
import Ogechisection from "./SubPages/Alumni/NotableAlumni/Ogechi/Ogechi";
import Error from "./Pages/Error";
import Gallerysection from "./NewPages/Features/Gallery";
import Mghso60 from "./Pages/mghso60";
import HomePage from "./NewPages/HomePage";
import Education from "./SubPages/Home/SubPagies/Education/education";
import SchoolLife from "./SubPages/Home/SubPagies/schoollife/schoolLife";
import SpiritSection from "./SubPages/Home/SubPagies/spirituality/spirituality";
import Blog from "./NewPages/Blog";
import Lumix from "./NewPages/Blogs/Lumix";
import Ncc from "./NewPages/Blogs/ncc";
import Prawa from "./NewPages/Blogs/Prawa";
import Lbd from "./NewPages/Blogs/lbd";
import Rsults from "./NewPages/Blogs/result";
import InterSports from "./NewPages/Blogs/InterSports";
import ZephTech from "./NewPages/Blogs/zephtech";
import ErrorPage from "./Components/ErrorPage";
import Support from "./NewPages/Community/PillersOfSupport";

function App() {
  return (
    <Container fluid>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route path="/HomePage" element={<HomePage />} />
          <Route path="/News" element={<News />} />
          <Route path="/Sports" element={<Sport />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Staff" element={<Staff />} />
          <Route path="/Alumni" element={<Alumni />} />
          <Route path="/Academics" element={<Academic />} />
          <Route path="/About" element={<About />} />
          <Route path="/Ujusection" element={<Ujusection />} />
          <Route path="/Ohia" element={<Ohiasection />} />
          <Route path="/Ogechi" element={<Ogechisection />} />
          <Route path="/eucheria" element={<Euchsection />} />
          <Route path="/Error" element={<Error />} />
          <Route path="/Gallery" element={<Gallerysection />} />
          <Route path="/mghso60" element={<Mghso60 />} />
          <Route path="/Taketour" element={<Toursection />} />
          <Route path="/education" element={<Education />} />
          <Route path="/schoolLife" element={<SchoolLife />} />
          <Route path="/spirituality" element={<SpiritSection />} />
          <Route path="/Blog" element={<Blog />} />
          <Route path="/Lumix" element={<Lumix />} />
          <Route path="/ncc" element={<Ncc />} />
          <Route path="/Prawa" element={<Prawa />} />
          <Route path="/lbd" element={<Lbd />} />
          <Route path="result" element={<Rsults />} />
          <Route path="InterSports" element={<InterSports />} />
          <Route path="zephtech" element={<ZephTech />} />{" "}
          <Route path="ErrorPage" element={<ErrorPage />} />
          <Route path="Donations" element={<Support />} />

        </Routes>
      </BrowserRouter>
    </Container>

    /* <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a>
        </header>
      </div> */
  );
}

export default App;
