import React from "react";
import Nav1 from "../../Components/Quicklink";
import Navbar from "../../Components/navigation";
import Footer3 from "../../Components/footer3";
import BlogSection1 from "../../SubPages/Blog/Blogsection1";

const InterSports = () => {
  return (
    <>
      <div className="max-w-[1460px] mx-auto  content-center">
        <Nav1 />
        <Navbar />
        <BlogSection1 />
        <div>
          <div className="grid md:grid-cols-2 md:mx-auto  p-2">
            <div
              className="text-justify p-4 bg-gray-100 col-span-1 "
              style={{ fontFamily: "calibri" }}
            >
              <h3 className="font-bold text-xl">
                Celebrating 60 Years of Excellence: Mercy Girls High School's
                Inter House Sports Competition
              </h3>{" "}
              <p>
                Mercy Girls High School, a cornerstone of education and
                excellence, recently celebrated its 60th anniversary with great
                pomp and grandeur. As part of the festivities, an exhilarating
                Inter House Sports Competition was organized, showcasing the
                school's rich tradition of fostering talent, sportsmanship, and
                camaraderie.{" "}
              </p>{" "}
              <p>
                {" "}
                The event was graced by esteemed guests, including the school's
                distinguished alumna, Dr. Uju Agomoh, the founder of PRAWA
                (Prisoners' Rehabilitation and Welfare Action), and her
                associate, Dr. Onwubiko, a member of the Living By Design
                nation. Also in attendance were the PTA chairman, Mr. Obioma
                Asomugha, members of the clergy, and other dignitaries, who
                added to the excitement of the occasion.
                <img
                  className=" p-4"
                  src="Images/houses.jpg"
                  alt="Prawa at mercy girls high school okigwe"
                />
              </p>
              <p>
                {" "}
                The Inter House Sports Competition featured a wide array of
                events that tested the athletic prowess and teamwork of the
                students. From traditional track and field events like shot put,
                high jump, and long jump to indoor sports such as table tennis
                and badminton, there was something for everyone to enjoy.
                Additionally, fun-filled activities like the sack race, egg
                race, and the highly anticipated 100m relay race kept the
                spectators on the on the edge of their seats.
              </p>
              <p>
                One of the highlights of the event was the participation of
                sister schools, including All Saints Model Secondary Technical
                School Ugwuaku and Queen of Apostle Secondary School, fostering
                a spirit of friendly competition and collaboration among the
                students.
              </p>{" "}
              <p>
                As the day unfolded, the atmosphere was filled with cheers and
                applause as the students showcased their skills and
                determination in each event. The march past, a symbol of unity
                and school pride, further added to the spectacle, with each
                house displaying their colors with pride and enthusiasm
              </p>{" "}
              <p>
                At the end of the day, as the dust settled and the scores were
                tallied, the results of the Inter House Sports Competition were
                announced. In the junior secondary school category, the winners
                emerged with outstanding performances
              </p>{" "}
              <ul className="list-disc p-6">
                <li>
                  <strong>Table Tennis:</strong> Yellow House emerged
                  victorious, with Okoron Juliet leading the team to victory.
                </li>{" "}
                <li>
                  <strong>High Jump:</strong> White House dominated the event,
                  with Chukwudi Chinecherem securing the first position and
                  Chidi Oluebube clinching the second position.
                </li>{" "}
                <li>
                  <strong> Long Jump:</strong> Once again, White House reigned
                  supreme, with Chukwudi Chinecherem taking the first position
                  and Moses Olumachi securing
                </li>
              </ul>{" "}
              <p>
                The joy and excitement were palpable as the winners celebrated
                their achievements, while the spirit of sportsmanship and
                camaraderie echoed throughout the school grounds
              </p>{" "}
              <img
                className="  p-4"
                src="Images/gift_presentation.jpg"
                alt="Prawa at mercy girls high school okigwe"
              />
              <p>
                The joy and excitement were palpable as the winners celebrated
                their achievements, while the spirit of sportsmanship and
                camaraderie echoed throughout the school grounds
              </p>{" "}
              <p>
                Congratulations to all the participants, winners, and organizers
                for making the event a resounding success. Here's to many more
                years of excellence and achievement at Mercy Girls High School
              </p>{" "}
            </div>
            <div
              style={{ fontFamily: "calibri" }}
              className=" p-4 bg-gray-100 "
            >
              <h2 className="font-bold text-xl text-zinc-400 mt-4">
                Recent Blogs
              </h2>
              <div className="p-2">
                <div className="grid md:grid-cols-2  p-4 m-4">
                  <div className="mx-auto mx-w-sm">
                    <h3 className="text-md font-bold px-2 ">
                      Prawa NGO Champions Girl Child Education with Support from
                      Mercy Girls High School Okigwe Alumni
                    </h3>
                    <section
                      style={{ fontFamily: "sans-serif" }}
                      className="px-6 text-sm mt-2 text-justify text-zinc-500"
                    >
                      In a world where education remains a powerful tool for
                      empowerment and social change, Prawa NGO stands at the
                      forefront of...
                    </section>
                  </div>
                  <div className="w-full">
                    <img
                      className=""
                      src="Images/prawaflyer.jpg"
                      alt="prawa at mercy girls high school okigwe"
                    />
                    <a
                      href="prawa"
                      className="bg-zinc-200 relative mx-auto hover:text-mghso-10 p-4 text-center "
                    >
                      Continue Reading
                    </a>
                  </div>
                </div>{" "}
                {/* second Blog headline */}
                <div className="grid md:grid-cols-2 p-4 m-4 ">
                  <div className="mx-auto mx-w-sm">
                    <h3 className="text-md font-bold px-2">
                      Celebrating Excellence: Mercy Girls High School Shines in
                      WAEC 2022/2023 Examinations
                    </h3>
                    <section
                      style={{ fontFamily: "sans-serif" }}
                      className="px-6 text-sm mt-2 text-justify text-zinc-500"
                    >
                      Mercy Girls High School, a beacon of academic excellence,
                      has once again demonstrated its commitment to nurturing
                      bright minds and fostering academic brilliance.
                    </section>
                  </div>
                  <div className="w-full">
                    <img
                      className=""
                      src="Images/living_logo.jpg"
                      alt="nnpc at mercy girls high school okigwe"
                    />
                    <a
                      href="result"
                      className="bg-zinc-200 relative mx-auto hover:text-mghso-10 p-4 text-center "
                    >
                      Continue Reading
                    </a>
                  </div>
                </div>
                {/* second Blog headline */}
                <div className="grid md:grid-cols-2 p-4  m-4">
                  <div className="mx-auto mx-w-sm">
                    <h3 className="text-md font-bold px-2 ">
                      Lumix Graphics Transforms Mercy Girls High School Okigwe's
                      Website and Facebook Page with
                    </h3>
                    <section
                      style={{ fontFamily: "sans-serif" }}
                      className="px-6 text-sm mt-2 text-justify text-zinc-500"
                    >
                      In a digital age where online presence is paramount, Mercy
                      Girls High School Okigwe has received a remarkable
                      makeover thanks to Lumix Graphics and its
                    </section>
                  </div>
                  <div className="w-full">
                    <img
                      className=""
                      src="Images/graphics_logo.jpg"
                      alt="prawa at mercy girls high school okigwe"
                    />
                    <a
                      href="Lumix"
                      className="bg-zinc-200 relative mx-auto hover:text-mghso-10 p-4 text-center "
                    >
                      Continue Reading
                    </a>
                  </div>
                </div>
                {/* 3rd Blog headline */}
                <div className="grid md:grid-cols-2 p-4 m-4">
                  <div className="mx-auto mx-w-sm">
                    <h3 className="text-md font-bold px-2 ">
                      NCC's Rural Broadband Initiative Empowers Mercy Girls High
                      School Okigwe with Cutting-Edge ICT Complex.
                    </h3>
                    <section
                      style={{ fontFamily: "sans-serif" }}
                      className="px-6 text-sm mt-2 text-justify text-zinc-500"
                    >
                      In a concerted effort to bridge the digital divide and
                      empower underserved communities,
                    </section>
                  </div>
                  <div className="w-full">
                    <img
                      className=""
                      src="Images/indesign.jpg"
                      alt="nnc at mercy girls high school okigwe"
                    />
                    <a
                      href="ncc"
                      className="bg-zinc-200 relative mx-auto hover:text-mghso-10 p-4 text-center "
                    >
                      Continue Reading
                    </a>
                  </div>
                </div>
                {/*4th Blog Headline*/}
                <div className="grid md:grid-cols-2 p-4 m-4 ">
                  <div className="mx-auto mx-w-sm">
                    <h3 className="text-md font-bold px-2">
                      Living by Design Nation (LBD) Launches "Say No to
                      Examination Malpractice" Campaign at Mercy Girls High
                      School okigwe
                    </h3>
                    <section
                      style={{ fontFamily: "sans-serif" }}
                      className="px-6 text-sm mt-2 text-justify text-zinc-500"
                    >
                      In a world where academic integrity is paramount, Living
                      by Design Nation (LBD) has taken...
                    </section>
                  </div>
                  <div className="w-full">
                    <img
                      className=""
                      src="Images/living_logo.jpg"
                      alt="nnpc at mercy girls high school okigwe"
                    />
                    <a
                      href="lbd"
                      className="bg-zinc-200 relative mx-auto hover:text-mghso-10 p-4 text-center "
                    >
                      Continue Reading
                    </a>
                  </div>
                </div>
                y{/*   section5 */}
                <div className="grid md:grid-cols-2 p-4  m-4">
                  <div className="mx-auto mx-w-sm">
                    <h3 className="text-md font-bold px-2 ">
                      NNPC LTD Enhances STEM Education at Mercy Girls High
                      School Okigwe with Generous Donation
                    </h3>
                    <section
                      style={{ fontFamily: "sans-serif" }}
                      className="px-6 text-sm mt-2 text-justify text-zinc-500"
                    >
                      In a commendable gesture towards promoting education and
                      empowering young minds in the field of STEM (Science,
                      Technology...
                    </section>
                  </div>
                  <div className="w-full">
                    <img
                      className=""
                      src="Images/nnpc_board.jpg"
                      alt="nnpc at mercy girls high school okigwe"
                    />
                    <a
                      href="Blog"
                      className="bg-zinc-200 relative mx-auto hover:text-mghso-10 p-4 text-center "
                    >
                      Continue Reading
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer3 />
      </div>
    </>
  );
};
export default InterSports;
