import React, { useState, useEffect } from "react";
const SportsSection1 = () => {
  const slides = ["stdntsp.jpg", "School_field.jpg", "School_Compoun2.jpg"];
  const [currentSlide, setCurrentSlide] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  return (
    <div className="relative md:-mt-24 -z-10 overflow-hidden">
      {/* Slides */}
      {slides.map((slide, index) => (
        <div
          key={index}
          className={`absolute inset-0 w-full h-full transition-opacity ${
            index === currentSlide ? "opacity-100" : "opacity-0"
          }`}
        >
          <img
            className="w-full h-full object-cover object-center"
            src={`Images/${slide}`}
            alt={`Slide ${index + 1}`}
          />
          <div className="absolute inset-0 bg-mghso-10 opacity-50"></div>
        </div>
      ))}
      {/* Content */}
      <div className="relative py-40 z-10 text-white md:text-center">
        <p className="mt-4 text-lg pl-4 sm:mt-4 md:mt-4 lg:mt-10 sm:text-xl ">
          {" "}
          Go For Mercy Team!
        </p>
        <h1 className="text-4xl font-extrabold pl-4 leading-tight  sm:text-5xl md:text-6xl lg:text-7xl">
          {" "}
          Sports{" "}
        </h1>
      </div>
    </div>
  );
};
export default SportsSection1;
