import React from "react";
const Partners = () => {
  const data = [
    {
      src: "ncclogo.jpeg",
      Header: "Become A Donor",
      Paragraph:
        " Life at Mercy Girls High School Okigwe is challenging, fun,exciting, hard work, religius, fast-paced and incredibly satisfying. it's also exceptionally busy. One thing college life isn't, is dull.",
    },

    {
      src: "mylogo.png",
      Header: "Our Alumni",
      Paragraph:
        " Life at Mercy At Mercy Girls High School a key factor in becoming outstanding and surpassing all standards anywhere in the world, is the quality of teaching and learning. High School Okigwe is challenging, fun,exciting, hard work, religius, fast-paced and incredibly satisfying. it's also exceptionally busy. One thing college life isn't, is dull.",
    },

    {
      src: "prawa.png",
      Header: "Spirituality",
      Paragraph:
        "Mercy Girls High School is thrilled and proud to celebrate a momentous occasion in our history - our 60th Anniversary! For six decades, we have been committed to providing quality  education, fostering a nurturing environment, and empowering generations of students to become responsible global citizens.",
    },
    {
      src: "inlogo.png",
      Header: "Spirituality",
      Paragraph:
        "Mercy Girls High School is thrilled and proud to celebrate a momentous occasion in our history - our 60th Anniversary! For six decades, we have been committed to providing quality  education, fostering a nurturing environment, and empowering generations of students to become responsible global citizens.",
    },
    {
      src: "graphics_logo.jpg",
      Header: "Spirituality",
      Paragraph:
        "Mercy Girls High School is thrilled and proud to celebrate a momentous occasion in our history - our 60th Anniversary! For six decades, we have been committed to providing quality  education, fostering a nurturing environment, and empowering generations of students to become responsible global citizens.",
    },
    {
      src: "nnpc_logo.jpg",
      Header: "Spirituality",
      Paragraph:
        "Mercy Girls High School is thrilled and proud to celebrate a momentous occasion in our history - our 60th Anniversary! For six decades, we have been committed to providing quality  education, fostering a nurturing environment, and empowering generations of students to become responsible global citizens.",
    },
    {
      src: "living_logo.jpg",
      Header: "Spirituality",
      Paragraph:
        "Mercy Girls High School is thrilled and proud to celebrate a momentous occasion in our history - our 60th Anniversary! For six decades, we have been committed to providing quality  education, fostering a nurturing environment, and empowering generations of students to become responsible global citizens.",
    },
  ];

  return (
    <div className="relative block grid md:grid-cols-7 content-center grid-cols-4  md:grid-cols-4 justify-between bg-zinc-200">
      {data.map((item, index) => (
        <div key={index} className="  p-4 md:p-12 font-sans mx-auto  w-full">
          <img
            src={`Images/${item.src}`}
            alt="Our Mercy Partners"
            className="md:h-[70px] h-[3rem] w-[3rem] md:w-[80px] "
          />
        </div>
      ))}
    </div>
  );
};

export default Partners;
