import React from "react";
const EduSection2 = () => {
  return (
    <div className="relative mt-8 md:-mt-24 -z-10 bg-white overflow-hidden">
      <div className="grid md:grid-cols-2 m-4 p-4 Calibri gap-2  space-y-2 ">
        <div>
          <section className="">
            <i
              data-aos="fade-up"
              data-aos-anchor-placement="top-center"
              className="p-8 m-4 text-sm"
            >
              At Mercy Girls' High School, we are committed to providing
              exceptional educational services that nurture the holistic
              development of our students. Our comprehensive curriculum, taught
              by dedicated educators, fosters academic excellence, critical
              thinking, and lifelong learning skills.{" "}
            </i>
            <h2 className="font-semibold text-md">Educational Services:</h2>
            <p>
              Academic Excellence:Our rigorous academic programs, aligned with
              national and international standards, prepare students for success
              in higher education and beyond. From STEM subjects to humanities,
              we offer a diverse range of courses to cater to every student's
              interests and abilities.{" "}
            </p>
            <div
              data-aos="fade-up"
              data-aos-anchor-placement="top-center"
              className="p-4 m-2"
            >
              <img src="Images/plane.jpg" alt="error" className="p-4 " />
              <i>Students on excursion </i>
            </div>
            <p>
              Experiential Learning: We believe in the power of hands-on,
              experiential learning. Through field trips, excursions, and
              educational tours, students have the opportunity to apply
              classroom knowledge in real-world settings, fostering deeper
              understanding and appreciation for various subjects.{" "}
            </p>
            <div
              data-aos="fade-up"
              data-aos-anchor-placement="top-center"
              className="p-4 m-2
            "
            >
              <img src="Images/excursion.jpg" alt="error" className="p-4" />
              <i>Students on excursion </i>
            </div>
            <p>
              Career Guidance: Our dedicated career guidance counselors provide
              personalized support to help students explore their interests,
              talents, and career aspirations. From career assessments to
              college admissions guidance, we empower students to make informed
              decisions about their future.{" "}
            </p>
            <div
              data-aos="fade-up"
              data-aos-anchor-placement="top-center"
              className="p-4 m-2"
            >
              <img src="Images/laboratory.jpg" alt="error" className="p-4 " />
              <i>Students on Chemistry Pratical Lab </i>
            </div>
            <p>
              {" "}
              Extracurricular Activities: In addition to academics, we offer a
              wide range of extracurricular activities to enrich students'
              educational experience. Whether it's sports, arts, music, or
              community service, students have ample opportunities to explore
              their passions and develop leadership skills outside the
              classroom.{" "}
            </p>
            <p>
              Holistic Development: We prioritize the holistic development of
              our students, emphasizing character education, values-based
              learning, and social-emotional well-being. Through mentorship
              programs, leadership development initiatives, and
              character-building activities, we foster qualities of resilience,
              empathy, and integrity in our students.{" "}
            </p>
          </section>
        </div>
        <div>
          <section>
            <div className="p-4 m-2">
              <img src="Images/lab.jpg" alt="error" className="p-4 " />
              <i>Chemistry Lab </i>
            </div>
            <p>
              Community Engagement: At Mercy Girls' High School, we are deeply
              connected to our community. Through service-learning projects,
              outreach programs, and collaborative partnerships with local
              organizations, students learn the importance of civic
              responsibility and global citizenship.
            </p>
            <div className="p-4 m-2">
              <img
                src="Images/Student_in_farm.jpg"
                alt="error"
                className="p-4 "
              />
              <i>Students on school farm </i>
            </div>
            <h3>Students on Excursion:</h3> Our school regularly organizes
            educational excursions and field trips to enhance students' learning
            experiences outside the classroom. From historical sites to
            scientific laboratories, students have the opportunity to explore
            diverse destinations and gain firsthand knowledge in various
            disciplines. These excursions not only supplement classroom learning
            but also promote curiosity, critical thinking, and cultural
            appreciation among students. Join us at Mercy Girls' High School,
            where excellence in education meets a nurturing and supportive
            environment for every student to thrive{" "}
            <div className="p-4 m-2">
              <img src="Images/graduation.jpg" alt="error" className="p-4 " />
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};
export default EduSection2;
