import { Card } from "react-bootstrap";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import CardImg from "react-bootstrap/esm/CardImg";
import Row from "react-bootstrap/esm/Row";
import Button from "react-bootstrap/esm/Button";

const Error = () => {
  return (
    <Container fluid>
      <Col
        className="img-fluid d-sm-none d-xs-block opacity-50 d-flex justify-content-center align-items-center"
        style={{
          backgroundImage: 'url("Images/alumnibg.jpeg")',
          width: "100%",
          padddingTop: "10",
          //height: "30rem",
          backgroundPosition: "center",
        }}
      ></Col>
      <Col
        className="img-fluid d-none d-md-block opacity-50 d-flex justify-content-center align-items-center"
        style={{
          backgroundImage: 'url("Images/alumnibg.jpeg")',
          width: "100%",
          //height: "30rem",
          backgroundPosition: "center",
        }}
      ></Col>
      <Col className="border-0  text-center">
        <Row xs={1} md={2}>
          <Card className="d-none d-md-block border-0  p-5">
            <Card.Text>
              <CardImg
                className="shadow p-5  bg-white rounded"
                style={{ height: "25rem" }}
                src="Images/E404.jpeg"
                alt="mercy_girls_high_school_okigwe"
              ></CardImg>
            </Card.Text>
          </Card>
          {/* small screen */}
          <Card className="d-sm-none d-xs-block border-0  p-1">
            <Card.Text>
              <CardImg
                className=" shadow p-1  bg-white rounded"
                style={{ height: "auto" }}
                src="Images/E404.jpeg"
                alt="mercy_girls_high_school_okigwe"
              ></CardImg>
            </Card.Text>
          </Card>
          <Card className="border-0  p-5">
            <CardImg
              className="d-none d-md-block shadow p-5  bg-white rounded"
              style={{ height: "25rem" }}
              src="Images/E4041.jpeg"
              alt="mercy_girls_high_school_okigwe"
            ></CardImg>
          </Card>

          {/* small screen */}
          <Card className="d-sm-none d-xs-block border-0  p-1">
            <CardImg
              className=" shadow p-1  bg-white rounded"
              style={{ height: "auto" }}
              src="Images/E4041.jpeg"
              alt="mercy_girls_high_school_okigwe"
            ></CardImg>
          </Card>
        </Row>
        <Col className="fome">
          <h1>Opps</h1>
          <p>Maintenance is ongoing, Please check back later</p>
          <i>Return to Homepage </i>
          <p>
            {" "}
            <Button
              href="Home"
              className="border-0"
              style={{ backgroundColor: "#AC2D5A", color: "white" }}
            >
              Home
            </Button>{" "}
          </p>
        </Col>
        <hr></hr>
      </Col>
    </Container>
  );
};
export default Error;
