import React from "react";
import {
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa";

const contactsection4 = () => {
  return (
    <>
      <div>
        <div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3969.3438427821197!2d7.34582481418
5292!3d5.807020795792093!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x10431bd4eebec52d%3A0x4c
7b07a58fb83608!2sMercy%20Girls%20High%20School%20Okigwe!5e0!3m2!1sen!2sng!4v1679488437944!5m2!
1sen!2sng"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            title="map"
            className="border-0  h-[27rem] w-[100%]"
          ></iframe>
        </div>
        <div>
          <div className="max-w-md p-4 mx-auto grid grid-cols-5">
            <FaFacebook /> <FaInstagram />
            <FaTwitter />
            <FaWhatsapp />
            <FaYoutube />
          </div>
        </div>
      </div>
    </>
  );
};
export default contactsection4;
