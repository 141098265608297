import React, { useState } from "react";
import { IoArrowDownCircle } from "react-icons/io5";

const Navbar = () => {
  const downloadCV = () => {
    const link = document.createElement("a");
    link.href = "File/GSSO_MGHSO_ALUMNI.pdf";
    link.download = "GSSO_MGHSO_ALUMNI.pdf";
    link.click();
  };

  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  // State to track whether the dropdown is open
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpen1, setDropdownOpen1] = useState(false);

  // Function to toggle the dropdown
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const toggleDropdown1 = () => {
    setDropdownOpen1(!dropdownOpen1);
  };
  return (
    <nav className="bg-white fixed md:static z-40 w-full md:floa-center px-6 py-2 ">
      <div className="flex items-center justify-between ">
        {/* Logo or Brand */}{" "}
        <div className="flex justify-between text-mghso-10 ">
          {" "}
          <img
            src="Images/biglogo.png"
            className="h-16 sm:h-12 sm:w-12 w-16 md:h-26 mx-2 md:w-26"
            alt="Mercy Girls logo"
          />{" "}
          <div className="pt-2 md:pt-0 pl-2 border-l-2 border-mghso-10">
            <div className="md:text-base  text-sm">
              Catholic Diocese of Okigwe
            </div>
            <div className=" text-mghso-10 text-lg text-sm md:text-base font-bold">
              Mercy Girls High School Okigwe
            </div>
          </div>
        </div>
        {/* Mobile Menu Button */}{" "}
        <button
          className="lg:hidden text-mghso-10 focus:outline-none"
          onClick={toggleMenu}
        >
          <svg
            className="h-6 w-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16m-7 6h7"
            ></path>
          </svg>
        </button>
        {/* Desktop Menu */}{" "}
        <div className="hidden bg-white md:text-sm text-sm lg:flex mx-10 space-x-2">
          <a
            href="HomePage"
            className="text-mghso-10 no-underline min-h-4 hover:border-b-2 border-mghso-10 px-2 py-2 "
          >
            {" "}
            Home
          </a>
          <a
            href="Academics"
            className="text-mghso-10 no-underline hover:border-b-2 border-mghso-10 px-2 py-2 "
          >
            {" "}
            Academics
          </a>
          <a
            href="Blog"
            className="text-mghso-10 no-underline hover:border-b-2 border-mghso-10 px-2 py-2 "
          >
            {" "}
            Blog
          </a>
          <div className="pt-2 py-2 relative group">
            <button className="text-mghso-10 hover:text-gray-300">
              Featurs
            </button>
            <div className="absolute text-white hidden mt-2 space-y-2 bg-mghso-10 group-hover:block">
              <a
                href="Gallery"
                className="block px-4 py-2 hover:border-b-2 border-gray-200"
              >
                Gallery
              </a>
              <a
                href="Sports"
                className="block px-4 py-2 hover:border-b-2 border-gray-200"
              >
                {" "}
                Sports
              </a>
              <a
                href="ErrorPage"
                className="block px-4 py-2 hover:border-b-2 border-gray-200"
              >
                Taketour
              </a>
            </div>
          </div>
          <a
            href="News"
            className="text-mghso-10 no-underline hover:border-b-2 border-mghso-10 px-2 py-2 "
          >
            {" "}
            News
          </a>
          <a
            href="About"
            className="text-mghso-10  no-underline hover:border-b-2 border-mghso-10 px-2 py-2 "
          >
            {" "}
            About{" "}
          </a>
          <a
            href="Contact"
            className="text-mghso-10 no-underline hover:border-b-2 border-mghso-10 px-2 py-2 "
          >
            {" "}
            Contact{" "}
          </a>
          <div className="pt-2 py-2 relative group">
            <button className="text-mghso-10 hover:text-gray-300">
              Community
            </button>
            <div className="absolute text-white hidden  mt-2 space-y-2 bg-mghso-10 group-hover:block">
              <a
                href="Alumni"
                className="block px-4 py-2 hover:border-b-2 border-gray-200"
              >
                Alumni
              </a>
              <a
                href="ErrorPage"
                className="block whitespace-nowrap px-4 py-2 hover:border-b-2 max-w-sm border-gray-200"
              >
                Hall of firm
              </a>
              <button
                onClick={downloadCV}
                className="block px-4 py-2 whitespace-nowrap hover:border-b-2 border-gray-200"
              >
                Mercy chronicles
              </button>
              <a
                href="ErrorPage"
                className="block px-4 py-2 hover:border-b-2 border-gray-200"
              >
                PTA
              </a>
              <a
                href="Donations"
                className="block px-4 py-2 hover:border-b-2 border-gray-200"
              >
                Donation
              </a>
            </div>
          </div>
          {/* Add more menu items as needed */}{" "}
        </div>
      </div>
      {/* Off-canvas Menu */}
      {menuOpen && (
        <div className="lg:hidden absolute top-0 mx-auto left-0 w-full h-full text-mghso-10 bg-white">
          <div className="flex justify-between pt-2 px-4 pb-0">
            <img
              src="Images/biglogo.png"
              className="h-12 w-12 "
              alt="Mercy Girls logo"
            />{" "}
            <div className="  mt-3 mr-2 ">
              <div className=" text-mghso-10 text-lg font-bold">MGHSO</div>
            </div>
            <button
              className="text-mghso-10 mghso focus:outline-none"
              onClick={toggleMenu}
            >
              <svg
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>
          <div className="flex flex-col m-4 bg-mghso-10/30 backdrop-blur-lg text-center items-center">
            <a
              href="HomePage"
              className="text-white hover:bg-gray-800 hover:text-gray-300 shadow-md border-b-2 border-mghso-10 no-underline hover:scale-125 w-full pb-2  "
            >
              Home
            </a>{" "}
            <a
              href="Academics"
              className="text-white border-b-2 hover:bg-gray-800 hover:text-gray-300 border-mghso-10  hover:scale-125 w-full py-2 no-underline "
            >
              Academic{" "}
            </a>{" "}
            <a
              href="Blog"
              className="text-white border-b-2 border-mghso-10 hover:bg-gray-800 hover:text-gray-300 hover:scale-125 w-full py-2 no-underline "
            >
              Blog{" "}
            </a>{" "}
            {/*drop down*/}
            <div className="border-b-2 border-mghso-10  text-white  hover:scale-125 w-full pb-2 no-underline ">
              <div className="flex">
                <button
                  className="dropdown-toggle  px-2 hover:scale-125 w-full py-2 no-underline "
                  onClick={toggleDropdown1}
                >
                  Features{" "}
                </button>{" "}
                <span className="p-2 mt-1">
                  {" "}
                  <IoArrowDownCircle />
                </span>
              </div>
              <div>
                {dropdownOpen1 && (
                  <ul className="dropdown-menu bg-mghso-10">
                    <li>
                      <a
                        className="hover:scale-125 text-sm m-2 border-b-2 border-gray-300 "
                        href="Gallery"
                      >
                        Gallery
                      </a>
                    </li>
                    <li>
                      <a
                        className="hover:scale-125 text-sm m-2 border-b-2 border-gray-300"
                        href="Sports"
                      >
                        Sports
                      </a>
                    </li>
                    <li>
                      <a
                        className="hover:scale-125 text-sm m-2 border-b-2 border-gray-300"
                        href="ErrorPage"
                      >
                        Take a Tour
                      </a>
                    </li>
                  </ul>
                )}
              </div>
            </div>
            <a
              href="News"
              className="text-white border-b-2 border-mghso-10 hover:bg-gray-800 hover:text-gray-300 hover:scale-125 w-full py-2 no-underline "
            >
              News{" "}
            </a>
            <a
              href="About"
              className="text-white border-b-2 border-mghso-10 hover:bg-gray-800 hover:text-gray-300 hover:scale-125 w-full py-2 no-underline "
            >
              About{" "}
            </a>{" "}
            <a
              href="Contact"
              className="text-white border-b-2 border-mghso-10 hover:bg-gray-800 hover:text-gray-300 hover:scale-125 w-full py-2 no-underline "
            >
              Contact{" "}
            </a>{" "}
            <div className="border-b-2 border-mghso-10 text-white w-full">
              <div className="flex">
                <button
                  className="dropdown-toggle  px-2 hover:scale-125 w-full py-2 no-underline "
                  onClick={toggleDropdown}
                >
                  Community{" "}
                </button>{" "}
                <span className="p-2 mt-1">
                  {" "}
                  <IoArrowDownCircle />
                </span>
              </div>
              <div>
                {dropdownOpen && (
                  <ul className="dropdown-menu bg-mghso-10 p-4 w-full">
                    <li>
                      <a
                        className="hover:scale-125 text-sm m-2 border-b-2 border-gray-300 "
                        href="Alumni"
                      >
                        Alumni
                      </a>
                    </li>
                    <li>
                      <a
                        className="hover:scale-125 text-sm m-2 border-b-2 border-gray-300"
                        href="ErrorPage"
                      >
                        Hall Of Fame
                      </a>
                    </li>
                    <li>
                      <button
                        onClick={downloadCV}
                        className="hover:scale-125 text-sm m-2 border-b-2 border-gray-300"
                      >
                        {" "}
                        Mercy chronicles
                      </button>
                    </li>
                    <li>
                      <a
                        className="hover:scale-125  text-sm m-2 border-b-2 border-gray-300"
                        href="ErrorPage"
                      >
                        {" "}
                        PTA
                      </a>
                    </li>
                    <li>
                      <a
                        className="hover:scale-125  text-sm m-2 border-b-2 border-gray-300"
                        href="Donations"
                      >
                        {" "}
                        Donation
                      </a>
                    </li>
                  </ul>
                )}
              </div>
            </div>
            {/* Add more menu items as needed */}{" "}
          </div>
        </div>
      )}{" "}
    </nav>
  );
};
export default Navbar;
