import React from "react";
import Nav1 from "../../Components/Quicklink";
import Navbar from "../../Components/navigation";
import Footer3 from "../../Components/footer3";
import BlogSection1 from "../../SubPages/Blog/Blogsection1";

const Prawa = () => {
  return (
    <>
      <div className="max-w-[1460px] mx-auto  content-center">
        <Nav1 />
        <Navbar />
        <BlogSection1 />
        <div>
          <div className="grid md:grid-cols-2 md:mx-auto  p-2">
            <div
              className="text-justify p-4 bg-gray-100 col-span-1 "
              style={{ fontFamily: "calibri" }}
            >
              <h3 className="font-bold text-xl">
                Empowering Girls Through Education: Prawa NGO Champions Girl
                Child Education with Support from Mercy Girls High School Okigwe
                Alumni
              </h3>{" "}
              <img
                className=" p-4"
                src="Images/prawaflyer.jpg"
                alt="Prawa at mercy girls high school okigwe"
              />
              <p>
                In a world where education remains a powerful tool for
                empowerment and social change, Prawa NGO stands at the forefront
                of championing girl child education. Led by its founder and CEO,
                Dr. Uju Agomoh, an esteemed alumna of Mercy Girls High School
                Okigwe, Prawa NGO is committed to breaking down barriers and
                creating opportunities for girls to access quality education.
                Through innovative programs and strategic partnerships, Prawa
                NGO is reshaping the narrative and ensuring that every girl has
                the chance to fulfill her potential.
              </p>
              <h4 className="font-semibold text-lg"> A Visionary Leader: </h4>
              <p>
                Dr. Uju Agomoh's journey from Mercy Girls High School Okigwe to
                the helm of Prawa NGO is a testament to the transformative power
                of education. As a passionate advocate for girls' rights and
                empowerment, Dr. Agomoh brings her personal experiences and
                expertise to bear in driving positive change. Her unwavering
                commitment to education as a catalyst for social transformation
                inspires others to join the cause and invest in the future of
                girls everywhere.
              </p>
              <h4 className="font-semibold text-lg">
                Supporting Access to Education:
              </h4>{" "}
              <p>
                {" "}
                Prawa NGO's initiatives go beyond rhetoric, actively supporting
                access to education for girls in marginalized communities.
                Through scholarships, mentorship programs, and advocacy efforts,
                Prawa NGO ensures that financial constraints and societal norms
                do not hinder girls from pursuing their educational aspirations.
                By providing resources, guidance, and encouragement, Prawa NGO
                equips girls with the tools they need to succeed academically
                and realize their dreams
              </p>
              <h4 className="font-semibold text-lg">
                Empowering Through Knowledge:
              </h4>
              <p>
                Education is not just about acquiring knowledge; it is about
                empowerment and self-discovery. Prawa NGO recognizes this and
                goes the extra mile to empower girls with life skills,
                leadership training, and opportunities for personal growth. By
                instilling confidence, resilience, and a sense of purpose, Prawa
                NGO prepares girls to navigate the challenges of life with grace
                and determination, equipping them to become leaders and
                change-makers in their communities.
              </p>
              <h4 className="font-semibold text-lg">
                Building Sustainable Futures:
              </h4>
              <p>
                The impact of Prawa NGO's work extends far beyond the classroom,
                laying the foundation for sustainable futures and inclusive
                development. By investing in girls' education, Prawa NGO
                contributes to poverty reduction, gender equality, and social
                cohesion, creating ripple effects that benefit entire
                communities. Through partnerships with schools like Mercy Girls
                High School Okigwe, Prawa NGO leverages the power of education
                to break the cycle of poverty and empower girls to shape their
                own destinies.
              </p>
              <p>
                As Prawa NGO continues to champion girl child education, its
                partnership with Mercy Girls High School Okigwe alumni like Dr.
                Uju Agomoh serves as a beacon of hope for girls everywhere. By
                investing in education and empowerment, Prawa NGO is not just
                changing lives; it is transforming societies and building a
                brighter future for all. As we celebrate the progress made, let
                us recommit ourselves to ensuring that every girl has the
                opportunity to unleash her full potential and thrive in a world
                where education knows no bounds.
              </p>
            </div>
            <div
              style={{ fontFamily: "calibri" }}
              className=" p-4 bg-gray-100 "
            >
              <h2 className="font-bold text-xl text-zinc-400 mt-4">
                Recent Blogs
              </h2>
              <div className="p-2">
                <div className="grid md:grid-cols-2 p-4 m-4 ">
                  <div className="mx-auto mx-w-sm">
                    <h3 className="text-md font-bold px-2">
                      Living by Design Nation (LBD) Launches "Say No to
                      Examination Malpractice" Campaign at Mercy Girls High
                      School okigwe
                    </h3>
                    <section
                      style={{ fontFamily: "sans-serif" }}
                      className="px-6 text-sm mt-2 text-justify text-zinc-500"
                    >
                      In a world where academic integrity is paramount, Living
                      by Design Nation (LBD) has taken...
                    </section>
                  </div>
                  <div className="w-full">
                    <img
                      className=""
                      src="Images/living_logo.jpg"
                      alt="nnpc at mercy girls high school okigwe"
                    />
                    <a
                      href="lbd"
                      className="bg-zinc-200 relative mx-auto hover:text-mghso-10 p-4 text-center "
                    >
                      Continue Reading
                    </a>
                  </div>
                </div>

                {/* second Blog headline */}
                <div className="grid md:grid-cols-2 p-4  m-4">
                  <div className="mx-auto mx-w-sm">
                    <h3 className="text-md font-bold px-2 ">
                      Lumix Graphics Transforms Mercy Girls High School Okigwe's
                      Website and Facebook Page with
                    </h3>
                    <section
                      style={{ fontFamily: "sans-serif" }}
                      className="px-6 text-sm mt-2 text-justify text-zinc-500"
                    >
                      In a digital age where online presence is paramount, Mercy
                      Girls High School Okigwe has received a remarkable
                      makeover thanks to Lumix Graphics and its
                    </section>
                  </div>
                  <div className="w-full">
                    <img
                      className=""
                      src="Images/graphics_logo.jpg"
                      alt="prawa at mercy girls high school okigwe"
                    />
                    <a
                      href="Lumix"
                      className="bg-zinc-200 relative mx-auto hover:text-mghso-10 p-4 text-center "
                    >
                      Continue Reading
                    </a>
                  </div>
                </div>
                {/* 3rd Blog headline */}
                <div className="grid md:grid-cols-2 p-4 m-4">
                  <div className="mx-auto mx-w-sm">
                    <h3 className="text-md font-bold px-2 ">
                      NCC's Rural Broadband Initiative Empowers Mercy Girls High
                      School Okigwe with Cutting-Edge ICT Complex.
                    </h3>
                    <section
                      style={{ fontFamily: "sans-serif" }}
                      className="px-6 text-sm mt-2 text-justify text-zinc-500"
                    >
                      In a concerted effort to bridge the digital divide and
                      empower underserved communities,
                    </section>
                  </div>
                  <div className="w-full">
                    <img
                      className=""
                      src="Images/indesign.jpg"
                      alt="nnc at mercy girls high school okigwe"
                    />
                    <a
                      href="ncc"
                      className="bg-zinc-200 relative mx-auto hover:text-mghso-10 p-4 text-center "
                    >
                      Continue Reading
                    </a>
                  </div>
                </div>
                {/*#rd Blog */}
                <div className="grid md:grid-cols-2 p-4 m-4 ">
                  <div className="mx-auto mx-w-sm">
                    <h3 className="text-md font-bold px-2">
                      Celebrating Excellence: Mercy Girls High School Shines in
                      WAEC 2022/2023 Examinations
                    </h3>
                    <section
                      style={{ fontFamily: "sans-serif" }}
                      className="px-6 text-sm mt-2 text-justify text-zinc-500"
                    >
                      Mercy Girls High School, a beacon of academic excellence,
                      has once again demonstrated its commitment to nurturing
                      bright minds and fostering academic brilliance.
                    </section>
                  </div>
                  <div className="w-full">
                    <img
                      className=""
                      src="Images/waeclogo.jpeg"
                      alt="nnpc at mercy girls high school okigwe"
                    />
                    <a
                      href="result"
                      className="bg-zinc-200 relative mx-auto hover:text-mghso-10 p-4 text-center "
                    >
                      Continue Reading
                    </a>
                  </div>
                </div>
                <div className="grid md:grid-cols-2 p-4 m-4 ">
                  <div className="mx-auto mx-w-sm">
                    <h3 className="text-md font-bold px-2">
                      Celebrating 60 Years of Excellence: Mercy Girls High
                      School's Inter House Sports Competition
                    </h3>
                    <section
                      style={{ fontFamily: "sans-serif" }}
                      className="px-6 text-sm mt-2 text-justify text-zinc-500"
                    >
                      Mercy Girls High School, a cornerstone of education and
                      excellence, recently celebrated its 60th anniversary with
                      great pomp and grandeur.
                    </section>
                  </div>
                  <div className="w-full">
                    <img
                      className=""
                      src="Images/houses.jpg"
                      alt="nnpc at mercy girls high school okigwe"
                    />
                    <a
                      href="result"
                      className="bg-zinc-200 relative mx-auto hover:text-mghso-10 p-4 text-center "
                    >
                      Continue Reading
                    </a>
                  </div>
                </div>
                {/*4th Blog Headline*/}
                <div className="grid md:grid-cols-2 p-4  m-4">
                  <div className="mx-auto mx-w-sm">
                    <h3 className="text-md font-bold px-2 ">
                      NNPC LTD Enhances STEM Education at Mercy Girls High
                      School Okigwe with Generous Donation
                    </h3>
                    <section
                      style={{ fontFamily: "sans-serif" }}
                      className="px-6 text-sm mt-2 text-justify text-zinc-500"
                    >
                      In a commendable gesture towards promoting education and
                      empowering young minds in the field of STEM (Science,
                      Technology...
                    </section>
                  </div>
                  <div className="w-full">
                    <img
                      className=""
                      src="Images/nnpc_board.jpg"
                      alt="nnpc at mercy girls high school okigwe"
                    />
                    <a
                      href="Blog"
                      className="bg-zinc-200 relative mx-auto hover:text-mghso-10 p-4 text-center "
                    >
                      Continue Reading
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer3 />
      </div>
    </>
  );
};
export default Prawa;
