import React from "react";
//import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Card from "react-bootstrap/esm/Card";
import Row from "react-bootstrap/esm/Row";
import CardImg from "react-bootstrap/esm/CardImg";
import Col from "react-bootstrap/esm/Col";

const Calender = () => {
  return (
    <Container
      fluid
      style={{ backgroundImage: "linear-gradient(to top, #E8BAAA, white" }}
    >
      <Col
        className="img-fluid d-sm-none d-xs-block opacity-50 d-flex justify-content-center align-items-center"
        style={{
          backgroundImage: 'url("Images/lib.jpeg")',
          width: "100%",
          padddingTop: "10px",
          marginTop: "5rem",
          backgroundPosition: "center",
        }}
      >
        <h1
          className="  d-flex justify-content-center align-items-center"
          style={{
            color: "white",
            fontSize: "60px",
            paddingTop: "50px",
            textShadow: "1px 1px 2px black",

            paddingBottom: "50px",
          }}
        >
          MGHSO STAFF
        </h1>
      </Col>

      <Col
        className="img-fluid d-none d-md-block opacity-50 d-flex justify-content-center align-items-center"
        style={{
          backgroundImage: 'url("Images/lib.jpeg")',
          width: "100%",
          //height: "30rem",
          backgroundPosition: "center",
        }}
      >
        <h1
          style={{
            color: "white",
            textShadow: "2px 2px 3px black",
            textAlign: "center",

            fontSize: "60px",
            paddingTop: "50px",
            paddingBottom: "50px",
          }}
        >
          MGHSO STAFF
        </h1>
      </Col>

      <Card.Header className="text-center">
        <div
          style={{
            width: "auto",
            height: "10px",
            background: "linear-gradient(to left, #AC2D5A, white",
            padding: "0",
            margin: "0",
          }}
        ></div>
      </Card.Header>
      <hr></hr>
      <Col>
        {/*  Pricipals And Dean  */}
        <hr></hr>
        <Row xs={1} md={3} xl={4} className="g-3">
          <Card>
            <CardImg
              style={{ height: "15rem" }}
              src="Images/frwhite2.jpg"
            ></CardImg>{" "}
            <Card.Header>Rev Fr. Remigius Ogu</Card.Header>
            <Card.Body>
              <Card.Text>
                <p style={{ margin: "0", padding: "0", fontSize: "11px" }}>
                  Pricipal
                </p>
                <p style={{ margin: "0", padding: "0", fontSize: "11px" }}>
                  Loading....{" "}
                </p>
                <p style={{ margin: "0", padding: "0", fontSize: "11px" }}>
                  +2348068491189{" "}
                </p>
                <p style={{ margin: "0", padding: "0", fontSize: "11px" }}>
                  oguremigius@gmail.com{" "}
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <CardImg
              style={{ height: "15rem" }}
              src="Images/rose.jpg"
            ></CardImg>
            <Card.Header>Mrs Ekuma Roseline Uchenna</Card.Header>
            <Card.Body>
              <p style={{ margin: "0", padding: "0", fontSize: "11px" }}>
                Vice Principal{" "}
              </p>

              <p style={{ margin: "0", padding: "0", fontSize: "11px" }}>
                0806617075{" "}
              </p>
              <p style={{ margin: "0", padding: "0", fontSize: "11px" }}>
                ekumaroseline@gmail.com
              </p>
            </Card.Body>
          </Card>
          <Card>
            <CardImg
              style={{ height: "15rem" }}
              src="Images/sisr.jpg"
            ></CardImg>
            <Card.Header>Rev. Sr, Mary-Francis Okoye</Card.Header>
            <Card.Body>
              <p style={{ margin: "0", padding: "0", fontSize: "11px" }}>
                Business Studies
              </p>
              <p style={{ margin: "0", padding: "0", fontSize: "11px" }}>
                ND Secretarial Studies
              </p>
              <p style={{ margin: "0", padding: "0", fontSize: "11px" }}>
                Loading.....
              </p>
              <p style={{ margin: "0", padding: "0", fontSize: "11px" }}>
                Loading....
              </p>
            </Card.Body>
          </Card>
          <Card>
            <CardImg
              style={{ height: "15rem" }}
              src="Images/isaacc.jpg"
            ></CardImg>{" "}
            <Card.Header>Mrs Isaac Justina Nkiruka</Card.Header>
            <Card.Body>
              <p style={{ margin: "0", padding: "0", fontSize: "11px" }}>
                School Burser
              </p>
              <p style={{ margin: "0", padding: "0", fontSize: "11px" }}>
                BSc. Political Science
              </p>
              <p style={{ margin: "0", padding: "0", fontSize: "11px" }}>
                07057097673
              </p>
              <p style={{ margin: "0", padding: "0", fontSize: "11px" }}>
                nkirukaanyanwu0@gmail.com
              </p>
            </Card.Body>
          </Card>
        </Row>
        {/*   Teachers and Subject */}
        <hr></hr>
        <Row className="g-3" xs={2} md={3} xl={5}>
          <Card>
            <CardImg
              style={{ height: "15rem" }}
              src="Images/revC.jpg"
            ></CardImg>
            <Card.Header>Rev Canon Nnadozie</Card.Header>
            <Card.Body>
              <p style={{ margin: "0", padding: "0", fontSize: "11px" }}>
                Civic Education/Government
              </p>
              <p style={{ margin: "0", padding: "0", fontSize: "11px" }}>
                BSc. Education/Library & Information Sci.
              </p>
              <p style={{ margin: "0", padding: "0", fontSize: "11px" }}>
                07052380549
              </p>
              <p style={{ margin: "0", padding: "0", fontSize: "11px" }}>
                nnadoziejonans69@gmail.com
              </p>
            </Card.Body>
          </Card>

          <Card>
            <CardImg
              style={{ height: "15rem" }}
              src="Images/oscar.jpg"
            ></CardImg>{" "}
            <Card.Header>Agugua Oscar</Card.Header>
            <Card.Body>
              <p style={{ margin: "0", padding: "0", fontSize: "11px" }}>
                School Burser
              </p>
              <p style={{ margin: "0", padding: "0", fontSize: "11px" }}>
                BSc. Human Physiology
              </p>
              <p style={{ margin: "0", padding: "0", fontSize: "11px" }}>
                08063748119
              </p>
              <p style={{ margin: "0", padding: "0", fontSize: "11px" }}>
                oscar4best@gmail.com
              </p>
            </Card.Body>
          </Card>

          <Card>
            <CardImg style={{ height: "15rem" }} src="Images/ike.jpg"></CardImg>{" "}
            <Card.Header>Nwoke Stephen</Card.Header>
            <Card.Body style={{ margin: "0", padding: "0", fontSize: "11px" }}>
              <p style={{ margin: "0", padding: "0" }}>ICT Administrator</p>
              <p style={{ margin: "0", padding: "0" }}>Bsc. Computer Science</p>
              <p style={{ margin: "0", padding: "0" }}>07033640832</p>
              <p style={{ margin: "0", padding: "0" }}>
                nwokestephen788@gmail.com
              </p>
            </Card.Body>
          </Card>

          <Card>
            <CardImg style={{ height: "15rem" }} src="Images/chibu.jpg" />
            <Card.Header>Alaneme Brillian Chibuzo</Card.Header>
            <Card.Body>
              <p style={{ margin: "0", padding: "0", fontSize: "11px" }}>
                Basic Science/Biology Teacher
              </p>
              <p style={{ margin: "0", padding: "0", fontSize: "11px" }}>
                Bsc. Animal Science & Envi. Biology
              </p>
              <p style={{ margin: "0", padding: "0", fontSize: "11px" }}>
                07033640832
              </p>
              <p style={{ margin: "0", padding: "0", fontSize: "11px" }}>
                suntp385@gmail.com
              </p>
            </Card.Body>
          </Card>
        </Row>

        {/*   Other Staff */}
        <hr></hr>
        <Row className="g-3" xs={2} md={3} xl={5}>
          <Card>
            <CardImg
              style={{ height: "10rem" }}
              src="Images/ohia.jpg"
            ></CardImg>{" "}
            <Card.Header>Ohia Josephine</Card.Header>
            <Card.Body>
              <p style={{ margin: "0", padding: "0", fontSize: "11px" }}>
                Food and Nutrition/ Home Economics
              </p>
              <p style={{ margin: "0", padding: "0", fontSize: "11px" }}>
                NCE English
              </p>
              <p style={{ margin: "0", padding: "0", fontSize: "11px" }}>
                07033486183
              </p>
              <p style={{ margin: "0", padding: "0", fontSize: "11px" }}>
                ohiajosephine@gmail.com
              </p>
            </Card.Body>
          </Card>

          <Card>
            <CardImg
              style={{ height: "10rem" }}
              src="Images/onyebuchi.jpg"
            ></CardImg>{" "}
            <Card.Header>Nwaobia Derlington </Card.Header>
            <Card.Body>
              <p style={{ margin: "0", padding: "0", fontSize: "11px" }}>-</p>
              <p style={{ margin: "0", padding: "0", fontSize: "11px" }}>
                BSc. Microbiology
              </p>
              <p style={{ margin: "0", padding: "0", fontSize: "11px" }}>
                08037324569
              </p>
              <p style={{ margin: "0", padding: "0", fontSize: "11px" }}>
                dnwaobia0@gmail.com
              </p>
            </Card.Body>
          </Card>

          <Card>
            <CardImg
              style={{ height: "10rem" }}
              src="Images/getty.png"
            ></CardImg>{" "}
            <Card.Header>Loading......</Card.Header>
            <Card.Body>
              <p style={{ margin: "0", padding: "0", fontSize: "11px" }}>
                Loading...........
              </p>
              <p style={{ margin: "0", padding: "0", fontSize: "11px" }}>
                Loading......{" "}
              </p>
              <p style={{ margin: "0", padding: "0", fontSize: "11px" }}>
                Loading.....
              </p>
              <p style={{ margin: "0", padding: "0", fontSize: "11px" }}>
                Loading....@gmail.com
              </p>
            </Card.Body>
          </Card>

          <Card>
            <CardImg
              style={{ height: "10rem" }}
              src="Images/getty.png"
            ></CardImg>{" "}
            <Card.Header>loading......</Card.Header>
            <Card.Body>
              <p style={{ margin: "0", padding: "0", fontSize: "11px" }}>
                Loading...........
              </p>
              <p style={{ margin: "0", padding: "0", fontSize: "11px" }}>
                Loading......{" "}
              </p>
              <p style={{ margin: "0", padding: "0", fontSize: "11px" }}>
                Loading.....
              </p>
              <p style={{ margin: "0", padding: "0", fontSize: "11px" }}>
                Loading....@gmail.com
              </p>
            </Card.Body>
          </Card>

          <Card>
            <CardImg
              style={{ height: "10rem" }}
              src="Images/getty.png"
            ></CardImg>{" "}
            <Card.Header>loading....</Card.Header>
            <Card.Body>
              <p style={{ margin: "0", padding: "0", fontSize: "11px" }}>
                Loading...........
              </p>
              <p style={{ margin: "0", padding: "0", fontSize: "11px" }}>
                Loading......{" "}
              </p>
              <p style={{ margin: "0", padding: "0", fontSize: "11px" }}>
                Loading.....
              </p>
              <p style={{ margin: "0", padding: "0", fontSize: "11px" }}>
                Loading....@gmail.com
              </p>
            </Card.Body>
          </Card>
        </Row>
        <hr></hr>
      </Col>
    </Container>
  );
};

export default Calender;
