import React from "react";

const AlumniSection4 = () => {
  const downloadCV = () => {
    const link = document.createElement("a");
    link.href = "File/GSSO_MGHSO_ALUMNI.pdf";
    link.download = "GSSO_MGHSO_ALUMNI.pdf";
    link.click();
  };

  return (
    <>
      <div className="relative z-10 ">
        <div className="inset-0 w-full h-full">
          {/*  <div className="absolute inset-0 inset-0 Gradient1 md:Gradient2 opacity-10"></div> */}
          <div className="grid md:grid-cols-3 p-4 md:p-6">
            <div className="col-span-1">
              <img
                className="w-full h-full p-8"
                src="Images/Alumni_Info2.jpg"
                alt="Mercy Alumni"
              />
            </div>
            <div className="  md:mt-8  Roboto">
              <h2 className="font-bold Calibri"> Check Your Name</h2>
              <h3 className="text-md Calibri">
                Official Mercy Girls' High School okigwe Alumni List
              </h3>
              <p className="text-gray-500 py-2 text-sm Cartogothic">
                This list includes students who graduated from mercy Girls' High
                School from 1971 to 2022
              </p>
              <p className="text-gray-500 py-2 text-sm Cartogothic">
                Click below to Download the Lists
              </p>
              <button
                onClick={downloadCV}
                className="bg-mghso-10 text-white px-4 py-2 mx-1 mt-5 sm:px-12 sm:mx-6  hover:black"
              >
                Download List Now
              </button>
            </div>{" "}
            <div className="col-span-1">
              <img
                className="w-full h-full p-8"
                src="Images/flyerList.jpg"
                alt="Mercy Alumni"
              />
            </div>
          </div>{" "}
        </div>
      </div>
    </>
  );
};
export default AlumniSection4;
