import React from "react";
import Nav1 from "../../../../Components/Quicklink";
import Navbar from "../../../../Components/navigation";
import AlumniSection1 from "../../AlumniSection1";
import Footer3 from "../../../../Components/footer3";

const Euchsection = () => {
  return (
    <>
      <div>
        <Nav1 />
        <Navbar />
        <AlumniSection1 />

        <div className="grid md:grid-cols-3 p-6 md:p-20 text-justify">
          <div className="max-w-sm mx-auto">
            <img
              className="  p-6 md:p-8 pb-2 w-[100%] mx-auto object-center"
              src="Images/euch.jpg"
              alt="Ohiasection"
            />
            <h4 className="text-md font-bold px-4 mx-auto md:px-8">
              {" "}
              Eucharia Okpugo-Enofe - M
            </h4>
          </div>
          <div className="col-span-2 md:py-20">
            <p className="text-sm px-4">
              {" "}
              Eucharia graduated from "Mercy High School, Okigwe" . She moved on
              to the completion of her post- secondary degree at the Nigerian
              Journalism Institute, and moved on to serve and is still serving
              with the Nigerian Police Force, and has been recognized for a
              number of trail-blazing awards. The engaging community of women
              that she grew up with at mercy high school gave her the foresight
              to re-evalute what history was and what she thought possible;
              hence pushing her to want to break the societal norms and
              restrictions set up for women which led her to her current work as
              a public servant. I owe both my personal and professional
              development to them
            </p>
          </div>
        </div>
        <Footer3 />
      </div>
    </>
  );
};
export default Euchsection;
