import React from "react";
import Nav1 from "./Quicklink";
import Navbar from "./navigation";
import Footer3 from "./footer3";

const ErrorPage = () => {
  return (
    <>
      <div className="max-w-[1460px] mx-auto content-center">
        <Nav1 />
        <Navbar />
        <div className="flex calibri">
          <div className="p-8 m-20">
            <h1 className="text-[3rem] font-bold">Oops!!</h1>

            <h1 className="text-[7rem] font-semibold">404</h1>
            <h2 className="text-xl font-semibold">Error</h2>
            <h3>The Page You Are looking For Can Not Be Found</h3>
            <p className="pb-4">
              {" "}
              Please Check Back Later as maintainance is ongoing
            </p>
            <a
              href="Homepage"
              className="hover:Black text-gray-200 bg-mghso-10 p-2"
            >
              Return Home
            </a>
          </div>
          <br />

          <div>
            <img src="Images/E4041.jpeg" alt="error" />
          </div>
        </div>
        <Footer3 />
      </div>
    </>
  );
};
export default ErrorPage;
