import React from "react";
import Nav1 from "../../Components/Quicklink";
import Navbar from "../../Components/navigation";
import Footer3 from "../../Components/footer3";
import BlogSection1 from "../../SubPages/Blog/Blogsection1";

const Lbd = () => {
  return (
    <>
      <div className="max-w-[1460px] mx-auto content-center">
        <Nav1 />
        <Navbar />
        <BlogSection1 />
        <div>
          <div className="grid md:grid-cols-2 md:mx-auto  p-2">
            <div
              className="text-justify p-4 bg-gray-100 col-span-1 "
              style={{ fontFamily: "calibri" }}
            >
              <h3 className="font-bold text-xl">
                Promoting Integrity and Empowerment: Living by Design Nation
                (LBD) Launches "Say No to Examination Malpractice" Campaign at
                Mercy Girls High School Okigwe
              </h3>{" "}
              <p>
                In a world where academic integrity is paramount, Living by
                Design Nation (LBD) has taken a bold step towards promoting
                honesty and ethical behavior among students. Through its "Say No
                to Examination Malpractice" campaign, LBD is empowering girls at
                Mercy Girls High School Okigwe to uphold the values of
                integrity, fairness, and excellence in their academic pursuits.
                <img
                  className=" p-4"
                  src="Images/living_logo.jpg"
                  alt="Prawa at mercy girls high school okigwe"
                />
                This initiative not only fosters a culture of honesty but also
                lays the groundwork for a future where education is a pathway to
                success, untainted by dishonest practices.
              </p>
              <h4 className="font-semibold text-lg">
                Empowering Girls through Education:
              </h4>
              <p>
                At the heart of LBD's campaign is a commitment to empowering
                girls through education. By emphasizing the importance of
                integrity in academic settings, LBD equips students with the
                moral compass and ethical principles needed to navigate the
                challenges of the educational journey. Through workshops,
                seminars, and interactive sessions, girls at Mercy Girls High
                School Okigwe are empowered to make informed decisions, resist
                peer pressure, and uphold the highest standards of integrity in
                their academic endeavors
              </p>
              <h4 className="font-semibold text-lg">
                Fostering a Culture of Integrity:
              </h4>{" "}
              <p>
                {" "}
                The "Say No to Examination Malpractice" campaign is more than
                just a slogan—it is a call to action for students to take
                ownership of their academic integrity. Through educational
                materials, posters, and awareness-raising activities, LBD
                creates a supportive environment where girls feel empowered to
                stand up against cheating and uphold the principles of honesty
                and fairness. By fostering a culture of integrity within the
                school community, LBD ensures that academic excellence is
                achieved through hard work, dedication, and ethical conduct.
              </p>
              <h4 className="font-semibold text-lg">
                Promoting Academic Excellence:
              </h4>
              <p>
                By addressing the issue of examination malpractice head-on,
                LBD's campaign promotes academic excellence as the cornerstone
                of educational success. By emphasizing the importance of
                studying diligently, seeking help when needed, and preparing
                thoroughly for exams, LBD encourages girls to take pride in
                their academic achievements and strive for excellence in all
                aspects of their education. Through the cultivation of a
                merit-based academic culture, LBD inspires girls to reach their
                full potential and excel in their chosen fields of study
              </p>
              <h4 className="font-semibold text-lg">
                Creating Agents of Change:
              </h4>
              <p>
                As girls at Mercy Girls High School Okigwe embrace the "Say No
                to Examination Malpractice" campaign, they become agents of
                change within their school community and beyond. By leading by
                example and advocating for integrity, they inspire their peers
                to follow suit and uphold the values of honesty and fairness in
                all aspects of their lives. Through collective action and
                solidarity, LBD's campaign creates a ripple effect of positive
                change, transforming attitudes towards academic integrity and
                paving the way for a brighter future for all.
              </p>
              <p>
                As Living by Design Nation (LBD) launches its "Say No to
                Examination Malpractice" campaign at Mercy Girls High School
                Okigwe, it sends a powerful message that integrity is
                non-negotiable in the pursuit of education. By empowering girls
                to uphold the values of honesty and fairness, LBD not only
                promotes academic excellence but also lays the foundation for a
                future where education is a catalyst for positive change and
                empowerment. As students pledge to say no to examination
                malpractice, they affirm their commitment to integrity and set a
                shining example for others to follow.
              </p>
            </div>
            <div
              style={{ fontFamily: "calibri" }}
              className=" p-4 bg-gray-100 "
            >
              <h2 className="font-bold text-xl text-zinc-400 mt-4">
                Recent Blogs
              </h2>
              <div className="p-2">
                <div className="grid md:grid-cols-2  p-4 m-4">
                  <div className="mx-auto mx-w-sm">
                    <h3 className="text-md font-bold px-2 ">
                      Prawa NGO Champions Girl Child Education with Support from
                      Mercy Girls High School Okigwe Alumni
                    </h3>
                    <section
                      style={{ fontFamily: "sans-serif" }}
                      className="px-6 text-sm mt-2 text-justify text-zinc-500"
                    >
                      In a world where education remains a powerful tool for
                      empowerment and social change, Prawa NGO stands at the
                      forefront of...
                    </section>
                  </div>
                  <div className="w-full">
                    <img
                      className=""
                      src="Images/prawaflyer.jpg"
                      alt="prawa at mercy girls high school okigwe"
                    />
                    <a
                      href="prawa"
                      className="bg-zinc-200 relative mx-auto hover:text-mghso-10 p-4 text-center "
                    >
                      Continue Reading
                    </a>
                  </div>
                </div>
                {/* second Blog headline */}
                <div className="grid md:grid-cols-2 p-4 m-4 ">
                  <div className="mx-auto mx-w-sm">
                    <h3 className="text-md font-bold px-2">
                      Celebrating Excellence: Mercy Girls High School Shines in
                      WAEC 2022/2023 Examinations
                    </h3>
                    <section
                      style={{ fontFamily: "sans-serif" }}
                      className="px-6 text-sm mt-2 text-justify text-zinc-500"
                    >
                      Mercy Girls High School, a beacon of academic excellence,
                      has once again demonstrated its commitment to nurturing
                      bright minds and fostering academic brilliance.
                    </section>
                  </div>
                  <div className="w-full">
                    <img
                      className=""
                      src="Images/waeclogo.jpeg"
                      alt="nnpc at mercy girls high school okigwe"
                    />
                    <a
                      href="result"
                      className="bg-zinc-200 relative mx-auto hover:text-mghso-10 p-4 text-center "
                    >
                      Continue Reading
                    </a>
                  </div>
                </div>
                {/* second Blog headline */}
                <div className="grid md:grid-cols-2 p-4 m-4 ">
                  <div className="mx-auto mx-w-sm">
                    <h3 className="text-md font-bold px-2">
                      Celebrating 60 Years of Excellence: Mercy Girls High
                      School's Inter House Sports Competition
                    </h3>
                    <section
                      style={{ fontFamily: "sans-serif" }}
                      className="px-6 text-sm mt-2 text-justify text-zinc-500"
                    >
                      Mercy Girls High School, a cornerstone of education and
                      excellence, recently celebrated its 60th anniversary with
                      great pomp and grandeur.
                    </section>
                  </div>
                  <div className="w-full">
                    <img
                      className=""
                      src="Images/houses.jpg"
                      alt="nnpc at mercy girls high school okigwe"
                    />
                    <a
                      href="result"
                      className="bg-zinc-200 relative mx-auto hover:text-mghso-10 p-4 text-center "
                    >
                      Continue Reading
                    </a>
                  </div>
                </div>
                {/* second Blog headline */}
                <div className="grid md:grid-cols-2 p-4  m-4">
                  <div className="mx-auto mx-w-sm">
                    <h3 className="text-md font-bold px-2 ">
                      Lumix Graphics Transforms Mercy Girls High School Okigwe's
                      Website and Facebook Page with
                    </h3>
                    <section
                      style={{ fontFamily: "sans-serif" }}
                      className="px-6 text-sm mt-2 text-justify text-zinc-500"
                    >
                      In a digital age where online presence is paramount, Mercy
                      Girls High School Okigwe has received a remarkable
                      makeover thanks to Lumix Graphics and its
                    </section>
                  </div>
                  <div className="w-full">
                    <img
                      className=""
                      src="Images/graphics_logo.jpg"
                      alt="prawa at mercy girls high school okigwe"
                    />
                    <a
                      href="Lumix"
                      className="bg-zinc-200 relative mx-auto hover:text-mghso-10 p-4 text-center "
                    >
                      Continue Reading
                    </a>
                  </div>
                </div>
                {/* 3rd Blog headline */}
                <div className="grid md:grid-cols-2 p-4 m-4">
                  <div className="mx-auto mx-w-sm">
                    <h3 className="text-md font-bold px-2 ">
                      NCC's Rural Broadband Initiative Empowers Mercy Girls High
                      School Okigwe with Cutting-Edge ICT Complex.
                    </h3>
                    <section
                      style={{ fontFamily: "sans-serif" }}
                      className="px-6 text-sm mt-2 text-justify text-zinc-500"
                    >
                      In a concerted effort to bridge the digital divide and
                      empower underserved communities,
                    </section>
                  </div>
                  <div className="w-full">
                    <img
                      className=""
                      src="Images/indesign.jpg"
                      alt="nnc at mercy girls high school okigwe"
                    />
                    <a
                      href="ncc"
                      className="bg-zinc-200 relative mx-auto hover:text-mghso-10 p-4 text-center "
                    >
                      Continue Reading
                    </a>
                  </div>
                </div>
                {/*4th Blog Headline*/}
                <div className="grid md:grid-cols-2 p-4  m-4">
                  <div className="mx-auto mx-w-sm">
                    <h3 className="text-md font-bold px-2 ">
                      NNPC LTD Enhances STEM Education at Mercy Girls High
                      School Okigwe with Generous Donation
                    </h3>
                    <section
                      style={{ fontFamily: "sans-serif" }}
                      className="px-6 text-sm mt-2 text-justify text-zinc-500"
                    >
                      In a commendable gesture towards promoting education and
                      empowering young minds in the field of STEM (Science,
                      Technology...
                    </section>
                  </div>
                  <div className="w-full">
                    <img
                      className=""
                      src="Images/nnpc_board.jpg"
                      alt="nnpc at mercy girls high school okigwe"
                    />
                    <a
                      href="Blog"
                      className="bg-zinc-200 relative mx-auto hover:text-mghso-10 p-4 text-center "
                    >
                      Continue Reading
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer3 />
      </div>
    </>
  );
};
export default Lbd;
