import React from "react";
import Nav1 from "../../Components/Quicklink";
import Navbar from "../../Components/navigation";
import NewsSection1 from "../../SubPages/News/Newssection1";
import Footer3 from "../../Components/footer3";
import FacebookNews from "../../SubPages/News/Newssection2";

const News = () => {
  return (
    <>
      <div className="max-w-[1460px] mx-auto  content-center">
        <Nav1 />
        <Navbar />
        <NewsSection1 />
        <FacebookNews />
        <Footer3 />
      </div>
    </>
  );
};
export default News;
