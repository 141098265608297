import React, { useEffect, useState } from "react";
import { AiFillClockCircle, AiOutlineArrowRight } from "react-icons/ai";
import moment from "moment";
function FacebookNews2() {
  const [post, setData] = useState({
    posts: { data: [{ attachments: { data: [] } }] },
  });
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      const getKey =
        "EAAHK6yd6uvMBAAWdV0ifEO1e1mM0eqY0IYqTlLd6FXPxdzOq2n3OoZCz05u2xdpHx3jC93ZAOZCgb5GxpG4hX3zczrZBQS9bKX1OAerv9OpcbCMgUUya3TZA4K82ofjGEmZAv6dxKU9knMHbrFIlipx2uXYAlfbLxUipExg2ZA6crt1NtCCvvKz";
      const response = await fetch(
        `https://graph.facebook.com/v13.0/me?fields=posts{attachments{media,url,media_type},message,created_time,permalink_url}&access_token=${getKey}`
      );
      const jsonData = await response.json();
      setData(jsonData);
      setLoading(false);
    };
    fetchData();
  }, []);
  function truncateMessage(message) {
    if (message && message.length > 80) {
      return message.slice(0, 80) + "...";
    } else if (message) {
      return message;
    } else {
      return "";
    }
  }
  function Hdmi(media, srcc) {
    if (media === "photo") {
      return (
        <div>
          {" "}
          <img
            src={srcc}
            alt="mercy girls high school okigwe"
            className="h-[6rem] px-2 w-[7rem] mx-2  round"
          />
          {/* <CardImg src="Images/news.jpg" alt="mercy girls" /> */}
        </div>
      );
    } else if (media === "video") {
      return (
        <video controls>
          <source src={srcc} type="video/mp4" />
        </video>
      );
    } else if (media === "album") {
      return (
        <img
          src={srcc}
          alt="mercy girls high school okigwe"
          className="h-[6rem] px-2 w-[7rem] mx-2  round"
        />
      );
    } else if (media === "link") {
      return (
        <div>
          <img
            src={srcc || "Images/news.jpg"}
            alt="mercy girls"
            className="h-[6rem] px-2 w-[7rem] mx-2  round"
          />
        </div>
      );
    } else {
      return (
        <img
          src={srcc || "Images/news.jpg"}
          alt="mercy girls"
          style={{
            height: "6rem",
          }}
          className="h-[6rem] px-2 w-[7rem] mx-2  round"
        />
      );
    }
  }
  function timeAgo(props) {
    const { timestamp } = props;
    const timeAgo = moment(timestamp).fromNow();
    const [dayAgo, hourAgo] = timeAgo.split("");
    return (
      <div>
        {dayAgo} {hourAgo} ago
      </div>
    );
  }
  const news = post.posts.data
    .slice(0, 3)

    .map((dataItem, index) => {
      const message = dataItem.message || "";
      const truncatedMessage = truncateMessage(message);
      /* console.log(dataItem.message);
      console.log(dataItem.attachments.data[0]?.media.image.src);
      console.log(dataItem.attachments.data[0]?.media.media_type); */
      let srcc = "";
      let hasError = 0;
      let media = dataItem.attachments?.data[0]?.media_type || "";
      try {
        const dataImage = dataItem.attachments?.data[0];
        // srcc = dataItem.attachments.data[0]?.media.image?.src || "";
        if (!dataImage.media.image || !dataImage.media.image.src) {
          srcc = "Images/news.jpg";
        } else {
          srcc = dataImage?.media.image.src;
        }
      } catch (error) {
        /*   console.error("Error:", error); */
        hasError = 1;
      }
      /* const srcc = dataItem.attachments.data[0]?.media.image?.src || "";
      const media = dataItem.attachments?.data[0]?.media_type || ""; */
      const HdmiCable = Hdmi(media, srcc);
      const timestamp = dataItem.created_time;
      const date = new Date(timestamp);
      const hoursAgo = Math.floor((new Date() - date) / (1000 * 60 * 60));
      return (
        <div className="grid grid-cols-3 p-2 " key={index}>
          <div className="col-span-1 ">{HdmiCable}</div>

          <div className="mx-2 col-span-2">
            <div>
              <p className="text-sm pt-2 px-2 text-zinc-500">
                {hoursAgo} {timeAgo}
                <i>Hours Ago</i>
              </p>
            </div>
            <div>
              <h3
                href={`https://www.facebook.com/334509856663559/posts/${dataItem.id}`}
                className="text-sm px-2 font-semibold font-sans text-black hover:text-slate-300 "
              >
                {truncatedMessage}
              </h3>
            </div>
          </div>
        </div>
      );
    });

  if (isLoading) {
    return (
      <div
        className="animate-pulse "
        style={{
          display: " flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <img
          src="Images/biglogo3.png"
          alt="mghso"
          className="loading-icon-image"
          style={{
            height: "100px",
            width: "100px",
            animation: " zoom 2s ease-in-out infinite2",
          }}
        />
        <br></br>
        <h4 className="Loading-icon-image fome">
          Loading Page Content......
          <svg className="animate-spin h-5 w-5 ml-3" viewBox="0 0 24 24"></svg>
        </h4>
      </div>
    );
  } else {
    return <div>{news}</div>;
  }
}
export default FacebookNews2;
