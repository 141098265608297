import React from "react";
import Nav1 from "../Components/Quicklink";
import Navbar from "../Components/navigation";
import ContactSection4 from "../SubPages/Contact/contactsection4";
import ContactSection3 from "../SubPages/Contact/contactsection3";
import ContactSection2 from "../SubPages/Contact/contactsection2";
import ContactSection1 from "../SubPages/Contact/contactsection1";
import Footer3 from "../Components/footer3";

const Contact = () => {
  return (
    <>
      <div className="max-w-[1460px] mx-auto  content-center">
        <Nav1 />
        <Navbar />
        <ContactSection1 />
        <ContactSection2 />
        <ContactSection3 />
        <ContactSection4 />
        <Footer3 />
      </div>
    </>
  );
};
export default Contact;
