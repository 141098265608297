import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import { CardImg, Col } from "react-bootstrap";

const Mghso60 = () => {
  return (
    <div>
      <Col
        className="img-fluid d-sm-none d-xs-block opacity-50 d-flex justify-content-center align-items-center"
        style={{
          backgroundImage: 'url("Images/60image.jpg")',
          width: "100%",
          padddingTop: "10px",
          marginTop: "5rem",
          backgroundPosition: "center",
        }}
      >
        <h1
          style={{
            color: "white",
            fontSize: "60px",
            paddingTop: "50px",
            paddingBottom: "50px",
            textShadow: "2px 2px 3px black",
          }}
        >
          .
        </h1>
      </Col>

      <Col
        className="img-fluid d-none d-md-block opacity-50 d-flex justify-content-center align-items-center"
        style={{
          backgroundImage: 'url("Images/60image.jpg")',
          width: "100%",
          //height: "30rem",
          backgroundPosition: "center",
        }}
      >
        <h1
          style={{
            color: "yellow",
            textAlign: "center",

            fontSize: "60px",
            paddingTop: "50px",
            paddingBottom: "50px",
            textShadow: "1px 1px 2px black",
          }}
        >
          .{" "}
        </h1>
      </Col>

      {/*       feed from programs and details
       */}
      <Row
        xs={1}
        md={2}
        style={{
          backgroundColor: "white",
        }}
        className="g-4"
      >
        {/* //{Array.from({ length: 4 }).map((_, idx) => ( */}
        <Col>
          <Card
            className="border-0"
            style={{
              backgroundColor: "transparent",
              padding: "7px",
            }}
          >
            <h4
              style={{
                backgroundColor: "transparent",
                color: "green",
              }}
            >
              <i> Join us in Commemorating Our School's Diamond Jubilee</i>
            </h4>{" "}
            <hr
              style={{
                justifyContent: "center",
                color: "#AC2D5A",
                marginBottom: "13px",
                width: "50%",
                margin: "0px",
                padding: "0px",
                background: "#AC2D5A",
                border: "3px solid #AC2D5A",
              }}
            ></hr>
            <hr
              style={{
                justifyContent: "center",
                color: "white",
                marginBottom: "13px",
                width: "50%",
                margin: "0px",
                padding: "0px",
                background: "white",
                border: "3px solid white",
              }}
            ></hr>
            <Card.Img
              className="shadow"
              variant="top"
              src="Images/anniverasyzoom.jpg"
              alt="mercy_girls_high_school_okigwe"
            />
            <Card.Body
              className="fome"
              style={{
                color: "black",
              }}
            >
              <h4>
                <strong>MERCY @60 CALENDER OF ACTIVITIES</strong>
              </h4>{" "}
              <hr
                style={{
                  justifyContent: "center",
                  color: "#AC2D5A",
                  marginBottom: "13px",
                  width: "50%",
                  margin: "0px",
                  padding: "0px",
                  background: "#AC2D5A",
                  border: "3px solid #AC2D5A",
                }}
              ></hr>
              <h3>A Grand Gala: Marking the Diamond Jubilee</h3>
              <p>
                To commemorate this momentous milestone, we have planned a
                series of events and activities to captivate, engage, and
                celebrate our entire school community. The festivities will
                include:
              </p>{" "}
              <strong>25th (Tues) July 2023:</strong>
              <p>
                {" "}
                Briefing on the formation of "SAY NO TO EXAM MALPRACTICE" Club.
              </p>
              <p> 11am - 1pm (a virtual meeting) </p>{" "}
              <strong>27th July :</strong>
              <p> Anniversary Planning Committee meeting</p>{" "}
              <p> (Virtual) Time: 9-10.30am</p>
              <p>
                Essay Writing Competition on{" "}
                <i> "SAY NO TO EXAM MALPRACTICE"</i>{" "}
              </p>
              <p>Venue: @MGHSO TIME: 11:30am - 1:30pm </p>{" "}
              <strong>26th August:</strong> <p>Planning Committee meeting </p>{" "}
              <p>(5pm - 6pm) virtual </p>
              <p> Meeting with Old Girl's</p>
              <p>(6:30pm - 8:30pm) virtual</p>{" "}
              <strong>2nd Week of September:</strong>
              <p>
                Formation of <i>"SAY NO TO EXAM MALPRACTICE CLUB"</i>{" "}
              </p>
              <p>Debate on Exam Malpractice</p> <strong>23rd Sept :</strong>{" "}
              <p>Planning Committee meeting</p>
              <p> ( 8am - 10:30am) virtual</p> <strong>14th October : </strong>
              <p>Planning Committee meeting with Old Girls </p>
              <p>(Virtual) 5pm - 6pm</p> <p> Meeting with Old Girls.</p>{" "}
              <p> 6.30pm - 8pm</p> <strong>24th November:</strong>
              <p>Meeting with the Media</p> <strong>25th November :</strong>{" "}
              <p>
                Grand finale <i>INTER-HOUSE SPORTS</i>
              </p>{" "}
              <p>Interface with the PTA</p>
              <p>Physical Meeting of Planning Committee </p>
              <p> Meeting with the Old Girls</p>{" "}
              <strong>2nd week of December :</strong>{" "}
              <p>Planning Committee meeting</p>
              <strong>2nd & 3rd Wk of January 2024:</strong>{" "}
              <p>Meeting of the Plann. Comm & the Old Girls 13th January </p>
              <p>
                & 20th January 5pm - 6pm (for the planning meeting) & 6pm -
                8.30pm (Old Girls Meeting.)
              </p>
              <strong>23rd Saturday February 2024:</strong>{" "}
              <p>MERCY AMBASSADORS ANNUAL LECTURE </p>
              <strong className="border-0 justify-content-center">
                24th Saturday February 2024:
                <h3 className="border-0 justify-content-center">
                  <i
                    style={{
                      color: "#AC2D5A",
                    }}
                  >
                    {" "}
                    "Grand Finale of the MERCY @60 JUBILEE CELEBRATION".
                  </i>
                </h3>
              </strong>{" "}
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card
            className="border-0"
            style={{
              backgroundColor: "transparent",
            }}
          >
            <Card.Body
              className="fome"
              style={{
                color: "black",
              }}
            >
              <h1>
                Celebrating 60 Years of Excellence: A Glorious Journey in
                Education
              </h1>
              <p>
                In 1963, an erudite scholar, a man with vision and mission, who
                later in life came to be known and call "The education Bishop",
                was the Most Rev. Dr. Anthony Gogo Nwedo CSSP, of the blessed
                memory embarked on a journey with a vision to be a bastion of
                knowledge, character, and innovation. From modest beginnings
                with just a handful of students and dedicated educators, we have
                evolved into an educational institution of repute, fostering a
                community of learners and leaders. Over the years, we have faced
                challenges, embraced change, and celebrated triumphs that have
                shaped us into who we are today - a beacon of knowledge and a
                symbol of excellence.
              </p>{" "}
              <Card.Img
                variant="top"
                src="Images/60thjpg.jpg"
                alt="mercy_girls_high_school_okigwe"
              />
              <h4>Honoring Our Alumni</h4>
              <p>
                Shaping the Future, Together The heart of Mercy Girls High
                School Okigwe lies in the accomplishments of our esteemed alumni
                who have made us proud with their contributions to society. From
                accomplished professionals, pioneering entrepreneurs, to
                esteemed artists and social change-makers, our alumni community
                continues to leave an indelible mark on the world. As we
                celebrate our 60th anniversary, we extend a warm invitation to
                all our alumni to come together and rekindle old memories,
                strengthen bonds, and inspire the next generation of students.{" "}
              </p>{" "}
              <Card.Img
                variant="top"
                src="Images/commitee.jpg"
                alt="mercy_girls_high_school_okigwe"
              />
              <h3>Celebrating Achievements</h3>
              <p>
                {" "}
                A Reflection of Excellence Throughout the years, our students
                and faculty have achieved remarkable feats in academics, sports,
                arts, and various other fields. We take immense pride in the
                numerous awards,s, accolades, and recognition earned by our
                school and its members. Our Diamond Jubilee celebrations will be
                a tribute to these outstanding accomplishments, a testament to
                our dedication to nurturing talent and fostering a well-rounded
                education.
              </p>
              <Card.Img
                variant="top"
                src="Images/uju.jpg"
                alt="mercy_girls_high_school_okigwe"
              />
            </Card.Body>
            <h4> Uju Agomoh PhD (Mercy Alumni)</h4>
            <p>
              <strong>Chairperson Apex Organizing Committee</strong>
            </p>
          </Card>
        </Col>
        {/*  ))} */}
      </Row>
    </div>
  );
};
export default Mghso60;
