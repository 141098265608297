import React, { useState, useEffect } from "react";

const SportsSection2 = () => {
  const slides = ["intersport1.jpg", "intersport2.jpg", "sports.jpg"];
  const [currentSlide, setCurrentSlide] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  return (
    <>
      <div className=" p-8 my-4 grid sm:grid-cols-2 ">
        <div className="text-justify text-sm px-8">
          {" "}
          <p className="py-2">
            Students experience a wide range of activities which promote a
            balance between competition and cooperation. The department is
            focused on providing our students with meaningful sporting
            experiences which will help them to live a healthy active lifestyle
          </p>
          <p className="py-2">
            {" "}
            Sport plays a vital role in the enrichment at Mercy Girls' High
            School Okigwe, every student is given the opportunity to develop
            skills and interests whilst improving their physical, social and
            mental health. The PE curriculum is supported by a range of
            enrichment activities and comprehensive online learning provisions
          </p>
          <img
            className="max-auto  object-cover object-center"
            src="Images/uefa.jpg"
            alt="Field"
          />
          <p className="py-2">
            Students experience a range of internal competitions girls including
            a sports day, football, basketball, volleyball, tennis, badminton,
            tennis and table tennis house competitions. We are also involved in
            a range of external competitions organised by Dioces and Deanary.
          </p>
          <p className="py-2">
            At Mercy Girls, we are blessed with excellent facilities: 1 football
            pitches, an Olympic size 400m running track, a grass hockey pitch, 2
            basketball courts, 2 tennis courts, 2 grass volleyball courts, a
            multi-purpose hall that has badminton, netball and basketball
            courts.
          </p>
          <img
            className="max-auto  object-cover object-center"
            src="Images/School_field.jpg"
            alt="Field"
          />
          <p className="py-2">
            Through encouraging participation the PE Department at Mercy Girls
            will develop character, a sense of fair play and a determination to
            excel.
          </p>
        </div>
        <div className="relative m-4">
          <h2 className="p-4 text-xl font-bold">Our Sports, Our Pride</h2>
          <div className="relative item-center  w-full">
            {slides.map((slide, index) => (
              <div
                key={index}
                className={`absolute inset-0 max-w-sm h-[17rem] transition-opacity ${
                  index === currentSlide ? "opacity-100" : "opacity-0"
                }`}
              >
                <img
                  className="max-auto mx-auto  object-cover object-center"
                  src={`Images/${slide}`}
                  alt={`Slide ${index + 1}`}
                />
              </div>
            ))}
            <div className="relative md:pt-[12rem] pt-[12rem]">
              <h3></h3>
            </div>
          </div>
          <img
            className="max-auto  object-cover object-center"
            src="Images/teamsports.jpg"
            alt="Field"
          />
        </div>
      </div>
    </>
  );
};
export default SportsSection2;
