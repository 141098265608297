import React from "react";
import Nav1 from "../../../../Components/Quicklink";
import Navbar from "../../../../Components/navigation";
import AlumniSection1 from "../../AlumniSection1";
import Footer3 from "../../../../Components/footer3";

const Ohiasection = () => {
  return (
    <>
      <div>
        <Nav1 />
        <Navbar />
        <AlumniSection1 />

        <div className="grid md:grid-cols-3 p-6 md:p-20 text-justify">
          <div className="max-w-sm mx-auto">
            <img
              className="  p-6 md:p-8 pb-2 max-w-sm object-center"
              src="Images/ohia.jpg"
              alt="Ohiasection"
            />
            <h4 className="text-md font-bold px-4 md:px-8"> Ohia Josephine</h4>
            <p className="text-sm px-4"> Loading...</p>
          </div>
          <div className="col-span-2">
            <p className="text-sm px-4"> </p>
            <p className="text-sm px-4"></p>
          </div>
        </div>
        <Footer3 />
      </div>
    </>
  );
};
export default Ohiasection;
