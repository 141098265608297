import React from "react";
const Section4 = () => {
  const data = [
    {
      src: "images/logo",
      Header: "Become A Donor",
      Paragraph:
        "Become a catalyst for change! join  us in making a lasting impact on education by becoming a value donor at Mercy Girls High School Okigwe. Your support will empower us to create opportunities, enhance learning experiences, and foster a brighter future for those in need",
    },

    {
      src: "images/logo",
      Header: "Our Alumni",
      Paragraph:
        "Welcome to the Mercy Girls' Alumni community a network of accomplished individuals who have left an indelible mark on the world. Our alumni are the pride of our school, representing a legacy of excellence, leadership, and positive impact",
    },

    {
      src: "images/logo",
      Header: "Mercy @60",
      Paragraph:
        "Celebrating 60 Glorious Years of Excellence! Join us at Mercy Girls as we commemorate six decades of educational brilliance and lasting impact. Our 60th anniversary is a testament to the resilience, archievements, and enduring spirit of our school community",
    },
    
  ];
  return (
    <div className=" md:flex justify-between ">
      {data.map((item, index) => (
        <div
          key={index}
          data-aos="fade-up"
          data-aos-anchor-placement="top-center"
          className=" text-white p-6 md:p-12 font-sans  w-full"
          style={{
            backgroundImage: "linear-gradient(to left, #9e142a, #e44b64",
            fontFamily: "calibri",
          }}
        >
          <div>
            <div className="text-lg font-semibold pb-2 code">{item.Header}</div>
            <div className="text-sm text-zinc-200">{item.Paragraph}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Section4;
