import React, { useState, useEffect } from "react";
const HeroSection = () => {
  const slides = ["School_compound.jpg", "stdntlife.jpg", "School_Gate.jpg"];
  const hero = [
    " Innovate, Educate, Elevate",
    "Shaping, Building Confidence",
    "Inspiring, Empowering Dreams",
  ];
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currenthero, setCurrentHero] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
      setCurrentHero((prevHero) => (prevHero + 1) % hero.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [currentSlide, currenthero]);
  return (
    <div className="relative  md:-mt-24 -z-10 overflow-hidden">
      {/* Slides */}
      {slides.map((slide, index) => (
        <div
          key={index}
          className={`absolute inset-0 w-full h-full transition-opacity ${
            index === currentSlide ? "opacity-100" : "opacity-0"
          }`}
        >
          <img
            className="w-full h-full object-cover object-center"
            src={`Images/${slide}`}
            alt={`Slide ${index + 1}`}
          />
          <div
            className="absolute inset-0  opacity-50"
            style={{
              backgroundImage: "linear-gradient(to top, #9e142a, black",
              fontFamily: "calibri",
            }}
          ></div>
        </div>
      ))}
      {/* Content */}
      <div className="relative py-40 z-10 text-white md:text-center">
        <div className="relative sm:pb-8 md:pb-2">
          {hero.map((heros, index) => (
            <div
              key={index}
              className={`absolute inset-0 w-full h-full transition-opacity ${
                index === currenthero ? "opacity-100" : "opacity-0"
              }`}
            >
              <h1
                style={{
                  fontFamily: "calibri",
                }}
                className="relative text-4xl font-extrabold pl-4 leading-tight sm:text-3xl md:text-3xl lg:text-4xl"
              >
                {" "}
                {heros}
              </h1>
            </div>
          ))}
        </div>
        <div className="mt-[23%] sm:mt-[10%] md:mt-2">
          <p
            style={{
              fontFamily: "calibri",
            }}
            className=" mt-4 text-lg pl-4 max-w-lg mx-auto sm:mt-6 sm:text-xl md:mt-8 lg:mt-10"
          >
            {" "}
            Step into the world of Mercy Girls' where innovation meets
            education. join us on a journey of academic excellence and personal
            growth, elevating each student to reach their fullest potential
          </p>
        </div>
        <div className="mt-6 pl-4 sm:mt-10">
          <a
            href="Academics"
            className="inline-block border-2 hover:border-gray-200 no-underline px-4 py-2 md:px-6 md:py-3 text-lg font-semibold bg-mghso-10 hover:bg-black  text-white "
          >
            Get Started
          </a>
        </div>
      </div>
    </div>
  );
};
export default HeroSection;
