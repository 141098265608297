import React from "react";
import {
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa";
const Footer3 = () => {
  const data = [
    {
      src: "HomePage",
      Name: "HamePage",
    },

    {
      src: "About",
      Name: "About",
    },

    {
      src: "Academics",
      Name: "Academics",
    },
    {
      src: "Alumni",
      Name: "Alumni",
    },
  ];
  return (
    <>
      <div className="bg-gray-900">
        <img
          src="Images/biglogo.png"
          alt="mercy girls logo"
          className="pt-6 h-[10rem] w-auto item-center mx-auto"
        />
        <h4 className="text-center text-md px-3 text-gray-400 font-mono ">
          MERCY GIRLS HIGH SCHOOL{" "}
        </h4>
        <div className="text-center text-base md:text-lg p-2 text-white">
          P.O Box 94, Okpara Road Okigwe, Imo State, Nigeria
          <p className="text-sm font-mono text-gray-400">
            mercygirlshighschoolokigwe@gmail.com
          </p>
        </div>
        <div className=" w-full h-auto text-white ">
          <div className="max-w-[8rem]  p-2 m-2 mx-auto grid grid-cols-5">
            <FaFacebook /> <FaInstagram />
            <FaTwitter />
            <FaWhatsapp />
            <FaYoutube />
          </div>
        </div>
        <div className="  px-auto items-center  mb-4   ">
          <div className="md:flex text-center max-w-md md:mx-[38%]">
            {data.map((item, index) => (
              <div
                key={index}
                className="text-white text-center  text-sm font-mono"
              >
                <a
                  href={item.src}
                  alt="Our Mercy Partners"
                  className="border-b-[1px]  text-gray-300  no-underline hover:text-mhgso-10 hover:shadow-md mx-4 border-gray-300"
                >
                  {item.Name}{" "}
                </a>
              </div>
            ))}
          </div>
        </div>
        <div className="text-gray-200 text-center text-[13px] md:text-md p-2 bg-mghso-10">
          Copyright All Right Reserved 2020, Mercy Girls High School Okigwe |
          Privacy Policy
        </div>
      </div>
    </>
  );
};
export default Footer3;
