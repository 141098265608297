import React from "react";
import Nav1 from "../../../../Components/Quicklink";
import Navbar from "../../../../Components/navigation";
import AlumniSection1 from "../../AlumniSection1";
import Footer3 from "../../../../Components/footer3";

const Ujusection = () => {
  return (
    <>
      <div>
        <Nav1 />
        <Navbar />
        <AlumniSection1 />

        <div className="grid md:grid-cols-3 p-6 md:p-20 text-justify">
          <div className="max-w-sm mx-auto">
            <img
              className="  p-6 md:p-8 pb-2 max-w-sm object-center"
              src="Images/uju.jpg"
              alt="Uju"
            />
            <h4 className="text-md font-bold px-4 md:px-8"> Dr. Uju Agomoh</h4>
            <p className="text-sm px-4">
              {" "}
              Dr. Uju Agomoh is the Executive Director Prisoners’ Rehabilitation
              And Welfare Action (PRAWA) – with a mission of promoting
              institutional reforms in formal and informal sector for access to
              justice, rehabilitation, and social development of prisoners,
              ex-prisoners, torture victims and youth at risks. She is a Member,
              United Nations Sub Committee on Prevention of Torture (SPT),
              elected 20th October 2022, her tenure is from 1st January 2023 to
              31st December 2026
            </p>
          </div>
          <div className="col-span-2">
            <p className="text-sm px-4">
              {" "}
              She is also the Executive Committee Member, African Security
              Sector Network (ASSN),Member- Independent Investigation Panel of
              Inquiry on Nigeria Police Force Squad(SARS) and other Specialised
              Units Alleged Cases of Human Rights Violation(Appointed in 2020),
              Co-Chair- Development Law Group (DLG) of the Nigeria Bar
              Association (NBA) Section on Public Interest and Development Law
              (SPIDEL) (July 2021 - July 2023), Member- World Organisation
              Against Torture (OMCT) Global SOS-Torture Covid 19 Crisis Action
              Group (September 2021-December 2022).
            </p>
            <p className="text-sm px-4">
              Some of her past posts/assignments include the following: Team
              Lead - Prisons/Corrections Component of the Nigeria Security and
              Justice Reform Programme of the Foreign and Commonwealth Office of
              the British Government (August 2017 – Feb 2018); Project
              Coordinator - United Nations Office on Drugs and Crimes (UNODC)
              Support to Justice Sector in Nigeria (a project funded by the
              European Union under the 10th European Union Development Fund
              (October 2016 – August 2017), Lead Consultant – for the design of
              the Rule of Law and Anti-Corruption (RoLAC) Project under the 11th
              European Union Development Fund (April – June 2016); Executive
              Committee Member (representing Sub Saharan Africa) - International
              Rehabilitation Council for Torture Victims (IRCT) (2012-2016);
            </p>
            <p className="text-sm px-4">
              {" "}
              Council Member and the Special Rapporteur on Police, Prisons and
              Centers of Detention – Nigerian National Human Rights Commission
              (2001 - 2008); Member - Presidential Committee on Prerogative of
              Mercy Committee of Nigeria (2006 - 2009), Member Presidential
              Committee on Prison Decongestion (1999 – 2001, 2013 - 2014). She
              was until July 2013 a Research Fellow at the Institute for
              Development Studies (IDS) University of Nigeria Nsukka (Enugu
              Campus). President - International Corrections and Prisons
              Association Africa - Chapter (ICPA-Africa) 2013 – 2018
            </p>
            <p className="text-sm px-4">
              Dr Uju Agomoh academic qualifications include the following: B.Sc
              Psychology (University of Jos, Nigeria), M.Sc Clinical Psychology
              (University of Lagos, Nigeria), M.Sc Sociology: Deviant Behavior
              (University of Ibadan, Nigeria), M.Phil Criminology (University of
              Cambridge, England), PhD Criminology/Prisons Studies (University
              of Ibadan, Nigeria), LLB Law (Queenmary & Westfield College,
              University of London, England), BL (Nigerian Law School). She is a
              Chevening Scholar, a Fellow of the Cambridge Commonwealth Trust,
              the 2014 Edwin Megargee Distinguish Lecturer, Nigerian Bar
              Association Criminal Justice Reformer 2018 Awardee, Vision Africa
              Face of Humanity 2019 Awardee, and the Vision Africa Personality
              Award 2019. Dr. Agomoh's work involves training, research
              advocacy, assessment, program design and implementation of over 72
              projects on security, justice, corrections and development related
              issues in many African countries including Nigeria, Kenya, Rwanda,
              Burundi, Zambia, Democratic Republic of Congo, Ghana, and the
              Gambia. She has personally carried out over 1000 prison
              /monitoring visits covering about 150 prisons in 32 countries. She
              has authored/co-authored to date thirty (30) books, four training
              manuals and directed the production of four (4) audio-visual
              documentaries on death penalty, treatment of mentally ill
              prisoners, torture, and prison conditions in Nigeria. She is
              married to Dr Ahamefule Agomoh (a consultant psychiatrist) and
              they are blessed with children
            </p>
          </div>
        </div>
        <Footer3 />
      </div>
    </>
  );
};
export default Ujusection;
