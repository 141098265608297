import React, { useState, useEffect } from "react";
import { FaFacebook } from "react-icons/fa";
const CommentAndApply = () => {
  const slides = [
    {
      src: "gift.jpg",
      Message:
        "Choosing this school  was the best decisions I've made as a child.  Rigorous academics, and emphasis on character development have helped my child thrive academically and personally ",
      Name: " Gift",
    },
    {
      src: "ijeoma.jpg",
      Message:
        "Reflecting on my time at this school brings back fond memories and a deep sense of gratitude. Engaging learning environment, and vibrant school community played a pivotal role in shaping who I am today.",
      Name: " Ijeoma ",
    },

    {
      src: "Ibuchi Faith.jpg",
      Message: ". I feel empowered to make a positive impact in the world.",
      Name: " Ibuchi Faith ",
    },
    {
      src: "Mercy Ebere.jpg",
      Message: "My amazing Alma mata",
      Name: " Mercy Ebere",
    },
    {
      src: "Ngozi Awambu.jpg",
      Message: "This is absolutely amazing, congratulation to us all.",
      Name: "Ngozi Awambu",
    },
    {
      src: "pricilia.png",
      Message:
        "'ll always cherish the friendships and experiences I gained here.",
      Name: " Pricilia ",
    },
    {
      src: "Onunkwo_Pascaline.jpg",
      Message:
        "Being a student at this school has been an incredible journey of growth and discovery. i like my school",
      Name: " Onunkwo Pascaline ",
    },
    {
      src: "pretty.jpg",
      Message:
        " The dedicated teachers, and wide range of extracurricular opportunities havehelped me realize my potential and pursue my passions.",
      Name: " Pretty ",
    },
    {
      src: "amaka.jpg",
      Message: " God bless this school",
      Name: " Amaka ",
    },
    {
      src: "Anita_Beka.jpg",
      Message:
        ". The supportive teachers, engaging learning environment, and vibrant school community played a pivotal role in shaping who I am today.",
      Name: " Anita Beka ",
    },
    {
      src: "Chiamaka.png",
      Message:
        "The supportive staff, collaborative culture, and focus on student-centered learning make every day fulfilling and rewarding. I'm grateful for the opportunity to inspire and empower the next generation of leaders.",
      Name: " Chiamaka ",
    },
    {
      src: "Immaculate.jpg",
      Message:
        "we lead others follow",
      Name: " Immaculate ",
    },
    {
      src: "ObiageriMGHSO.jpg",
      Message:
        "Padre, our God is good ooo, God of surprises, the God of mercy, I;m so happy for our girls oo, thank you Jesus",
      Name: " Obiegeri ",
    },
    {
      src: "stellaNwadike.jpg",
      Message:
        "You guys are always been the best. More wins for you in all remifications in jesus name amen..",
      Name: " Stella",
    },
    {
      src: "BenitaOpara.jpg",
      Message:
        "School with doings. Always the best.",
      Name: " benita ",
    },
    {
      src: "DeBorah.jpg",
      Message:
        "We keep moving forward.",
      Name: " De Borah ",
    },
    {
      src: "PromiseNeakaego.jpg",
      Message:
        "Great Alaokwa girls, adighi ama aka.",
      Name: " Promise Neakaego ",
    }
  ];
  const [currentSlide, setCurrentSlide] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 7000);
    return () => clearInterval(interval);
  }, [currentSlide]);

  return (
    <>
      <div className="grid md:grid-cols-3" style={{ fontFamily: "calibri" }}>
        <div className="md:col-span-2 relative md:h-[7rem] px-20 bg-zinc-100  h-[24rem] bg-white shadow-lg w-[100%] md:h-[8rem]">
          <div className="max-w-[100%] relative">
            <div className="p-4 max-w-[100%] ">
              {/* Slides */}
              {slides.map((slide, index) => (
                <div
                  key={index}
                  className={` absolute transition-opacity ${
                    index === currentSlide ? "opacity-100" : "opacity-0"
                  }`}
                >
                  <div className="py-4 md:py-2 md:px-2 px- w-[100%] mx-auto  space-y-2 sm:py-4 sm:flex sm:items-center  sm:space-y-0 sm:space-x-6">
                    <img
                      className=" mx-auto h-20 w-20 border-2 border-mghso-10 rounded-full  sm:mx-0 sm:shrink-0"
                      src={`Images/${slide.src}`}
                      alt={`Slide ${index + 1}`}
                    />
                    <div className="text-center space-y-2 md:space-y-0 sm:text-left">
                      <div className="space-y-0.5 md:space-y-0">
                        <FaFacebook className="max-w-full mx-auto md:mx-2  text-center" />
                        <p className="text-lg text-black font-semibold">
                          {slide.Name}
                        </p>
                        <p className="text-slate-500 font-medium md:text-sm">
                          {slide.Message}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {/* Content */}
            </div>
          </div>
        </div>
        <div style={{ fontFamily: "anton" }} className="bg-amber-300  w-[100%]">
          <div className="   py-10 font-bold text-center ">
            <a
              href="Academics"
              className="w-full   font-bold text-center text-gray-100 hover:text-black"
            >
              Apply to Mercy Girls'
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommentAndApply;
