import React from "react";
import {
  FaCalendar,
  FaEnvelope,
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";

const AcSection1 = () => {
  return (
    <div className="relative  z-10 overflow-hidden  md:mb-10">
      <div className=" grid gap-4 md:grid-cols-2 lg:grid-cols-3 md:mx-[20]  mx-8 text-mghso-10 pb-4 md:pb-20 pt-4 md:pt-12">
        <div className="item-center w-full pt-[1px] md:pt-12">
          <img
            src="Images/frwcpix.jpg"
            alt="mercy girls principal"
            className=" item-center h-[15rem] w-[14rem]  md:h-72 md:w-60 border-2 border-mghso-10  p-4 mx-auto md:mx-auto"
          />
          <h3 className="text-black pt-4 text-center">Rev. Fr. Remigius Ogu</h3>
          <p className="text-gray-600 text-center">Principal </p>
          <p className="flex text-center px-[35%]">
            <a href="https://facebook.com/Remy Ogu">
              <FaFacebook />{" "}
            </a>
            &nbsp;&nbsp;
            <a href="https://principal@mghso.com.ng/">
              {" "}
              <FaEnvelope />
            </a>
            &nbsp; &nbsp;
            <a href="https://youtube.com/frRemyOgu">
              <FaYoutube />
              &nbsp; &nbsp; &nbsp;
            </a>
            <a href="https://Instagram.com/Remy_ogu">
              <FaInstagram />
            </a>
            &nbsp;
            <a href="https://twitter.com/fr_ogu">
              <FaTwitter />
            </a>
            &nbsp;
          </p>
          <i className=" px-[18%] w-full lg:px-[25%] w-[100%] text-center italic font-semibold text-md md:text-xl">
            For more information
          </i>
          <p className="text-base w-[100%] text-center text-gray-600">
            mercygirlshighschool@gmail.com
          </p>
        </div>
        <div className="lg:col-span-2 md:col-span-1">
          <h3 className="text-center w-[100%]  font-bold text-xl p-2">
            Why Choose Us
          </h3>
          <h4 className="text-[15px] text-justify">
            <strong>Our Curriculum</strong> is designed to ensure that student
            fit in easily into any educational and work environment . Our team
            of qualified and experience teachers discharge their duties with a
            sense of determination to develop each student's individual
            capability paying special attention to her needs. Mercy Girls' High
            School Okigwe is dedicated to the general upliftment of humanity
            through quality education, dedicated service, total commitment and
            giving girls the right focus in life.
          </h4>

          <div className="relative mt-4 object-cover">
            <img
              src="Images/stdntonflag.jpg"
              alt="mercy girls principal"
              className="inline-block absolute h-full w-full object-cover"
            />

            <div className="absolute inset-0 bg-mghso-10 opacity-95"></div>
            <div className="relative z-10 text-white ">
              <div className="md:text-base items-center text-sm md:px-[10%] md:py-[4%] p-6 ">
                <div className="flex ">
                  <h3 className="md:text-lg font-semibold sm:text-base">
                    {" "}
                    How To Register :{" "}
                  </h3>
                  <div className="bg-gray-300 h-[2px] mx-2 mt-6 w-auto md:w-[400px]"></div>
                </div>

                <div className="grid gap-2 lg:grid-cols-3 ">
                  <div className="bg-white border-[3px] w-[95%] border-gray-200 p-[2px] w-full m-4 shadow-lg">
                    <p className="bg-mghso-10 mr-[80%] mt-[2px] mx-auto text-center text-gray-200 mx-auto">
                      1
                    </p>
                    <div className="p-4">
                      <img
                        src="Images/mghsPF.png"
                        alt="mercy girls high school okigwe"
                        className="px-[30%] pb-2"
                      />
                      <h3 className="text-mghso-10 w-full text-center font-semibold">
                        Get Form
                      </h3>
                      <p
                        className="text-center text-sm w-full font-mono"
                        style={{
                          color: "black",
                        }}
                      >
                        Purchase the entrance examination form
                      </p>
                    </div>
                  </div>
                  <div className="bg-white border-[3px] w-[95%] border-gray-200 p-[2px] w-full m-4 shadow-lg">
                    <p className="bg-mghso-10 mr-[80%] mt-[2px] mx-auto text-center text-gray-200 mx-auto">
                      2
                    </p>
                    <div className="p-4">
                      <img
                        src="Images/notify.png"
                        alt="mercy girls high school okigwe"
                        className="px-[30%] pb-2"
                      />
                      <h3 className="text-mghso-10 w-full text-center font-semibold">
                        Notification
                      </h3>
                      <p
                        className="text-center text-sm text text-center w-full font-mono"
                        style={{
                          color: "black",
                        }}
                      >
                        Upon completion of the registration,exam date, venue,
                        will be cummunicated through SMS or Email
                      </p>
                    </div>
                  </div>
                  <div className="bg-white border-[3px] w-[95%] border-gray-200 p-[2px] w-full m-4 shadow-lg">
                    <p className="bg-mghso-10 mr-[80%] mt-[2px] mx-auto text-center text-gray-200 mx-auto">
                      3
                    </p>
                    <div className="p-4">
                      <img
                        src="Images/mghsSR.png"
                        alt="mercy girls high school okigwe"
                        className="px-[30%] pb-2"
                      />
                      <h3 className="text-mghso-10 w-full text-center font-semibold">
                        Admitted
                      </h3>
                      <p
                        className="text-center text-sm w-full font-mono"
                        style={{
                          color: "black",
                        }}
                      >
                        Successful candidates after meeting up the school
                        requirments will be admitted.
                      </p>
                    </div>
                  </div>
                </div>
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AcSection1;
