import React from "react";

import { FaChalkboardTeacher } from "react-icons/fa";
import { IoSchoolSharp } from "react-icons/io5";
import { GiPrayerBeads } from "react-icons/gi";
import { BiTrophy } from "react-icons/bi";
const MoreRection = () => {
  const data = [
    {
      Header: "Education Services",
      Paragraph:
        "Discover a nurturing environment for young minds at Mercy Girls', a premier girl's secondary school. Our Educational services are tailored to cultivate the budding potential within each student. We empower gils to flourish intellectually and socially. Our Educators provide a holistic learning experience, fostering cruisity, creativity, and resilience.",
      src: <FaChalkboardTeacher />,
      link: "Education",
    },

    {
      src: <IoSchoolSharp />,
      Header: "School Life",
      Paragraph:
        "Embark on an unforgettable jouney of growth and discovery at Mercy Girls'. Our school life is a vibrant tapestry of experiences, friendships, and academic excellence. From engaging classess to exiting extracurricolular activities, we create an environment where students thriev socially and academically. At Mercy Girls, we believe in nurturing not just the mind but the heart.",
      link: "schoolLife",
    },

    {
      src: <GiPrayerBeads />,
      Header: "Spirituality",
      Paragraph:
        "Immerse your spirit in a nurturing and inclusive environment at mercy Gils'. Out commitment to spirituality goes han-in-hand with academic exellence. Our School is a haven where student can explore and deepen their spiritual journey. Rooted in values, we encourage mindfulness, compassion, and self reflection. Our inclusive approach embraces diversity and promotes a sense of unity.",
      link: "spirituality",
    },

    {
      src: <BiTrophy />,
      Header: "Why Chosose Us",
      Paragraph:
        "As each student prepare for life after Mercy Girls High School, the educational foundation they carry forwared with them ensure the can transit easily into institutions in Nigeria and beyound. Choosing Mercy Gils' is choosing exellence in education and empowerment. We stands out for its unwavering commitment to fostering holistic development.",
      link: "Academics",
    },
  ];
  return (
    <div className="relative block md:flex justify-between bg-mghso-10">
      {data.map((item, index) => (
        <div
          key={index}
          data-aos="fade-up"
          data-aos-anchor-placement="center-bottom"
          className=" text-white p-4 w-full"
        >
          <div>
            <div
              className="text-amber-300  text-[30px]"
              style={{ fontFamily: "carthogothic" }}
            >
              {item.src}
            </div>
            <div className="text-lg font-semibold code pb-2">{item.Header}</div>
            <div
              className="text-sm text-zinc-300"
              style={{ fontFamily: "calibri" }}
            >
              {item.Paragraph}
            </div>
          </div>
          <div>
            <a
              href={`${item.link}`}
              style={{ fontFamily: "browallia" }}
              className="text-amber-300 text-[11px]"
            >
              Learn more
            </a>{" "}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MoreRection;
