import React, { useState, useEffect } from "react";
import Nav1 from "../../Components/Quicklink";
import Navbar from "../../Components/navigation";
import Footer3 from "../../Components/footer3";
const Gallerysection = () => {
  const data = [
    {
      src: "stdntonflag.jpg",
    },

    {
      src: "preaching.jpg",
    },
    {
      src: "picinchurch.jpg",
    },
    {
      src: "stdntfree.jpg",
    },
    {
      src: "teacherssr.jpg",
    },
    {
      src: "mghso_pic1.jpg",
    },
    {
      src: "masscon.jpg",
    },
    {
      src: "library.jpg",
    },
    {
      src: "sdntTeach.jpg",
    },
    {
      src: "ES1.jpg",
    },
    {
      src: "ES2.jpg",
    },
    {
      src: "ES3.jpg",
    },
    {
      src: "intersport1.jpg",
    },
    {
      src: "intersport2.jpg",
    },
    {
      src: "band.jpg",
    },
    {
      src: "ict1.jpg",
    },
    {
      src: "ict3.jpg",
    },
    {
      src: "fmr.jpg",
    },
    {
      src: "slide1.jpg",
    },
    {
      src: "student3.jpg",
    },
    {
      src: "stdntincl.jpg",
    },
    {
      src: "student1.jpg",
    },
    {
      src: "student2.jpg",
    },

    {
      src: "stdntinmas.jpg",
    },
    {
      src: "stdnt_As.jpg",
    },
    {
      src: "stdntlife.jpg",
    },
    {
      src: "stdntinfld.jpg",
    },
    {
      src: "commitee.jpg",
    },
    {
      src: "Student_in_farm.jpg",
    },
    {
      src: "student3.jpg",
    },
    {
      src: "slide3.jpg",
    },
    {
      src: "slide2.jpg",
    },
    {
      src: "assembly.jpg",
    },
  ];

  const slides = ["slide2.jpg", "School_Road.jpg", "School_compound.jpg"];
  const [currentSlide, setCurrentSlide] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  return (
    <>
      <div className="max-w-[1460px] mx-auto content-center">
        <Nav1 />
        <Navbar />
        <div className="relative md:-mt-24 -z-10 overflow-hidden">
          {/* Slides */}
          {slides.map((slide, index) => (
            <div
              key={index}
              className={`absolute inset-0 w-full h-full  transition-opacity ${
                index === currentSlide ? "opacity-100" : "opacity-0"
              }`}
            >
              <img
                className="w-full h-full object-cover object-center"
                src={`Images/${slide}`}
                alt={`Slide ${index + 1}`}
              />
              <div
                className="absolute inset-0  opacity-50"
                style={{
                  backgroundImage: "linear-gradient(to top, #9e142a, black",
                  fontFamily: "calibri",
                }}
              ></div>
            </div>
          ))}
          {/* Content */}
          <div className="relative py-40 z-10 text-white md:text-center">
            <p className="mt-4 text-lg pl-4 sm:mt-4 md:mt-4 lg:mt-10 sm:text-xl ">
              {" "}
              Browse Through Our Gallery
            </p>
            <h1 className="text-4xl font-extrabold pl-4 leading-tight  sm:text-5xl md:text-6xl lg:text-7xl">
              {" "}
              Gallery
            </h1>
          </div>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 p-6 md:p-4 w-full">
          {data.map((item, index) => (
            <div key={index} className=" text-white p-3 md:p-4  w-full">
              <div className="">
                <img
                  className="space-x-4  px-2 pb-2 object-center"
                  src={`Images/${item.src}`}
                  alt="Uju"
                />
              </div>
            </div>
          ))}
        </div>
        <Footer3 />
      </div>
    </>
  );
};
export default Gallerysection;
