import React, { useState, useEffect } from "react";
const SchSection1 = () => {
  const slides = ["slide2.jpg", "slide1.jpg", "slide3.jpg"];
  const [currentSlide, setCurrentSlide] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  return (
    <div className="relative mt-8 md:-mt-24 -z-10 overflow-hidden">
      {/* Slides */}
      {slides.map((slide, index) => (
        <div
          key={index}
          className={`absolute inset-0 w-full h-full transition-opacity ${
            index === currentSlide ? "opacity-100" : "opacity-0"
          }`}
        >
          <img
            className="w-full h-full object-cover object-center"
            src={`Images/${slide}`}
            alt={`Slide ${index + 1}`}
          />
          <div className="absolute inset-0 bg-mghso-10 opacity-50"></div>
        </div>
      ))}
      {/* Content */}
      <div className="relative py-40 z-10 text-white md:text-center">
        <h1 className="text-4xl font-extrabold pl-4 leading-tight sm:text-5xl md:text-6xl lg:text-7xl">
          {" "}
          School Life
        </h1>
        <p className="mt-4 bg-gray-200 border-l-4 max-w-auto text-mghso-10 mx-auto text-cemter border-amber-300 text-lg p-4 sm:mt-4 sm:text-xl md:mt-6 lg:mt-10">
          {" "}
          Life at Mercy Girls{" "}
        </p>
      </div>
    </div>
  );
};
export default SchSection1;
