import React from "react";
import {
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa";
const Nav1 = () => {
  return (
    <div className="hidden md:block">
      <div className="color10  justify-between  p-2 flex items-center ">
        <div className="text-white sm-12  sm:pl-6 ">
          <div className="max-w-md space-x-2  mx-auto grid grid-cols-5">
            <a href="https://facebook.com/Remy Ogu">
              <FaFacebook />
            </a>{" "}
            <a href="https://Instagram.com/Remy_ogu">
              {" "}
              <FaInstagram />
            </a>
            <a href="https://twitter.com/fr_ogu">
              {" "}
              <FaTwitter />
            </a>
            <a href="https://web.whatsapp.com/">
              {" "}
              <FaWhatsapp />
            </a>
            <a href="https://youtube.com/frRemyOgu">
              {" "}
              <FaYoutube />
            </a>
          </div>
        </div>{" "}
        <div className="text-white sm:pl-6  ">
          <p className="text-[11px]">+2348068491189 / info@mghso.com.ng</p>
        </div>{" "}
        <div className="text-white sm:pl-6 pr-12 ">
          <p className="text-[11px]">
            Mercy Girls' @ P.O Box 94, Okpara Road Okigwe
          </p>
        </div>
      </div>
    </div>
  );
};
export default Nav1;
