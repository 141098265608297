import React from "react";
import Nav1 from "../Components/Quicklink";
import Navbar from "../Components/navigation";
import SportsSection1 from "../SubPages/Sport/sportssection1";
import SportsSection2 from "../SubPages/Sport/sportssection2";
import Footer3 from "../Components/footer3";

const Sport = () => {
  return (
    <>
      <div className="max-w-[1460px] mx-auto content-center">
        <Nav1 />
        <Navbar />
        <SportsSection1 />
        <SportsSection2 />
        <Footer3 />
      </div>
    </>
  );
};
export default Sport;
