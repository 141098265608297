import React, { useState, useEffect } from "react";
import Nav1 from "../../Components/Quicklink";
import Navbar from "../../Components/navigation";
import Footer3 from "../../Components/footer3";
const Toursection = () => {
  const data = [
    {
      src: "images/logo",
    },

    {
      src: "<FaEnvelope />",
    },

    {
      src: "images/logo",
    },
  ];
  const slides = ["slide2.jpg", "slide1.jpg", "slide3.jpg"];
  const [currentSlide, setCurrentSlide] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  return (
    <>
      <div>
        <Nav1 />
        <Navbar />
        <div className="relative mt-8 md:-mt-24 -z-10 overflow-hidden">
          {/* Slides */}
          {slides.map((slide, index) => (
            <div
              key={index}
              className={`absolute inset-0 w-full h-full transition-opacity ${
                index === currentSlide ? "opacity-100" : "opacity-0"
              }`}
            >
              <img
                className="w-full h-full object-cover object-center"
                src={`Images/${slide}`}
                alt={`Slide ${index + 1}`}
              />
              <div className="absolute inset-0 bg-mghso-10 opacity-50"></div>
            </div>
          ))}
          {/* Content */}
          <div className="relative py-40 z-10 text-white md:text-center">
            <p className="mt-4 text-lg pl-4 sm:mt-4 md:mt-4 lg:mt-10 sm:text-xl ">
              {" "}
              Go For Mercy Team!
            </p>
            <h1 className="text-4xl font-extrabold pl-4 leading-tight  sm:text-5xl md:text-6xl lg:text-7xl">
              {" "}
              Take A Tour
            </h1>
          </div>
        </div>

        <div className="grid md:grid-cols-4 p-6 md:p-20 text-justify bg-gray-300">
          {data.map((item, index) => (
            <div
              key={index}
              className=" text-white p-6 md:p-12 font-sans  w-full"
            >
              <div>
                <img
                  className="  p-6 md:p-8 pb-2 max-w-sm object-center"
                  src={`Images/${item.src}`}
                  alt="Take A Tour At Mercy Girls High School"
                />
              </div>
            </div>
          ))}
        </div>
        <Footer3 />
      </div>
    </>
  );
};
export default Toursection;
