import React from "react";
import Nav1 from "../../Components/Quicklink";
import Navbar from "../../Components/navigation";
import AlumniSection1 from "../../SubPages/Alumni/AlumniSection1";
import AlumniSection2 from "../../SubPages/Alumni/AlumniSection2";
import AlumniSection3 from "../../SubPages/Alumni/AlumniSection3";
import AlumniSection4 from "../../SubPages/Alumni/AlumniSection4";
import Footer3 from "../../Components/footer3";

const Alumni = () => {
  return (
    <>
      <div className="max-w-[1460px] mx-auto  content-center">
        <Nav1 />
        <Navbar />
        <AlumniSection1 />
        <AlumniSection2 />
        <AlumniSection3 />
        <AlumniSection4 />
        <Footer3 />
      </div>
    </>
  );
};
export default Alumni;
