import React from "react";
import Nav1 from "../../Components/Quicklink";
import Navbar from "../../Components/navigation";
import Footer3 from "../../Components/footer3";
import BlogSection1 from "../../SubPages/Blog/Blogsection1";

const Lumix = () => {
  return (
    <>
      <div className="max-w-[1460px] mx-auto content-center">
        <Nav1 />
        <Navbar />
        <BlogSection1 />
        <div>
          <div className="grid md:grid-cols-2 md:mx-auto  p-2">
            <div
              className="text-justify p-4 bg-gray-100 col-span-1 "
              style={{ fontFamily: "calibri" }}
            >
              <h3 className="font-bold text-xl">
                Elevating Online Presence: Lumix Graphics Transforms Mercy Girls
                High School Okigwe's Website and Facebook Page with Stunning
                Designs
              </h3>
              <p>
                In a digital age where online presence is paramount, Mercy Girls
                High School Okigwe has received a remarkable makeover thanks to
                Lumix Graphics and its visionary founder and designer, Nwoke
                Ikechukwu Stephen. Leveraging his expertise as a computer
                scientist and ICT teacher at the school, Stephen has
                collaborated with Lumix Graphics to provide a stunning graphics
                design for the school's website and Facebook page, enhancing its
                visibility and engagement in the virtual realm.
              </p>
              <h4 className="font-semibold text-lg">
                {" "}
                Unleashing Creativity and Expertise:{" "}
              </h4>
              <p>
                As a computer scientist and ICT teacher at Mercy Girls High
                School Okigwe, Nwoke Ikechukwu Stephen understands the
                importance of a captivating online presence.
              </p>
              <img
                className=" "
                src="Images/graphics_logo.jpg"
                alt="Lumix at mercy girls high school okigwe"
              />
              <p>
                He unleashes his creative prowess and technical expertise to
                revamp the school's digital platforms. With a keen eye for
                design and a deep understanding of technology, Stephen ensures
                that the graphics are not only visually appealing but also
                functional and user-friendly.
              </p>
              <h4 className="font-semibold text-lg">
                Breathing Life into Digital Spaces:
              </h4>{" "}
              <p>
                {" "}
                The transformation of Mercy Girls High School Okigwe's website
                and Facebook page is nothing short of spectacular. Lumix
                Graphics infuses vibrant colors, engaging visuals, and intuitive
                navigation to breathe new life into these digital spaces. From
                dynamic banners and eye-catching graphics to seamless
                integration of multimedia content, the redesigned platforms
                captivate visitors and provide an immersive experience that
                reflects the school's ethos and values.
              </p>
              <h4 className="font-semibold text-lg">
                Enhancing Communication and Engagement:
              </h4>
              <p>
                By providing a stunning graphics design for the school's website
                and Facebook page, Lumix Graphics facilitate enhanced
                communication and engagement with stakeholders. Whether it's
                prospective students exploring the school's offerings, current
                students accessing important information, or alumni reconnecting
                with their alma mater, the revamped platforms serve as a hub for
                interaction, collaboration, and community building.
              </p>
              <h4 className="font-semibold text-lg">
                Fostering Pride and Identity:
              </h4>
              <p>
                The new graphics design not only enhances Mercy Girls High
                School Okigwe's online presence but also fosters a sense of
                pride and identity within the school community. With visually
                striking branding elements and personalized touches, the
                platforms reflect the unique spirit and character of the school,
                instilling a sense of belonging and unity among students, staff,
                parents, and alumni.
              </p>
              <p>
                As Mercy Girls High School Okigwe unveils its revamped website
                and Facebook page, courtesy of Lumix Graphics. It enters a new
                era of digital prominence and connectivity. With stunning
                graphics and intuitive design, the platforms serve as dynamic
                showcases of the school's excellence and innovation.
              </p>
              <p>
                As Mercy Girls High School Okigwe unveils its revamped website
                and Facebook page, courtesy of Lumix Graphics. It enters a new
                era of digital prominence and connectivity. With stunning
                graphics and intuitive design, the platforms serve as dynamic
                showcases of the school's excellence and innovation.
              </p>
            </div>
            <div
              style={{ fontFamily: "calibri" }}
              className=" p-4 bg-gray-100 "
            >
              <h2 className="font-bold text-xl text-zinc-400 mt-4">
                Recent Blogs
              </h2>
              <div className="p-2">
                <div className="grid md:grid-cols-2 p-4 m-4 ">
                  <div className="mx-auto mx-w-sm">
                    <h3 className="text-md font-bold px-2">
                      Living by Design Nation (LBD) Launches "Say No to
                      Examination Malpractice" Campaign at Mercy Girls High
                      School okigwe
                    </h3>
                    <section
                      style={{ fontFamily: "sans-serif" }}
                      className="px-6 text-sm mt-2 text-justify text-zinc-500"
                    >
                      In a world where academic integrity is paramount, Living
                      by Design Nation (LBD) has taken...
                    </section>
                  </div>
                  <div className="w-full">
                    <img
                      className=""
                      src="Images/living_logo.jpg"
                      alt="nnpc at mercy girls high school okigwe"
                    />
                    <a
                      href="lbd"
                      className="bg-zinc-200 relative mx-auto hover:text-mghso-10 p-4 text-center "
                    >
                      Continue Reading
                    </a>
                  </div>
                </div>
                {/* second Blog headline */}
                <div className="grid md:grid-cols-2 p-4 m-4 ">
                  <div className="mx-auto mx-w-sm">
                    <h3 className="text-md font-bold px-2">
                      Celebrating Excellence: Mercy Girls High School Shines in
                      WAEC 2022/2023 Examinations
                    </h3>
                    <section
                      style={{ fontFamily: "sans-serif" }}
                      className="px-6 text-sm mt-2 text-justify text-zinc-500"
                    >
                      Mercy Girls High School, a beacon of academic excellence,
                      has once again demonstrated its commitment to nurturing
                      bright minds and fostering academic brilliance.
                    </section>
                  </div>
                  <div className="w-full">
                    <img
                      className=""
                      src="Images/waeclogo.jpeg"
                      alt="nnpc at mercy girls high school okigwe"
                    />
                    <a
                      href="result"
                      className="bg-zinc-200 relative mx-auto hover:text-mghso-10 p-4 text-center "
                    >
                      Continue Reading
                    </a>
                  </div>
                </div>
                <div className="grid md:grid-cols-2 p-4 m-4 ">
                  <div className="mx-auto mx-w-sm">
                    <h3 className="text-md font-bold px-2">
                      Celebrating 60 Years of Excellence: Mercy Girls High
                      School's Inter House Sports Competition
                    </h3>
                    <section
                      style={{ fontFamily: "sans-serif" }}
                      className="px-6 text-sm mt-2 text-justify text-zinc-500"
                    >
                      Mercy Girls High School, a cornerstone of education and
                      excellence, recently celebrated its 60th anniversary with
                      great pomp and grandeur.
                    </section>
                  </div>
                  <div className="w-full">
                    <img
                      className=""
                      src="Images/houses.jpg"
                      alt="nnpc at mercy girls high school okigwe"
                    />
                    <a
                      href="result"
                      className="bg-zinc-200 relative mx-auto hover:text-mghso-10 p-4 text-center "
                    >
                      Continue Reading
                    </a>
                  </div>
                </div>
                {/*                 4th Section
                 */}{" "}
                <div className="grid md:grid-cols-2 p-4 m-4">
                  <div className="mx-auto mx-w-sm">
                    <h3 className="text-md font-bold px-2 ">
                      NCC's Rural Broadband Initiative Empowers Mercy Girls High
                      School Okigwe with Cutting-Edge ICT Complex.
                    </h3>
                    <section
                      style={{ fontFamily: "sans-serif" }}
                      className="px-6 text-sm mt-2 text-justify text-zinc-500"
                    >
                      In a concerted effort to bridge the digital divide and
                      empower underserved communities,
                    </section>
                  </div>
                  <div className="w-full">
                    <img
                      className=""
                      src="Images/indesign.jpg"
                      alt="nnpc at mercy girls high school okigwe"
                    />
                    <a
                      href="lbd"
                      className="bg-zinc-200 relative mx-auto hover:text-mghso-10 p-4 text-center "
                    >
                      Continue Reading
                    </a>
                  </div>
                </div>
                {/* 3rd Blog headline */}
                <div className="grid md:grid-cols-2  p-4 m-4">
                  <div className="mx-auto mx-w-sm">
                    <h3 className="text-md font-bold px-2 ">
                      Prawa NGO Champions Girl Child Education with Support from
                      Mercy Girls High School Okigwe Alumni
                    </h3>
                    <section
                      style={{ fontFamily: "sans-serif" }}
                      className="px-6 text-sm mt-2 text-justify text-zinc-500"
                    >
                      In a world where education remains a powerful tool for
                      empowerment and social change, Prawa NGO stands at the
                      forefront of...
                    </section>
                  </div>
                  <div className="w-full">
                    <img
                      className=""
                      src="Images/prawaflyer.jpg"
                      alt="prawa at mercy girls high school okigwe"
                    />
                    <a
                      href="prawa"
                      className="bg-zinc-200 relative mx-auto hover:text-mghso-10 p-4 text-center "
                    >
                      Continue Reading
                    </a>
                  </div>
                </div>
                {/*4th Blog Headline*/}
                <div className="grid md:grid-cols-2 p-4  m-4">
                  <div className="mx-auto mx-w-sm">
                    <h3 className="text-md font-bold px-2 ">
                      NNPC LTD Enhances STEM Education at Mercy Girls High
                      School Okigwe with Generous Donation
                    </h3>
                    <section
                      style={{ fontFamily: "sans-serif" }}
                      className="px-6 text-sm mt-2 text-justify text-zinc-500"
                    >
                      In a commendable gesture towards promoting education and
                      empowering young minds in the field of STEM (Science,
                      Technology...
                    </section>
                  </div>
                  <div className="w-full">
                    <img
                      className=""
                      src="Images/nnpc_board.jpg"
                      alt="nnpc at mercy girls high school okigwe"
                    />
                    <a
                      href="Blog"
                      className="bg-zinc-200 relative mx-auto hover:text-mghso-10 p-4 text-center "
                    >
                      Continue Reading
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer3 />
      </div>
    </>
  );
};
export default Lumix;
