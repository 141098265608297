import React, { useState } from "react";
const ContactSection3 = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // Add logic to handle form submission (e.g., send data to server)
    // For demonstration purposes, log the form data
    console.log(formData);
  };
  return (
    <>
      <div className="bg-gray-100 py-12 ">
        <h2 className="text-2xl font-bold mt-4 w-[100%] text-center">
          LEAVE US YOUR INFO
        </h2>
        <h3 className="text-sm font-semibold mb-4 text-center">
          AND WE WILL GET BACK TO YOU.
        </h3>

        <div className="max-w-xl mx-auto mt-8 p-6">
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                htmlFor="name"
                className="block text-gray-700 font-semibold mb-2"
              >
                {" "}
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full p-2 border "
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-gray-700 font-semibold mb-2"
              >
                {" "}
                Email{" "}
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full p-2 border "
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="message"
                className="block text-gray-700 font-semibold mb-2"
              >
                {" "}
                Message
              </label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                rows="4"
                className="w-full p-2 border "
                required
              ></textarea>
            </div>
            <button
              type="submit"
              className="bg-amber-300  text-white px-4 py-2 w-full  hover:bg-gray-600"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
};
export default ContactSection3;
