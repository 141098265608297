import React from "react";
const SchSection2 = () => {
  return (
    <div className="relative mt-8 md:-mt-24 -z-10 bg-white overflow-hidden">
      <div className="grid md:grid-cols-2 m-4 p-4 Calibri space-y-2 ">
        <div>
          <section className="">
            <i
              data-aos="fade-up"
              data-aos-anchor-placement="top-center"
              className="p-8 m-4 text-sm"
            >
              At Mercy Girls' High School, we believe in providing a vibrant and
              enriching school life experience that goes beyond academics. Our
              school life encompasses a variety of activities, events, and
              opportunities designed to foster personal growth, social
              development, and lifelong memories.
            </i>
            <h2
              data-aos="fade-up"
              data-aos-anchor-placement="top-center"
              className="font-semibold text-md"
            >
              School Life
            </h2>
            <p>
              Student Clubs and Organizations: Our school offers a diverse range
              of clubs and organizations where students can explore their
              interests, develop leadership skills, and build lifelong
              friendships. From student government to cultural clubs, there's
              something for everyone to get involved in.
            </p>
            <div
              data-aos="fade-up"
              data-aos-anchor-placement="top-center"
              className="p-4 m-2"
            >
              <img src="Images/excursion2.jpg" alt="error" className="p-4" />
              <i>Students on excursion </i>
            </div>
            <p>
              Sports and Athletics: We value the importance of physical fitness
              and teamwork. Our sports programs provide students with
              opportunities to participate in a variety of sports and
              competitions, promoting health, discipline, and sportsmanship
            </p>
            <div className="p-4 m-2">
              <img src="Images/teamsports.jpg" alt="error" className="p-4" />
              <i>Our volleyball team </i>
            </div>
            <p>
              Arts and Culture: Creativity and self-expression are celebrated at
              Mercy Girls' High School. Students have access to a range of
              artistic opportunities, including visual arts, performing arts,
              and cultural events, allowing them to showcase their talents and
              appreciation for the arts
            </p>
            <div className="p-4 m-2">
              <img src="Images/excursion3.jpg" alt="error" className="p-4 " />
            </div>
            <p>
              {" "}
              Spiritual and Moral Development: Our school fosters a nurturing
              and inclusive environment where students can explore their
              spirituality, values, and beliefs. Through religious education
              classes, prayer services, and community outreach initiatives, we
              promote moral and ethical development grounded in compassion and
              empathy.
            </p>
            <p>
              Student Leadership: We believe in empowering students to become
              confident and responsible leaders. Our leadership development
              programs provide students with the skills and opportunities to
              take on leadership roles within the school community, fostering a
              sense of ownership and pride
            </p>
          </section>
        </div>
        <div>
          <section>
            <div className="p-4 m-2">
              <img src="Images/stdnt_As.jpg" alt="error" className="p-4 " />
            </div>
            <p>
              Social Events and Celebrations: From pep rallies to prom, our
              school hosts a variety of social events and celebrations
              throughout the year. These events not only promote school spirit
              and camaraderie but also create lasting memories and bonds among
              students.
            </p>
            Students on Excursion: In addition to our regular school activities,
            students at Mercy Girls' High School have the opportunity to
            participate in educational excursions and field trips. These
            excursions provide students with hands -on learning experiences,
            cultural enrichment, and opportunities for personal growth outside
            the classroom. Whether exploring historical landmarks, scientific
            institutions, or natural wonders, students gain valuable insights
            and perspectives that complement their academic studies. Join us at
            Mercy Girls' High School, where school life is vibrant, inclusive,
            and full of opportunities for students to thrive, learn, and grow.
            <div className="p-4 m-2">
              <img src="Images/stdntfree.jpg" alt="error" className="p-4" />
              <i>Our volleyball team </i>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};
export default SchSection2;
