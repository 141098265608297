import React, { useState, useEffect } from "react";
import { IoArrowDownCircle } from "react-icons/io5";
import Update from "./SubPagies/Newsbox";
import FacebookNews2 from "./SubPagies/NewsBox2";

const Section1 = () => {
  const slides = ["slide2.jpg", "slide1.jpg", "slide3.jpg"];
  const [currentSlide, setCurrentSlide] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 15000);
    return () => clearInterval(interval);
  }, [currentSlide]);
  return (
    <div
      className=" grid md:grid-cols-3 w-[100%]"
      style={{ fontFamily: "calibri" }}
    >
      <div className="lg:col-span-2 md:col-span-3">
        <div className="md:mx-8 mb-2">
          <div className="flex mt-12 ">
            <h1
              style={{ fontFamily: "calibri" }}
              className="text-bold mx-4 text-xl font-bold"
            >
              News & Updates
            </h1>
            <div className="bg-gray-300 h-[2px] mx-2s md:mx-8 mt-6 w-[20%] md:w-[50%]"></div>
          </div>
          <h4
            className="text-amber-300 text-sm mx-4 font-mono mb-4"
            style={{ fontFamily: "cartogothic" }}
          >
            {" "}
            Read All News
          </h4>
          <div
            className="grid md:grid-cols-2 justify-between "
            style={{ fontFamily: "browallia" }}
          >
            <div>
              <div>
                <img
                  src="Images/houses.jpg"
                  alt="mercy girls News"
                  className="h-[100%] px-2 w-[100%] mx-auto  round"
                />{" "}
                <div className="mx-2">
                  <p className="text-sm pt-2 px-4 text-zinc-500">
                    {" "}
                    February 24th, 2024
                  </p>
                  <h3 className="text-base px-4  font-bold font-sans text-black ">
                    COME AND JOIN US IN CELEBRATING MERCY @60
                  </h3>
                </div>
              </div>
            </div>
            {/*The list of News */}
            <div
              style={{ fontFamily: "calibri" }}
              className="mt-[7rem] md:mt-[1px]"
            >
              <FacebookNews2 />
            </div>
          </div>
        </div>
      </div>

      <div
        data-aos="fade-up"
        data-aos-anchor-placement="center-bottom"
        className="relative md:col-span-3 lg:col-span-1"
      >
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`absolute inset-0 w-full h-full transition-opacity ${
              index === currentSlide ? "opacity-100" : "opacity-0"
            }`}
          >
            <img
              className="w-full h-full  object-cover"
              src={`Images/${slide}`}
              alt={`Slide ${index + 1}`}
            />
            <div
              className="absolute inset-0  opacity-50 hover:opacity-96"
              style={{
                backgroundImage: "linear-gradient(to top, #9e142a, black",
                fontFamily: "calibri",
              }}
            ></div>
          </div>
        ))}
        <div className="relative max-w-sm md:max-w-[100%]  inset-0  h-[17rem] md:h-[100%] object-center py-20 text-center">
          <div className="p-2 md:p-4 md:m-4 mx-auto max-w-[100%] ">
            <div className="animate-bounce">
              <IoArrowDownCircle className="text-[30px] text-center w-full text-gray-200" />
            </div>
            <h2 className="text-white text-md md:text-xl font-mono font-bold antialiased">
              Take A Tour
            </h2>
            <a
              href="Gallery"
              className="text-white text-sm md:text-base hover:text-amber-300"
            >
              Our Gallery
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section1;
