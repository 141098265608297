import React from "react";
import { FaChalkboardTeacher } from "react-icons/fa";
import { BiBookReader } from "react-icons/bi";
import { IoPeopleOutline } from "react-icons/io5";

const AbouttSection3 = () => {
  const data = [
    {
      src: <BiBookReader size={30} className="text-amber-300" />,
      Header: "Spacial School Tour",
      Paragraph1:
        "Embark on a captivating journey with our special tours, where students not only explore the world beyond textbooks but also create memoeries that last a lifetime",
    },

    {
      src: <FaChalkboardTeacher size={30} className="text-amber-300" />,
      Header: "Power Alumni",
      Paragraph1:
        "Our illusterious alumni form a powerfull network of accomplished women who have left an indelible mark on verious fields.  ",
    },

    {
      src: <IoPeopleOutline size={30} className="text-amber-300" />,
      Header: "Our Philosophy",
      Paragraph1:
        " At Mercy Girls', we embrace a holistic approach to education that nurtures both the minds and hearts of our students. ",
    },
  ];
  return (
    <div className=" md:flex justify-between bg-mghso-10">
      {data.map((item, index) => (
        <div key={index} className=" text-white p-6 md:p-12 font-sans  w-full">
          <div>
            <div className="py-4 text-white">{item.src}</div>
            <div className="text-lg font-semibold pb-2">{item.Header}</div>
            <div className="text-sm">{item.Paragraph1}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AbouttSection3;
