import React from "react";
import { FaEnvelope } from "react-icons/fa";
import { FaPhone } from "react-icons/fa";
import { FaMapMarkedAlt } from "react-icons/fa";
const ContactSection2 = () => {
  const data = [
    {
      src: <FaPhone />,
      Header: "Phone",
      Paragraph1: "We would love to hear from You!",
      Paragraph2: "Call or text us for",
      Paragraph3: " more infomation",
      Paragraph4: " +2348068491189",
      Link: " Google",
    },

    {
      src: <FaEnvelope />,
      Header: "Email",
      Paragraph1: " Contact through Our School Mail  ",
      Paragraph2: "info@mghso.com.ng",
      Paragraph3: "Admin@mghso.com.ng",
      Paragraph4: "mercygirlshighschoolokigwe@gmail.com ",
      Link: " Google",
    },

    {
      src: <FaMapMarkedAlt />,
      Header: "Location",
      Paragraph1: " Mercy Girls High School  ",
      Paragraph2: "P.O Box 94, Okpara Road Okigwe, Imo State, Nigeria",
      Paragraph3: " Imo State, Nigeria",
      Paragraph4: " View On Google Map",
      Link: "contactsection4",
    },
  ];
  return (
    <div className=" md:flex justify-between w-full shadow-md bg-mghso-10">
      {data.map((item, index) => (
        <div
          key={index}
          className=" text-white p-2 md:p-6 p-2 font-sans  w-full"
        >
          <div className="grid mx-8 md:mx-2 grid-cols-4 md:block">
            <div className="py-4 text-white text-[2rem] text-center">
              {item.src}
            </div>
            <div className="col-span-3">
              <div className="text-lg font-semibold pb-2">{item.Header}</div>
              <div className="text-sm">{item.Paragraph1}</div>
              <div className="text-sm">{item.Paragraph2}</div>
              <div className="text-sm">{item.Paragraph3}</div>
              <div className="text-sm py-4">
                <a href={`${item.Link}`} className="text-amber-300 ">
                  {item.Paragraph4}
                </a>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ContactSection2;
