import React from "react";

const AlumniSection2 = () => {
  return (
    <>
      <div className="grid md:grid-cols-2 md:mx-auto  p-8">
        <div className="text-justify p-8 bg-gray-100">
          <p>
            MGHO Alumni is set to maintain a connection with their fomer school
            and to contribute positively to the school community in various
            ways.
          </p>
          <p className="text-md font-semibold pt-8">
            {" "}
            Some common goal of the Mercy Girls' Alumni include
          </p>
          <ul className="list-disc mx-4">
            <li className="pt-2">
              <strong>Networking: </strong>Connecting with other alumni to build
              personal and professional networks, which may lead to job
              opportunities or other beneficial relationship.
            </li>
            <li className="pt-2">
              <strong>Giving Back: </strong> Supporting the school financially
              or through volunteer work to help current students have a positive
              enriching educational experience.{" "}
            </li>
            <li className="pt-2">
              <strong> Mentoring:</strong> Serving as mentor to current
              students, providing guildance and support as they navigate their
              academic and proffessional careers.
            </li>
            <li className="pt-2">
              <strong>Representing the school: </strong> Acting as ambassadors
              fro the school, promoting its values and achievements to the wider
              community.
            </li>
            <li className="pt-2">
              <strong> Continuing education: </strong>Continuing to learn and
              grow, both personally and proffessionally, and using their
              knowledge and skills to benefit their communities and the world at
              large.
            </li>
          </ul>
        </div>
        <div className="grid md:grid-cols-2 text-justify ">
          <div className="mx-auto mx-w-sm">
            <h3 className="text-md font-bold px-2">
              Join The Alumni Communication Platform
            </h3>
            <section
              style={{ fontFamily: "sans-serif" }}
              className="px-6 text-sm mt-2"
            >
              We love to keep our alumne up-to-date about all things Mercy.
              Please complete this form to send us your updated information so
              we can make sure you recieve all of our communications and
              invitations. additionally, you may use this form to submit items
              you would like us to include in the next post, such as wedding,
              births, or promotions.
            </section>
          </div>
          <div>
            <iframe
              style={{ justifyContent: "center" }}
              src="https://docs.google.com/forms/d/e/1FAIpQLSdk3EpQsYKZYNg0uqriuKK0P-IGHS2BbGUWcvMC0lRGtP8NHA/viewform?embedded=true"
              className="max-w-sm mx-auto h-[30rem]"
              frameborder="0"
              marginheight="0"
              marginwidth="0"
              title="Mercy Girls High School Okigwe Alumni Form"
            >
              Loading…
            </iframe>
          </div>
        </div>
      </div>
    </>
  );
};
export default AlumniSection2;
