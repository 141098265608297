import React, { useState } from "react";
const TeamSection = () => {
  const teamMembers = [
    // Your team members' data goes here
    {
      id: 1,
      name: "Rev. Fr. Remigius Ogu",
      role: "Principal",
      imageUrl: "Images/frwhite2.jpg",
      phone: "08068491189",
    },
    {
      id: 2,
      name: "Mrs Roseline Uchenna",
      role: "Vice Principal",
      imageUrl: "Images/rose.jpg",
      phone: "08068491189",
    },
    {
      id: 3,
      name: "Mrs Justina Nkiruka",
      role: "School Burser",
      imageUrl: "Images/isaacc.jpg",
      phone: "07057097673",
    },
    {
      id: 4,
      name: "Rev Sr. Mary-Francis",
      role: "Teacher",
      imageUrl: "Images/sisr.jpg",
      phone: "Loading.......",
    },
    {
      id: 5,
      name: "Nwoke Ikechukwu",
      role: "ICT Director",
      imageUrl: "Images/ike.jpg",
      phone: "07033640832",
    },
    {
      id: 6,
      name: "Agugua Oscar",
      role: "Teacher",
      imageUrl: "Images/oscar.jpg",
      phone: "08063748119",
    },
    {
      id: 7,
      name: "Rev. Canon Nnadozie",
      role: "Teacher",
      imageUrl: "Images/revC.jpg",
      phone: "08063748119",
    },
    {
      id: 8,
      name: "Alaneme Brillian Chibuzo",
      role: "Teacher",
      imageUrl: "Images/chibu.jpg",
      phone: "07033640832",
    },
    {
      id: 9,
      name: "Nwaobia Derlington",
      role: "Teacher",
      imageUrl: "Images/onyebuchi.jpg",
      phone: "080373245567",
    },
    {
      id: 10,
      name: "Ohia Josephine",
      role: "Teacher",
      imageUrl: "Images/ohia.jpg",
      phone: "07033486183",
    }, // Add more team members as needed
  ];
  const TeamCard = ({ id, name, role, imageUrl, phone }) => {
    return (
      <div className="bg-white round p-[1px] md:p-4 mx-[1px] md:mx-2">
        <img
          src={imageUrl || "Images/getty.png"}
          alt={`team member ${name}`}
          className="md:w-full md:h-[10rem] lg:h-[15rem] h-[5rem] w-full mb-2 md:mb-4 rounded md:round  "
        />
        <h3 className="md:text-lg text-[10px] p-2 md:p-[0px] md:font-semibold">
          {name}
        </h3>
        <p className="text-gray-500 hidden md:block sm:text-[3px] md:text-[14px]">
          {role}
        </p>
        <p className="text-sm text-gray-500 hidden md:block">{phone}</p>
      </div>
    );
  };
  const itemsPerPage = 4; // Number of team members per page
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(teamMembers.length / itemsPerPage);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const paginatedTeamMembers = teamMembers.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  return (
    <div>
      {" "}
      <h2 className="text-center w-full text-[30px] md:font-semibold  px-4 pt-4 mt-[5px]">
        Our Staff
      </h2>
      <div className="text-[2rem] w-[8rem] mx-auto h-[3px] bg-mghso-10"></div>
      <div className=" bg-zinc-200 mx-auto my-8 p-4">
        <div className="grid grid-cols-4 sm:grid-cols-4 md:grid-cols-4 gap-[1px] md:gap-4">
          {paginatedTeamMembers.map((member) => (
            <TeamCard key={member.id} {...member} />
          ))}{" "}
        </div>
        {totalPages > 1 && (
          <div className="flex justify-center mt-4">
            {[...Array(totalPages)].map((_, index) => (
              <button
                key={index + 1}
                onClick={() => handlePageChange(index + 1)}
                className={`md:mx-2 md:p-2 p-[2px] mx-[2px] text-[10px] rounded-full ${
                  currentPage === index + 1
                    ? "bg-mghso-10 text-white"
                    : "bg-gray-300"
                }`}
              >
                {index + 1}{" "}
              </button>
            ))}{" "}
          </div>
        )}{" "}
      </div>
    </div>
  );
};
export default TeamSection;
