import React from "react";
import Nav1 from "../../../../Components/Quicklink";
import Navbar from "../../../../Components/navigation";
import Footer3 from "../../../../Components/footer3";
import EduSection1 from "./edusection1";
import EduSection2 from "./edusection2";

const Education = () => {
  return (
    <div>
      <Nav1 />
      <Navbar />
      <EduSection1 />
      <EduSection2 />
      <Footer3 />
    </div>
  );
};

export default Education;
