import React from "react";
const SpiritSection2 = () => {
  return (
    <div className="relative mt-8 md:-mt-24 -z-10 bg-white overflow-hidden">
      <div className="grid md:grid-cols-2 m-4 p-4 Calibri space-y-2 ">
        <div>
          <section className="">
            <i className="p-8 m-4 text-sm">
              At Mercy Girls' High School, we are committed to nurturing the
              spiritual and moral development of our students, fostering a deep
              sense of faith, compassion, and ethical responsibility. Our
              spirituality and moral instruction programs provide students with
              opportunities for reflection, prayer, and growth in their
              spiritual journey
            </i>
            <h2 className="font-semibold text-md">
              Spirituality of our students:
            </h2>
            <p>
              Religious Education: Our school offers comprehensive religious
              education programs that provide students with a strong foundation
              in their faith tradition. Through the study of scripture,
              theology, and ethical teachings, students deepen their
              understanding of their religious beliefs and values
            </p>
            <div className="p-4 m-2">
              <img src="Images/masscon.jpg" alt="error" className="p-4" />
              <i>During one of our masses </i>
            </div>
            <p>
              Prayer and Reflection: Prayer is an integral part of daily life at
              Mercy Girls' High School. We provide dedicated prayer spaces and
              opportunities for students to engage in individual and communal
              prayer, fostering a sense of connection with the divine and
              promoting spiritual well-being.
            </p>
            <div className="p-4 m-2">
              <img src="Images/picinchurch.jpg" alt="error" className="p-4" />
            </div>
            <p>
              Mass and Worship: Regular Masses and worship services are held on
              campus, providing students with opportunities to participate in
              liturgical celebrations and sacramental rites. These gatherings
              strengthen the school community's bond and provide students with a
              sense of unity and reverence
            </p>
            <div className="p-4 m-2">
              <img src="Images/moralinstru.jpg" alt="error" className="p-4 " />{" "}
              <i>Students during a moral instruction section</i>
            </div>
            <p>
              {" "}
              Spiritual Retreats: Our school organizes spiritual retreats and
              reflection days, allowing students to step away from their daily
              routines and deepen their spiritual connection. These retreats
              provide opportunities for introspection, personal growth, and
              renewal of faith.
            </p>
            <p>
              Community Service and Outreach: We believe in putting faith into
              action through service to others. Our school encourages students
              to engage in community service and outreach programs, fostering a
              spirit of compassion, empathy, and social responsibility.
            </p>
          </section>
        </div>
        <div>
          <section>
            <div className="p-4 m-2">
              <img src="Images/mghso_pic1.jpg" alt="error" className="p-4 " />
            </div>
            <p>
              Moral Instruction: In addition to religious education, our school
              provides moral instruction that emphasizes ethical
              decision-making, character development, and integrity. Through
              discussions, workshops, and real-life scenarios, students learn to
              apply moral principles in their daily lives and become responsible
              citizens of society.
            </p>
            Our school provides dedicated prayer sections where students can
            retreat for moments of quiet reflection and spiritual connection.
            These prayer sections are adorned with symbols of faith and provide
            a peaceful and serene environment conducive to prayer and
            meditation.{" "}
            <p>
              Join us at Mercy Girls' High School, where spirituality is
              nurtured, moral instruction is valued, and students are encouraged
              to grow in faith, compassion, and ethical integrity
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};
export default SpiritSection2;
