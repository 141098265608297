import React from "react";
import Nav1 from "../Components/Quicklink";
import Navbar from "../Components/navigation";
import AboutSection1 from "../SubPages/About/AboutSection1";
import AboutSection2 from "../SubPages/About/AboutSection2";
import AbouttSection3 from "../SubPages/About/AboutSection3";
import AboutSection4 from "../SubPages/About/AboutSection4";
import Partners from "../SubPages/Home/partnerssection";
import Footer3 from "../Components/footer3";

const About = () => {
  return (
    <>
      <div className="max-w-[1460px] mx-auto  content-center">
        <Nav1 />
        <Navbar />
        <AboutSection1 />
        <AboutSection2 />
        <AbouttSection3 />
        <AboutSection4 />
        <Partners />
        <Footer3 />
      </div>
    </>
  );
};
export default About;
