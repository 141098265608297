import React from "react";
import Nav1 from "../Components/Quicklink";
import Navbar from "../Components/navigation";
import IntroSection from "../SubPages/Academics/Introsection";
import Footer3 from "../Components/footer3";
import AcSection1 from "../SubPages/Academics/Acsecction1";
import Acsecction2 from "../SubPages/Academics/Acsecction2";
import TeamSection from "../SubPages/Academics/Acsecction3";
const Academic = () => {
  return (
    <>
      <div className="max-w-[1460px] mx-auto  content-center">
        <Nav1 />
        <Navbar />
        <IntroSection />
        <AcSection1 />
        <Acsecction2 />
        <TeamSection />
        <Footer3 />
      </div>
    </>
  );
};
export default Academic;
