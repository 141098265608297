import React from "react";

const BlogSection2 = () => {
  return (
    <>
      <div className="grid md:grid-cols-2 md:mx-auto  p-2">
        <div
          className="text-justify p-4 bg-gray-100 col-span-1 "
          style={{ fontFamily: "calibri" }}
        >
          <h3 className="font-bold text-xl">
            NNPC LTD Enhances STEM Education at Mercy Girls High School Okigwe
            with Generous Donation
          </h3>
          <p>
            In a commendable gesture towards promoting education and empowering
            young minds in the field of STEM (Science, Technology, Engineering,
            and Mathematics), NNPC LTD recently made a significant contribution
            to Mercy Girls High School Okigwe. The donation, which included a
            STEM library and a set of science textbooks, was facilitated by the
            esteemed Honorable Declan, further solidifying NNPC LTD's commitment
            to fostering academic excellence and innovation
          </p>
          <img
            className=" "
            src="Images/nnpc_board.jpg"
            alt="nnpc at mercy girls high school okigwe"
          />
          <h4 className="font-semibold text-lg"> A Gift of Empowerment: </h4>
          <p>
            The unveiling of the STEM library and science textbooks at Mercy
            Girls High School Okigwe marked a pivotal moment in the school's
            journey towards academic excellence. NNPC LTD's generous donation
            not only provides students with access to invaluable educational
            resources but also inspires them to pursue careers in STEM fields,
            thereby contributing to the nation's technological advancement and
            economic growth.
          </p>
          <img
            className=" "
            src="Images/nnpc_logo.jpg"
            alt="nnpc at mercy girls high school okigwe"
          />
          <h4 className="font-semibold text-lg">
            Empowering Tomorrow's Innovators :
          </h4>{" "}
          <p>
            {" "}
            The importance of STEM education cannot be overstated in today's
            rapidly evolving world. By equipping Mercy Girls High School Okigwe
            with a well-stocked STEM library and comprehensive science
            textbooks, NNPC LTD has empowered students to explore, experiment,
            and innovate. These resources serve as a catalyst for curiosity,
            enabling students to develop critical thinking skills and
            problem-solving abilities essential for success in STEM disciplines.
          </p>
          <h4 className="font-semibold text-lg">
            Honorable Declan's Advocacy:
          </h4>
          <p>
            The role of Honorable Declan in facilitating this generous donation
            cannot be overlooked. As a staunch advocate for education and
            community development, Honorable Declan has once again demonstrated
            his unwavering commitment to uplifting the youth and providing them
            with opportunities for growth and advancement. His collaboration
            with NNPC LTD exemplifies the power of public-private partnerships
            in driving positive change and making a lasting impact on society.
          </p>
          <p>
            NNPC LTD's initiative to support Mercy Girls High School Okigwe
            reflects its strong commitment to corporate social responsibility.
            By investing in education and empowering young minds, NNPC LTD not
            only fulfills its obligation to the community but also nurtures
            future talent and fosters sustainable development. This noble act
            serves as a shining example for other corporate entities to follow
            suit and contribute meaningfully to the advancement of education in
            Nigeria.
          </p>
          <p>
            The partnership between NNPC LTD, Honorable Declan, and Mercy Girls
            High School Okigwe symbolizes the collective efforts towards
            creating a brighter future for generations to come. Through the
            establishment of a STEM library and the provision of science
            textbooks, NNPC LTD has laid the foundation for academic excellence
            and innovation, empowering students to pursue their passions and
            make meaningful contributions to society. As we celebrate this
            milestone, let us reaffirm our commitment to education and continue
            to support initiatives that enrich the lives of our youth and shape
            the leaders of tomorrow
          </p>
        </div>
        <div style={{ fontFamily: "calibri" }} className=" p-4 bg-gray-100 ">
          <h2 className="font-bold text-xl text-zinc-400 mt-4">Recent Blogs</h2>
          <div className="p-2">
            <div className="grid md:grid-cols-2  p-4 m-4">
              <div className="mx-auto mx-w-sm">
                <h3 className="text-md font-bold px-2 ">
                  Prawa NGO Champions Girl Child Education with Support from
                  Mercy Girls High School Okigwe Alumni
                </h3>
                <section
                  style={{ fontFamily: "sans-serif" }}
                  className="px-6 text-sm mt-2 text-justify text-zinc-500"
                >
                  In a world where education remains a powerful tool for
                  empowerment and social change, Prawa NGO stands at the
                  forefront of...
                </section>
              </div>
              <div className="w-full">
                <img
                  className=""
                  src="Images/prawaflyer.jpg"
                  alt="prawa at mercy girls high school okigwe"
                />
                <a
                  href="prawa"
                  className="bg-zinc-200 relative mx-auto hover:text-mghso-10 p-4 text-center "
                >
                  Continue Reading
                </a>
              </div>
            </div>
            {/* second Blog headline */}
            <div className="grid md:grid-cols-2 p-4 m-4 ">
              <div className="mx-auto mx-w-sm">
                <h3 className="text-md font-bold px-2">
                  Celebrating Excellence: Mercy Girls High School Shines in WAEC
                  2022/2023 Examinations
                </h3>
                <section
                  style={{ fontFamily: "sans-serif" }}
                  className="px-6 text-sm mt-2 text-justify text-zinc-500"
                >
                  Mercy Girls High School, a beacon of academic excellence, has
                  once again demonstrated its commitment to nurturing bright
                  minds and fostering academic brilliance.
                </section>
              </div>
              <div className="w-full">
                <img
                  className=""
                  src="Images/waeclogo.jpeg"
                  alt="nnpc at mercy girls high school okigwe"
                />
                <a
                  href="result"
                  className="bg-zinc-200 relative mx-auto hover:text-mghso-10 p-4 text-center "
                >
                  Continue Reading
                </a>
              </div>
            </div>
            {/* second Blog headline */}
            <div className="grid md:grid-cols-2 p-4 m-4 ">
              <div className="mx-auto mx-w-sm">
                <h3 className="text-md font-bold px-2">
                  Celebrating 60 Years of Excellence: Mercy Girls High School's
                  Inter House Sports Competition
                </h3>
                <section
                  style={{ fontFamily: "sans-serif" }}
                  className="px-6 text-sm mt-2 text-justify text-zinc-500"
                >
                  Mercy Girls High School, a cornerstone of education and
                  excellence, recently celebrated its 60th anniversary with
                  great pomp and grandeur.
                </section>
              </div>
              <div className="w-full">
                <img
                  className=""
                  src="Images/houses.jpg"
                  alt="nnpc at mercy girls high school okigwe"
                />
                <a
                  href="result"
                  className="bg-zinc-200 relative mx-auto hover:text-mghso-10 p-4 text-center "
                >
                  Continue Reading
                </a>
              </div>
            </div>
            {/* second Blog headline */}
            <div className="grid md:grid-cols-2 p-4  m-4">
              <div className="mx-auto mx-w-sm">
                <h3 className="text-md font-bold px-2 ">
                  Lumix Graphics Transforms Mercy Girls High School Okigwe's
                  Website and Facebook Page with
                </h3>
                <section
                  style={{ fontFamily: "sans-serif" }}
                  className="px-6 text-sm mt-2 text-justify text-zinc-500"
                >
                  In a digital age where online presence is paramount, Mercy
                  Girls High School Okigwe has received a remarkable makeover
                  thanks to Lumix Graphics and its
                </section>
              </div>
              <div className="w-full">
                <img
                  className=""
                  src="Images/graphics_logo.jpg"
                  alt="prawa at mercy girls high school okigwe"
                />
                <a
                  href="Lumix"
                  className="bg-zinc-200 relative mx-auto hover:text-mghso-10 p-4 text-center "
                >
                  Continue Reading
                </a>
              </div>
            </div>
            {/* 3rd Blog headline */}
            <div className="grid md:grid-cols-2 p-4 m-4">
              <div className="mx-auto mx-w-sm">
                <h3 className="text-md font-bold px-2 ">
                  NCC's Rural Broadband Initiative Empowers Mercy Girls High
                  School Okigwe with Cutting-Edge ICT Complex.
                </h3>
                <section
                  style={{ fontFamily: "sans-serif" }}
                  className="px-6 text-sm mt-2 text-justify text-zinc-500"
                >
                  In a concerted effort to bridge the digital divide and empower
                  underserved communities,
                </section>
              </div>
              <div className="w-full">
                <img
                  className=""
                  src="Images/indesign.jpg"
                  alt="nnc at mercy girls high school okigwe"
                />
                <a
                  href="ncc"
                  className="bg-zinc-200 relative mx-auto hover:text-mghso-10 p-4 text-center "
                >
                  Continue Reading
                </a>
              </div>
            </div>
            {/*4th Blog Headline*/}
            <div className="grid md:grid-cols-2 p-4  m-4">
              <div className="mx-auto mx-w-sm">
                <h3 className="text-md font-bold px-2 ">
                  Transforming Mercy Girls High School's Online Presence: A
                  Testimony of ZephTech's Excellence
                </h3>
                <section
                  style={{ fontFamily: "sans-serif" }}
                  className="px-6 text-sm mt-2 text-justify text-zinc-500"
                >
                  As a proud member of the Mercy Girls High School community in
                  Okigwe, I am thrilled to share my heartfelt testimony about
                  the incredible work of ZephTech, a web design firm that has
                  revolutionized our school's online presence....
                </section>
              </div>
              <div className="w-full">
                <img
                  className=""
                  src="Images/zephlogo.jpg"
                  alt="nnpc at mercy girls high school okigwe"
                />
                <a
                  href="zephtech"
                  className="bg-zinc-200 relative mx-auto hover:text-mghso-10 p-4 text-center "
                >
                  Continue Reading
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BlogSection2;
