import React from "react";

const AboutSection2 = () => {
  const data = [
    {
      src: "images/logo",
      Header: "Mercy Girls' History",
      Paragraph1:
        " In the year 1963, 20th February, An anointed servant of God, in the name of Late Bishop Anthony Gogo Nwedo CSSP, was led to open this great Institution.",
      Paragraph2:
        "In the heart of the founder, An avanue for self-development was given birth to, It was named 'Mercy Girls High School.' ",
      Paragraph3:
        " Mercy was established by Most Rev. Dr. Anthony Nwedo CSSP to train and prepare young people(girls) for challenging feature educative lives. In his effort to protect and educate the to be well trained and educated to meet their goals.",
      Paragraph4:
        " Mercy the oldest queen in town has passed through many transitory stages, she went through governments after the civil war in 1967 to jenauary 1970 and came back to the mission after 40 years of waiting, with her glory intact, and become a panacea to women",
      Paragraph5:
        " Greate men and women of God has given their all to bring this greate school to this monumental stage, The pride of the girl child is her name. In their quest to give hope to the 'girl child' These great men and woman, gave their very best to this institution and have made her become a paradigm of the excellence of girl education.",
      Paragraph6:
        "The pride of the girl child is her name. In their quest to give hope to the 'girl child' These great men and woman, gave their very best to this institution and have made her become a paradigm of the excellence of girl education.",
    },

    {
      Paragraph1:
        "  Mercy Girls High School has programmes designed to maintain the old befitting institution of academic excellence and moral integrity  ",
      Paragraph2:
        " Mercy Girls Okigwe now conduct its own annual Common Entrance Examination to evaluate the new incoming students  It now hires its own teachers according to its ideal and values.",
      Paragraph3:
        " Students are now inspired and motivated to study very hard for their exams. Those who don't will repeat their class.",
      Paragraph4:
        "Truely, our internal Mercy candidate (regular students) are registered for external examination(WAEC and NECO). Expos or any form of cheating (exam malpratices) are not allowed and must not be tolerated.",
      Paragraph5:
        "  Boarding school is now a reality at Mercy. We started on Octobar7, 2013 with few students.  Students are again friendly to one another through sisters of the Mercy family as against what was inherited in 2010 from the Imo State Government. There is high hope and confidence with the PTA",
      Paragraph6:
        " Mercy Girls, Just like a mother, she is a truest friend, so affectionate, nutures every child with her hands. A queen of all queens, always the best in all kinds and always ready to carry the brain best of it",
    },

    {
      src: "images/logo",
      Header: " Who We Are",
      Paragraph1:
        "   Towards surpasing the cost demanding standards available anywhere in the world  Mercy Girls' High School provides outstanding education that inspires leadership traits as it focuses on the individual child's ability to excel in the global world.",
      Paragraph2:
        "  Providing recorgnised standered educational programmes through the National Curriculum to enable students to gain access to excelent universities.",
      Paragraph3:
        "   Incorporating the local culture and language in teaching and learning to foster intergration.",
      Paragraph4:
        " Identifying gifts and talents of the individual child and nurturing them. Also providing a good educational system for the intellectual, and moral/ attitudinal and spiritual upbringing of the 21st century girl child Encouraging the development of the holistic student through participation in arts, sports, technology, dance and music",
      Link: " Google",
    },
  ];
  return (
    <div>
      <div className="flex max-w-lg p-8">
        <a href="Home" className="text-gray-300 hover:text-mghso-10">
          Home
        </a>
        <h className="px-2"> / </h>{" "}
        <a href="About Us" className="text-mghso-10 w-[10rem]">
          About Us
        </a>
      </div>
      <div className=" md:flex justify-between ">
        {data.map((item, index) => (
          <div
            key={index}
            className=" text-gray-900 p-8 md:p-12 font-sans  w-full"
          >
            <div>
              <div className="text-lg font-semibold pb-2 border-b-2 border-mghso-10">
                {item.Header}
              </div>
              <div className="text-sm text-justify mt-4 ">
                {item.Paragraph1}
              </div>
              <div className="text-sm text-justify pt-2">{item.Paragraph2}</div>
              <div className="text-sm text-justify">{item.Paragraph3}</div>
              <div className="text-sm text-justify">{item.Paragraph4}</div>
              <div className="text-sm text-justify">{item.Paragraph5}</div>
              <div className="text-sm text-justify">{item.Paragraph6}</div>
              <div className="text-sm text-justify">{item.Paragraph7}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AboutSection2;
