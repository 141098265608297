import React from "react";
const WelcomeSection = () => {
  return (
    <div className="relative  z-10 overflow-hidden  md:mb-8">
      <div className=" grid gap-4 md:grid-cols-2 lg:mx-56 md:mx-12  mx-8 text-mghso-10 pb-4 md:pb-8 pt-4 md:pt-12">
        <div
          data-aos="fade-up"
          data-aos-anchor-placement="top-center"
          className="item-center w-full pt-[1px] md:pt-12"
        >
          <img
            src="Images/frwcpix.jpg"
            alt="mercy girls principal"
            className=" item-center h-[15rem] w-[14rem]   lg:h-72 md:w-60 md:h-[25rem] border-2 border-mghso-10  p-4 mx-auto md:mx-auto"
          />
          <h3
            style={{ fontFamily: "roboto" }}
            className="text-black pt-4 text-center"
          >
            Rev. Fr. Remigius Ogu
          </h3>
          <p
            style={{ fontFamily: "calibri" }}
            className="text-gray-600 text-center"
          >
            Principal{" "}
          </p>
        </div>
        <div className="relative object-cover">
          <div
            style={{ fontFamily: "calibri" }}
            className="absolute inset-0 bg-mghso-10 opacity-95"
          ></div>
          <div
            data-aos="fade-up"
            data-aos-anchor-placement="top-center"
            className="relative z-10 text-white "
          >
            <h4 className="md:text-base text-cente text-sm md:p-20 p-6 ">
              <span className="text-xl md:text-base">"</span>I warmly welcome
              you to Mercy Girls' High School Okigwe. We are committed to
              nurturing the girl child in our care; instilling in them
              confidence, a sense of self-worth and a passion for life-long
              lerning. We are interested in the Education of the whole child, so
              that our students become thoughtful, confident, articulated,
              creative and caring individuals.<span className="text-xl">"</span>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WelcomeSection;
